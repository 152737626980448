import { Html } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import classes from "./InteriorOptionsSelect.module.scss";
import useGridSelect from "../../hooks/useGridSelect";

const InteriorOptionsSelect = ({ center, state, handler }) => {
  let mat = [];

  for (let i = 0; i < state.columns.length; i++) {
    mat[i] = [];
    for (let j = 0; j < state.columns[i].compartments.length; j++) {
      mat[i][j] = true;
    }
  }

  const [available, selected, selectOneField, deselectOneField] = useGridSelect(
    mat,
    state,
    false,
    false
  );

  useEffect(() => {
    if (typeof handler === "function") {
      handler(selected);
    }
  }, [selected]);

  const { camera, size } = useThree();
  const [visibleWidth, setVisibleWidth] = useState(0);

  useEffect(() => {
    const distanceToCenter = camera.position.distanceTo(center);

    const visibleHeight =
      2 * distanceToCenter * Math.tan((camera.fov * Math.PI) / 360);

    const aspectRatio = size.width / size.height;
    const visibleWidth = visibleHeight * aspectRatio;
    setVisibleWidth(visibleWidth);
  }, [camera.fov, camera.position, center, size.width, size.height]);

  const pixelWidth = size.width / visibleWidth;

  let posX = center.x - state.width / 200,
    posY = center.y - state.height / 200,
    width,
    height;

  let elems = [];
  let i = 0,
    j = 0;

  for (const column of state.columns) {
    posX += state.materialThickness / 100;
    posY =
      center.y -
      state.height / 200 +
      column.height / 100 -
      state.materialThickness / 100;

    width = column.width / 100;

    j = 0;
    for (const comp of column.compartments) {
      height = comp.height / 100;
      elems.push(
        <OverlayBorder
          position={[posX, posY, state.depth / 100]}
          width={width * pixelWidth}
          height={height * pixelWidth}
          key={"o" + i + j + "interior"}
          field={[i, j]}
          selected={selected}
          select={selectOneField}
          deselect={deselectOneField}
        />
      );
      posY -= height + state.innerThickness / 100;
      j++;
    }
    i++;
    posX += width;
  }

  return <>{elems.map((elem) => elem)}</>;
};

const OverlayBorder = ({
  position = [0, 0, 0],
  width = 0,
  height = 0,
  field = [0, 0],
  selected = [],
  select = () => {},
  deselect = () => {},
}) => {
  const isSelected =
    selected.find((elem) => elem[0] === field[0] && elem[1] === field[1]) !==
    undefined;

  const clickHandler = () => {
    if (isSelected) {
      deselect(...field);
    } else {
      select(...field);
    }
  };
  return (
    <Html position={position}>
      <div
        style={{
          width: width + "px",
          height: height + "px",
        }}
        className={`${classes.overlayBorder} ${
          isSelected ? classes.overlayBorderSelected : ""
        }`}
        onClick={clickHandler}
      >
        <div className={classes.circle}>
          <span className={classes.text}>{isSelected ? "-" : "+"}</span>
        </div>
      </div>
    </Html>
  );
};

export default InteriorOptionsSelect;
