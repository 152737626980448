import { useRef, useEffect, useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { Plane } from "../graphics/Plane/Plane";
import Police from "../graphics/Police/Police";
import { generate } from "../utils/Math";
import * as THREE from "three";
import Controls from "../graphics/OrbitControls/Controls";
const PI = Math.PI;
const PI_HALF = Math.PI / 2;

const pod_boja = 0x9d9d9d;
const zid_boja = 0xe6e6e2;

const SaveGraphics = ({ state, saveImage = () => null }) => {
  let position = [0, 0, 0];
  const canvasRef = useRef();

  const [center, setCenter] = useState(
    new THREE.Vector3(0 + position[0], 0.8 + position[1], 0 + position[2])
  );

  let oldCenter = center;

  function changeCenter(x, y, z) {
    if (oldCenter.x !== x || oldCenter.y !== y || oldCenter.z !== z)
      setCenter(
        new THREE.Vector3(x + position[0], y + position[1], z + position[2])
      );
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "-9999px",
        zIndex: "-1000000",
        width: "1024px",
        height: "1024px",
      }}
    >
      <Canvas
        ref={canvasRef}
        gl={{ preserveDrawingBuffer: true }}
        shadows
        camera={{
          position: [
            center.x + state.width / 200,
            center.y + state.height / 50,
            5,
          ],
          near: 0.1,
          far: 100,
          fov: 45,
        }}
      >
        <Controls
          target={center}
          state={state}
          enableRotate={false}
          enableZoom={false}
        />
        <ambientLight intensity={0.5} />
        <directionalLight
          position={[10, 5, 10]}
          rotation={[0, PI / 6, 0]}
          intensity={0.5}
          castShadow
          mapSize={{ width: 128, height: 128 }}
        />
        <Display
          state={state}
          position={position}
          changeCenter={changeCenter}
          saveImage={saveImage}
        />
      </Canvas>
    </div>
  );
};

export default SaveGraphics;

const Display = ({ state, position, changeCenter, saveImage }) => {
  const gl = useThree((state) => state.gl);

  useEffect(() => {
    const img = gl.domElement
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    saveImage(img);
  }, [state, saveImage]);

  return (
    <group>
      <Plane
        color={pod_boja}
        position={[0, 0, 0]}
        args={[100, 100]}
        rotation={[PI_HALF, 0, 0]}
        receiveShadow={true}
      />
      <Plane
        color={zid_boja}
        position={[0, 0, 0]}
        args={[100, 100]}
        rotation={[0, 0, 0]}
        receiveShadow={true}
      />
      <Police
        position={position}
        onChangeCenter={changeCenter}
        state={state}
        elems={generate(state)[0]}
      />
    </group>
  );
};
