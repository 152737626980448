import { Box } from "../Box/Box";
import handles from "../../../data/handles";
import Handle from "../Handle/Handle";

const DoorElement = ({
  dimensions = [0, 0, 0],
  position = [0, 0, 0],
  doorType = "none",
  rotation = [0, 0, 0],
  textures = [],
  color = "white",
  handleCode,
}) => {
  const handle = handles[handleCode];
  let positions = null;
  if (handle && handle?.positions && typeof handle?.positions === "object") {
    positions = handle?.positions?.find((item) => item.doorType === doorType);
  }

  switch (doorType) {
    case "right_door":
    case "left_door":
    case "drawer":
    case "flap_door_up":
    case "flap_door_down":
      return (
        <>
          <Box
            args={dimensions}
            position={position}
            rotation={rotation}
            textures={textures}
            color={color}
          />

          {handle && positions && (
            <Handle
              fileType={handle.fileType}
              model={handle.model}
              dimensions={handle.dimensions}
              rotation={positions?.rotation ?? handle.rotation}
              positions={positions?.max_heights}
              doorData={{ dimensions, position }}
            />
          )}
        </>
      );
    case "double_door":
      return (
        <>
          <DoorElement
            dimensions={[
              dimensions[0] / 2 - 0.002,
              dimensions[1],
              dimensions[2],
            ]}
            position={[
              position[0] - dimensions[0] / 4 - 0.001,
              position[1],
              position[2],
            ]}
            rotation={rotation}
            textures={textures}
            color={color}
            handleCode={handleCode}
            doorType={"left_door"}
          />
          <DoorElement
            dimensions={[
              dimensions[0] / 2 - 0.002,
              dimensions[1],
              dimensions[2],
            ]}
            position={[
              position[0] + dimensions[0] / 4 + 0.001,
              position[1],
              position[2],
            ]}
            rotation={rotation}
            textures={textures}
            color={color}
            handleCode={handleCode}
            doorType={"right_door"}
          />
        </>
      );
    case "none":
    default:
      return null;
  }
};

export default DoorElement;
