import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
/**
 *
 * @param {[[Boolean]]} mat matrix of available fields
 * @param {{}} state
 * @param {Boolean} multipleSelection
 */
const useGridSelect = (
  mat,
  state,
  multipleSelection = true,
  doorSelection = true
) => {
  const [available, setAvailable] = useState(mat);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (selected.length === 0) {
      setAvailable((oldAvailable) => {
        let newAvailable = cloneDeep(oldAvailable);
        for (let i = 0; i < newAvailable.length; i++) {
          for (let j = 0; j < newAvailable[i].length; j++) {
            newAvailable[i][j] = true;
          }
        }

        return newAvailable;
      });
    } else {
      setAvailable((oldAvailable) => {
        let newAvailable = cloneDeep(oldAvailable);
        for (let i = 0; i < newAvailable.length; i++) {
          for (let j = 0; j < newAvailable[i].length; j++) {
            newAvailable[i][j] = false;
          }
        }
        if (multipleSelection) {
          let column = selected[0][0];
          let firstRow = selected[0][1];
          let lastRow = selected[selected.length - 1][1];
          if (!state.columns[column].compartments[firstRow].hasDoor) {
            for (
              let i = Math.max(firstRow - 1, 0);
              i <= Math.min(lastRow + 1, newAvailable[column].length - 1);
              i++
            ) {
              if (state.columns[column].compartments[i].hasDoor) continue;
              newAvailable[column][i] = true;
            }
          }
        }

        return newAvailable;
      });
    }
  }, [selected, state.columns, multipleSelection]);

  useEffect(() => {}, [available]);

  const selectOneField = (i, j) => {
    if (state.columns[i].compartments[j].hasDoor && doorSelection) {
      let newSelected = [];
      let [, clm, start, end] =
        state.columns[i].compartments[j].door.key.split("_");
      clm = Number(clm);
      start = Number(start);
      end = Number(end);
      for (let k = start; k <= end; k++) {
        newSelected.push([clm, k]);
      }
      setSelected(newSelected);
    } else if (available[i][j]) {
      setSelected((oldSelected) => {
        let newSelected = [...oldSelected, [i, j]];
        newSelected.sort((a, b) => {
          return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
        });
        return newSelected;
      });
    } else {
      setSelected([[i, j]]);
    }
  };

  const deselectOneField = (i, j) => {
    setSelected([]);
  };

  return [available, selected, selectOneField, deselectOneField];
};

export default useGridSelect;
