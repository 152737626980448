import classes from "./LeftMenu.module.scss";

/**
 *
 * @param {string} itemKey key of the item
 * @param {string} title option title
 * @param {string} image option display image
 * @param {function} handler handler when option is choosen
 * @param {boolean} selected true if thios option ios selected
 * @returns
 */

const MenuOption = ({ itemKey, title, image, handler, selected }) => {
  return (
    <div
      onClick={() => handler(itemKey)}
      className={`${classes.menuOption} ${
        selected ? classes.menuOptionSelected : ""
      }`}
    >
      <img src={image} alt={title} />
      <p>{title}</p>
    </div>
  );
};

export default MenuOption;
