import { cloneDeep } from "lodash";
import {
  CreateInnerSizingArrow,
  CreateLabelRect,
  CreateSizingArrow,
} from "../CreateSizingArrow";
import { loadImage } from "../LoadImage";

const multiplyProperties = (obj, factor) => {
  for (const key in obj) {
    if (typeof obj[key] === "number") {
      obj[key] *= factor;
    } else if (typeof obj[key] === "object") {
      obj[key] = multiplyProperties(obj[key], factor);
    }
  }
  return obj;
};

const strokeAndFillRect = (context, x, y, w, h) => {
  context.fillRect(x, y, w, h);
  context.strokeRect(x, y, w, h);
};

const generateSizingImage = async (state = {}, context) => {
  let fillStyle;
  try {
    const img = await loadImage("./sizing/pattern.jpg");
    const pattern = context.createPattern(img, "repeat");
    fillStyle = pattern;
  } catch (e) {
    fillStyle = "gray";
  }

  //correction to make the image easily readable
  const correction = 5 / state.materialThickness;

  //saving old state for dimensions
  const dimensionsState = cloneDeep(state);

  //multiplyiong old state so everything is in new dimensions
  state = multiplyProperties(cloneDeep(state), correction);

  //getting values
  const width = state.width;
  const height = state.height;
  const depth = state.depth;
  const cols = state.columns.length;
  const sizingArrowHeight = 50;
  const labelRectWidth = 18;
  const margin = 30;
  const outerMargin = 100;
  const changeColumnHeight = 185 * correction;

  let heights = [];
  for (let i = 0; i < cols; i++) {
    if (i === 0) heights.push(state.columns[i].height);
    else
      heights.push(
        Math.max(state.columns[i].height, state.columns[i - 1].height)
      );
  }
  heights.push(state.columns[cols - 1].height);

  heights = heights.filter(
    (value, index, self) => self.indexOf(value) === index && value !== height
  ); // filter unique values different from full height
  heights.sort((a, b) => b - a);

  const totalWidth =
    outerMargin * 2 +
    width +
    (heights.length + 1) * (sizingArrowHeight + margin) +
    outerMargin * 2 +
    depth +
    sizingArrowHeight +
    margin;
  const totalHeight =
    outerMargin * 2 + height + (sizingArrowHeight + margin) * 3;

  context.canvas.width = totalWidth;
  context.canvas.height = totalHeight;

  context.fillStyle = fillStyle;

  let x, y;

  x = outerMargin;
  y = outerMargin;

  //whole body height sizing arrow
  CreateSizingArrow(
    context,
    x,
    y,
    height,
    sizingArrowHeight,
    "left",
    `${dimensionsState.height}`,
    "cm"
  );

  x += sizingArrowHeight + margin;
  y += height;

  //all heights of inner sizes
  for (const columnHeight of heights) {
    CreateSizingArrow(
      context,
      x,
      y - columnHeight,
      columnHeight,
      sizingArrowHeight,
      "left",
      `${columnHeight / correction}`,
      ""
    );
    x += sizingArrowHeight + margin;
  }

  //plinth
  if (state.plinth.enabled) {
    CreateSizingArrow(
      context,
      x,
      y - state.plinth.height,
      state.plinth.height,
      sizingArrowHeight,
      "left",
      `${dimensionsState.plinth.height}`,
      ""
    );
    x += sizingArrowHeight + margin;
  }

  y -= height;

  /*DRAWING MODEL*/
  y += height;

  //base
  if (state.plinth.enabled) {
    x += state.materialThickness;
    y -= state.plinth.height;
    strokeAndFillRect(
      context,
      x,
      y,
      width - 2 * state.materialThickness,
      state.plinth.height
    );

    y -= state.materialThickness;
    strokeAndFillRect(
      context,
      x,
      y,
      width - 2 * state.materialThickness,
      state.materialThickness
    );
    x -= state.materialThickness;
    y += state.plinth.height + state.materialThickness;
  } else {
    y -= state.materialThickness;
    strokeAndFillRect(context, x, y, width, state.materialThickness);
    y += state.materialThickness;
  }

  //columns
  let columnHeight, drawHeight;

  //column 0
  columnHeight = state.columns[0].height;
  y -= columnHeight;
  if (columnHeight > changeColumnHeight) {
    drawHeight = columnHeight;
  } else {
    drawHeight = columnHeight - state.materialThickness;
    y += state.materialThickness;
  }

  if (!state.plinth.enabled) {
    drawHeight -= state.materialThickness;
  }
  strokeAndFillRect(context, x, y, state.materialThickness, drawHeight);
  if (!(columnHeight > changeColumnHeight)) {
    y -= state.materialThickness;
  }
  x += state.materialThickness + state.columns[0].width;
  y += columnHeight;

  //inner columns
  for (let i = 1; i < cols; i++) {
    columnHeight = Math.max(
      state.columns[i].height,
      state.columns[i - 1].height
    );
    y -= columnHeight;
    if (columnHeight > changeColumnHeight) {
      drawHeight = columnHeight;
    } else {
      drawHeight = columnHeight - state.materialThickness;
      y += state.materialThickness;
    }

    drawHeight -= state.materialThickness;
    if (state.plinth.enabled) {
      drawHeight -= state.plinth.height;
    }
    strokeAndFillRect(context, x, y, state.materialThickness, drawHeight);

    if (!(columnHeight > changeColumnHeight)) {
      y -= state.materialThickness;
    }

    y += columnHeight;
    x += state.columns[i].width + state.materialThickness;
  }

  //last column
  drawHeight = columnHeight = state.columns[cols - 1].height;
  y -= columnHeight;
  if (columnHeight > changeColumnHeight) {
    drawHeight = columnHeight;
  } else {
    drawHeight = columnHeight - state.materialThickness;
    y += state.materialThickness;
  }

  if (!state.plinth.enabled) {
    drawHeight -= state.materialThickness;
  }
  strokeAndFillRect(context, x, y, state.materialThickness, drawHeight);
  if (!(columnHeight > changeColumnHeight)) {
    y -= state.materialThickness;
  }
  y += columnHeight;
  x -= width - state.materialThickness;

  //compartments
  let minY = y;

  let count = 0;

  for (let i = 0; i < cols; i++) {
    x += state.materialThickness;
    y = minY - state.columns[i].height;
    y += state.materialThickness;

    for (let j = 0; j < state.columns[i].compartments.length - 1; j++) {
      if (j !== 0) y += state.innerThickness;
      CreateLabelRect(context, x, y, labelRectWidth, count);
      CreateInnerSizingArrow(
        context,
        x + state.columns[i].width / 2,
        y,
        state.columns[i].compartments[j].height,
        "vertical",
        dimensionsState.columns[i].compartments[j].height
      );
      y += state.columns[i].compartments[j].height;

      strokeAndFillRect(
        context,
        x,
        y,
        state.columns[i].width,
        state.innerThickness
      );
      count++;
    }
    y += state.innerThickness;
    CreateInnerSizingArrow(
      context,
      x + state.columns[i].width / 2,
      y,
      state.columns[i].compartments[state.columns[i].compartments.length - 1]
        .height,
      "vertical",
      dimensionsState.columns[i].compartments[
        dimensionsState.columns[i].compartments.length - 1
      ].height
    );

    CreateLabelRect(context, x, y, labelRectWidth, count);
    count++;
    x += state.columns[i].width;
  }

  x -= width - state.materialThickness;
  let startX = x;
  y = outerMargin;

  //top
  let pr = 0,
    ch = 0,
    w = 0,
    h = state.materialThickness;
  y += height;
  if (state.columns[0].height > changeColumnHeight) {
    pr = 1;
  }
  const tops = [];
  for (let i = 0; i < cols; i++) {
    y -= state.columns[i].height;
    if (pr === 0 && state.columns[i].height <= changeColumnHeight) {
      w = state.materialThickness;
    } else {
      w = 0;
    }

    if (i === 0 && state.columns[i].height > changeColumnHeight) {
      w -= state.materialThickness;
    }

    if (state.columns[i].height > changeColumnHeight) {
      pr = 1;
      w = state.columns[i].width;
      x += state.materialThickness;
      ch = 1;
    } else {
      do {
        if (ch === 1) {
          x += state.materialThickness;
          ch = 0;
        }
        w += state.columns[i].width;
        if (i < cols - 1) {
          if (state.columns[i].height < state.columns[i + 1].height) {
            pr = 0;
            break;
          }
          if (state.columns[i].height > state.columns[i + 1].height) {
            w += state.materialThickness;
            pr = 1;
            break;
          }
          w += state.materialThickness;
        } else {
          w += state.materialThickness;
          break;
        }
      } while (
        state.columns[i].height === state.columns[++i].height &&
        i < cols
      );
    }

    strokeAndFillRect(context, x, y, w, h);
    tops.push({ x, w });
    x += w;
    y += state.columns[i].height;
  }

  x = startX;

  //bottom sizing arrows
  y += margin;

  //cols sizing arrows
  for (let i = 0; i < cols; i++) {
    x += state.materialThickness;
    CreateSizingArrow(
      context,
      x,
      y,
      state.columns[i].width,
      sizingArrowHeight,
      "down",
      dimensionsState.columns[i].width,
      "cm"
    );
    x += state.columns[i].width;
  }
  x -= width - state.materialThickness;
  y += sizingArrowHeight + margin;
  //top sizing arrows
  if (tops.length > 1) {
    for (const top of tops) {
      CreateSizingArrow(
        context,
        top.x,
        y,
        top.w,
        sizingArrowHeight,
        "down",
        top.w / correction,
        "cm"
      );
    }
    y += sizingArrowHeight + margin;
  }

  //full body sizing arrow
  CreateSizingArrow(
    context,
    x,
    y,
    width,
    sizingArrowHeight,
    "down",
    dimensionsState.width,
    "cm"
  );

  x += width;
  y = outerMargin;

  //side height sizing arrow of model
  x += outerMargin;
  CreateSizingArrow(
    context,
    x,
    y,
    height,
    sizingArrowHeight,
    "left",
    `${dimensionsState.height}`,
    "cm"
  );
  x += sizingArrowHeight + margin;

  if (state.skirtingBoard && state.skirtingBoard.enabled) {
    CreateSizingArrow(
      context,
      x,
      y,
      height - state.skirtingBoard.dimensions.height,
      sizingArrowHeight,
      "left",
      `${
        dimensionsState.height - dimensionsState.skirtingBoard.dimensions.height
      }`,
      "cm"
    );

    CreateSizingArrow(
      context,
      x,
      y + height - state.skirtingBoard.dimensions.height,
      state.skirtingBoard.dimensions.height,
      sizingArrowHeight,
      "left",
      `${dimensionsState.skirtingBoard.dimensions.height}`,
      "cm"
    );
    x += sizingArrowHeight + margin;
  }
  //side display of the model
  strokeAndFillRect(context, x, y, depth, height);

  if (state.skirtingBoard && state.skirtingBoard.enabled) {
    fillStyle = context.fillStyle;
    context.fillStyle = "white";
    y += height;
    context.strokeRect(
      x,
      y - state.skirtingBoard.dimensions.height,
      state.skirtingBoard.dimensions.depth,
      state.skirtingBoard.dimensions.height
    );
    context.clearRect(
      x - context.lineWidth,
      y - state.skirtingBoard.dimensions.height,
      state.skirtingBoard.dimensions.depth,
      state.skirtingBoard.dimensions.height + context.lineWidth
    );
    y -= height;
    context.fillStyle = fillStyle;
  }

  //depth sizing arrow
  y += height + margin;

  if (state.skirtingBoard && state.skirtingBoard.enabled) {
    CreateSizingArrow(
      context,
      x,
      y,
      state.skirtingBoard.dimensions.depth,
      sizingArrowHeight,
      "down",
      `${dimensionsState.skirtingBoard.dimensions.depth}`,
      "cm"
    );
    CreateSizingArrow(
      context,
      x + state.skirtingBoard.dimensions.depth,
      y,
      depth - state.skirtingBoard.dimensions.depth,
      sizingArrowHeight,
      "down",
      `${
        dimensionsState.depth - dimensionsState.skirtingBoard.dimensions.depth
      }`,
      "cm"
    );
    y += sizingArrowHeight + margin;
  }
  CreateSizingArrow(
    context,
    x,
    y,
    depth,
    sizingArrowHeight,
    "down",
    `${dimensionsState.depth}`,
    "cm"
  );
};

export default generateSizingImage;
