import { SpotLight, PointLight } from "three";
import { Box } from "../Box/Box";
import { useMemo } from "react";

const LightElement = ({ position, height, depth, width }) => {
  const pos = [
    position[0],
    position[1] + height / 2 - 0.02,
    position[2] + depth / 4,
  ];
  const lightPos = [position[0], position[1] + height / 6, position[2]];
  const lightPos2 = [position[0], position[1] - height / 6, position[2]];

  return (
    <group>
      <pointLight
        position={lightPos}
        intensity={0.9}
        penumbra={0}
        castShadow
        mapSize={{ width: 256, height: 256 }}
        distance={height / 2}
      />
      <pointLight
        position={lightPos2}
        intensity={0.9}
        penumbra={0}
        castShadow
        mapSize={{ width: 256, height: 256 }}
        distance={height / 2}
      />
      <Box position={pos} args={[0.01, 0.01, 0.01]} color='gray' />
    </group>
  );
};

export default LightElement;
