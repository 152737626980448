import styles from "./ManualControls.module.scss";

const ManualControls = ({ prev = () => null, next = () => null, current }) => {
  return (
    <div className={styles.controls}>
      <div>
        <p>Current element: {current?.key}</p>
      </div>
      <div className={styles.controlButtons}>
        <button onClick={prev} className={styles.controlButton}>
          Prev
        </button>
        <button onClick={next} className={styles.controlButton}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ManualControls;
