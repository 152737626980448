import { useState, useEffect } from "react";
import { isNumber } from "../utils/Math";

function useNumberInput(props) {
  const [value, setValue] = useState(
    props.value !== undefined ? props.value : 0
  );
  const [newValue, setNewValue] = useState(
    props.value !== undefined ? props.value : 0
  );

  let oldValue = value;

  const max = props.max !== undefined ? props.max : 100;
  const min = props.min !== undefined ? props.min : 0;

  function onValueChangeHandler(inputValue) {
    if (inputValue > max) {
      inputValue = max;
    } else if (inputValue < min) {
      inputValue = min;
    }
    if (typeof props.onPassValue === "function" && oldValue !== inputValue) {
      props.onPassValue(inputValue);
    }
    setValue(inputValue);
    oldValue = inputValue;
  }

  function onInputValueChangeHandler(event) {
    const value = event.target.value;
    if (isNumber(value)) {
      setNewValue(value);
    }
  }

  function onInputBlurHandler(event) {
    if (isNumber(event.target.value)) {
      let inputValue;
      if (event.target.value === "-") {
        inputValue = -0;
      } else {
        inputValue = Number(event.target.value);
      }

      if (inputValue > max) {
        inputValue = max;
      } else if (inputValue < min) {
        inputValue = min;
      }
      if (value !== newValue) {
        setNewValue(inputValue);
      }
      if (typeof props.onPassValue === "function" && oldValue !== inputValue) {
        props.onPassValue(inputValue);
      }
      setValue(inputValue);
      oldValue = inputValue;
    }
  }

  function onKeyPressHandler(event) {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);
  return [
    value,
    newValue,
    onValueChangeHandler,
    onInputValueChangeHandler,
    onInputBlurHandler,
    onKeyPressHandler,
    setNewValue,
  ];
}

export default useNumberInput;
