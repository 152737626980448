let materialTypes = [];
let materials = [];

export const setMaterialTypes = (newTypes) => {
  materialTypes = newTypes;
};

export const setMaterials = (newMaterials) => {
  if (!Array.isArray(newMaterials)) {
    newMaterials = Object.values(newMaterials);
  }
  materials = newMaterials;
};

export const getMaterialTypes = () => {
  return materialTypes;
};

export const getAllMaterials = (typeId) => {
  return materials.find((item) => item.id_category === typeId)?.items ?? [];
};
