import React from "react";
import useNumberInput from "../../hooks/useNumberInput";
//styles
import styles from "./NumberInput.module.scss";

const NumberInput = (props) => {
  const max = props.max !== undefined ? props.max : 100;
  const min = props.min !== undefined ? props.min : 0;

  const [
    value,
    newValue,
    onValueChangeHandler,
    onInputValueChangeHandler,
    onInputBlurHandler,
    onKeyPressHandler,
  ] = useNumberInput(props);

  function increment() {
    if (value < max) onValueChangeHandler(value + 1);
  }

  function decrement() {
    if (value > min) onValueChangeHandler(value - 1);
  }

  return (
    <div style={{ display: "flex" }} className={props.className}>
      <input
        value={newValue}
        type="text"
        pattern="[0-9]*"
        min={min}
        max={max}
        onChange={onInputValueChangeHandler}
        onBlur={onInputBlurHandler}
        onKeyDown={onKeyPressHandler}
        className={props.inputClass}
      />
      <label>{props.label}</label>
      {props.hasButtons && (
        <div className={props.buttonsClass}>
          <button
            onClick={increment}
            className={`${styles["button"]} + ${
              value === max ? styles["button-disabled"] : ""
            }`}
          >
            +
          </button>
          <button
            onClick={decrement}
            className={`${styles["button"]} + ${
              value === min ? styles["button-disabled"] : ""
            }`}
          >
            -
          </button>
        </div>
      )}
    </div>
  );
};

export default NumberInput;
