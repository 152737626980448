import React from "react";
import { DoubleSide } from "three";

export function Plane(props) {
  const color = props.color !== undefined ? props.color : "blue";
  const args = props.args !== undefined ? props.args : [];
  return (
    <mesh receiveShadow {...props}>
      <planeGeometry args={args} />
      <meshPhongMaterial color={color} side={DoubleSide} receiveShadow={true} />
    </mesh>
  );
}
