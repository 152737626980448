import { createContext } from "react";
import { v4 } from "uuid";
import AppConfig from "../../configs/app.config";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let customerToken = localStorage.getItem("customer-token");

  if (!customerToken) {
    customerToken = AppConfig.tokenPrefix + "-" + v4();
    localStorage.setItem("customer-token", customerToken);
  }

  return (
    <AuthContext.Provider value={{ customerToken, deviceToken: customerToken }}>
      {children}
    </AuthContext.Provider>
  );
};
