import classes from "./UndoButton.module.scss";
const UndoButton = ({ undo = () => null, isDisplayed = false }) => {
  return isDisplayed ? (
    <button onClick={undo} className={classes.undo}>
      <img src='/icons/undo.svg' alt='' />
      Undo
    </button>
  ) : null;
};

export default UndoButton;
