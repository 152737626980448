import React, { useState, Fragment } from "react";
import Indicator from "../Indicator/Indicator";
import NumberInput3d from "../NumberInput3d/NumberInput3d";
import ColumnForm from "../ColumnForm/ColumnForm";
import { useFrame, useThree } from "@react-three/fiber";
//styles
import styles from "./Overlay.module.scss";
import MovePaddle from "../MovePaddle/MovePaddle";

const Overlay = (props) => {
  const [used, setUsed] = useState(undefined);
  const [diplayedColumnForm, setDisplayedColumnForm] = useState(undefined);
  const [overHtml, setOverHtml] = useState(undefined);

  const indicators = props.indicators;
  const compartmentInputFields = props.compartmentInputFields;
  const columnForms = props.columnForms;

  const state = props.state;

  const { viewport } = useThree();

  const overHandler = (pos) => {
    setOverHtml(pos);
  };
  const outHandler = (pos) => {
    setOverHtml(pos);
  };

  if (used !== undefined) {
    let i,
      n = indicators.length;
    for (i = 0; i < n; i++) {
      if (indicators[i].key !== used) {
        indicators[i].disabled = true;
      }
    }
  } else {
    let i,
      n = indicators.length;
    for (i = 0; i < n; i++) {
      indicators[i].disabled = false;
    }
  }

  useFrame(({ mouse }) => {
    let x =
      (mouse.x * viewport.getCurrentViewport().width) / 2 + props.center.x;
    let y =
      (mouse.y * viewport.getCurrentViewport().height) / 2 + props.center.y;
    let posX = 0;
    let i;
    if (overHtml !== undefined) {
      x = overHtml[0];
      y = overHtml[1];
    }
    for (i = 0; i < state.columns.length; i++) {
      if (
        x >= posX + props.position[0] &&
        x <
          state.columns[i].width / 100 +
            ((state.materialThickness / 100) * 3) / 2 +
            posX +
            props.position[0]
      ) {
        if (diplayedColumnForm !== i) {
          setDisplayedColumnForm(i);
          break;
        }
      }

      posX +=
        state.columns[i].width / 100 +
        ((state.materialThickness / 100) * 3) / 2;
    }

    if (
      x < props.position[0] ||
      x > props.position[0] + state.width / 100 ||
      y >= props.position[1] + (state.height + state.materialThickness) / 100
    ) {
      setDisplayedColumnForm(undefined);
    }
  });

  return (
    <group position={props.position}>
      {indicators.map((indicator) => {
        let handler;
        switch (indicator.type) {
          case 1:
            handler = "columnWidthChange";
            break;
          case 2:
            handler = "compartmentHeightChange";
            break;
          case 3:
            handler = "columnHeightChange";
            break;
          default:
            break;
        }
        return (
          <MovePaddle
            key={indicator.key}
            index={indicator.key}
            position={indicator.position}
            type={indicator.type}
            max={indicator.max}
            min={indicator.min}
            disabled={indicator.disabled}
            onUse={setUsed}
            center={props.center}
            handler={props.handlers[handler]}
            overHtml={overHandler}
            outHtml={outHandler}
            scaleHeight={indicator?.scale?.height}
            scaleWidth={indicator?.scale?.width}
            scaleMeasure={indicator?.scale?.measure}
            scaleStartPosition={indicator?.scale?.startPosition}
          />
        );
      })}
      {compartmentInputFields.map((inputField) => {
        function passValue(value) {
          props.handlers["compartmentHeightSet"](
            value,
            inputField.key,
            inputField.min,
            inputField.max
          );
        }

        return (
          <NumberInput3d
            position={inputField.position}
            key={inputField.key}
            index={inputField.key}
            onPassValue={passValue}
            value={inputField.value}
            label={inputField.label}
            min={inputField.min}
            max={inputField.max}
            className={`${styles["overlay-input"]} + ${
              used !== undefined ? styles["overlay-input-disabled"] : ""
            }`}
            inputClass={styles["overlay-input-field"]}
            disabled={used !== undefined}
            overHtml={overHandler}
            outHtml={outHandler}
          />
        );
      })}
      {columnForms.map((form) => {
        let column = Number(form.key.split("")[1]);
        if (column === diplayedColumnForm && used === undefined) {
          return (
            <ColumnForm
              position={form.position}
              key={form.key}
              index={form.key}
              width={form.width}
              height={form.height}
              compartments={form.compartments}
              maxWidth={form.maxWidth}
              minWidth={form.minWidth}
              maxHeight={form.maxHeight}
              minHeight={form.minHeight}
              maxCompartments={form.maxCompartments}
              minCompartments={form.minCompartments}
              className={
                used !== undefined ? styles["overlay-input-disabled"] : ""
              }
              overHtml={overHandler}
              outHtml={outHandler}
              passHeight={props.handlers["columnHeightSet"]}
              passWidth={props.handlers["columnWidthSet"]}
              passCompartments={props.handlers["columnCompartmentsSet"]}
            />
          );
        } else if (used !== undefined && used.split("")[0] === "u") {
          let thisCol = Number(used.split("")[1]);
          let thisForm = columnForms[thisCol];
          let nextForm = columnForms[thisCol - 1];
          if (column === thisCol) {
            return (
              <Fragment key={0}>
                <ColumnForm
                  position={thisForm.position}
                  key={thisForm.key}
                  index={thisForm.key}
                  width={thisForm.width}
                  height={thisForm.height}
                  compartments={thisForm.compartments}
                  maxWidth={thisForm.maxWidth}
                  minWidth={thisForm.minWidth}
                  maxHeight={thisForm.maxHeight}
                  minHeight={thisForm.minHeight}
                  maxCompartments={thisForm.maxCompartments}
                  minCompartments={thisForm.minCompartments}
                  className={
                    used !== undefined ? styles["overlay-input-disabled"] : ""
                  }
                  overHtml={overHandler}
                  outHtml={outHandler}
                  onlyWidth
                />
                <ColumnForm
                  position={nextForm.position}
                  key={nextForm.key}
                  index={nextForm.index}
                  width={nextForm.width}
                  height={nextForm.height}
                  compartments={nextForm.compartments}
                  maxWidth={nextForm.maxWidth}
                  minWidth={nextForm.minWidth}
                  maxHeight={nextForm.maxHeight}
                  minHeight={nextForm.minHeight}
                  maxCompartments={nextForm.maxCompartments}
                  minCompartments={nextForm.minCompartments}
                  className={
                    used !== undefined ? styles["overlay-input-disabled"] : ""
                  }
                  overHtml={overHandler}
                  outHtml={outHandler}
                  onlyWidth
                />
              </Fragment>
            );
          }
        }
        return null;
      })}
    </group>
  );
};

export default Overlay;
