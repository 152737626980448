import React, { useRef } from "react";
import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { degToRad } from "three/src/math/MathUtils";

const PI = Math.PI;
const PI_HALF = Math.PI / 2;

const Controls = (props) => {
  const { gl, camera } = useThree();
  const controls = useRef();

  useFrame(() => {
    camera.fov = Math.min(
      Math.max(
        30,
        ((Math.atan(
          Math.max(props.state.width, props.state.height) / 100 / 2 / 5
        ) *
          2 *
          180) /
          PI) *
          1.3
      ),
      60
    );
    camera.updateProjectionMatrix();
    controls.current.update();
  });

  return (
    <OrbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      maxAzimuthAngle={props.enableRotate ? PI_HALF : 0}
      minAzimuthAngle={props.enableRotate ? -PI_HALF : 0}
      minPolarAngle={props.enableRotate ? 0 : PI_HALF - degToRad(5)}
      maxPolarAngle={props.enableRotate ? PI_HALF : PI_HALF - degToRad(5)}
      maxDistance={props.enableZoom ? 15 : 5}
      minDistance={props.enableZoom ? 3 : 5}
      enableZoom={props.enableZoom}
      enableDamping={false}
      enablePan={false}
      enableRotate={props.enableRotate}
      target={props.target}
      enabled={true}
    ></OrbitControls>
  );
};

export default Controls;
