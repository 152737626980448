import { useEffect, useState } from "react";
import InputSelectImage from "../InputSelectImage/InputSelectImage";
import { getAllMaterials, getMaterialTypes } from "./materials_handlers";

import styles from "./MaterialSelect.module.scss";

const MaterialSelect = ({
  selectedOptions: initialSelectedOptions = {},
  onPassValue = () => null,
  defaultSelectedMaterial = null,
  ...props
}) => {
  const types = getMaterialTypes();
  const [selectedType, setSelectedType] = useState(
    types.find((item) => item.selected)?.id ?? null
  );
  const materials = getAllMaterials(selectedType);
  const [selectedMaterial, setSelectedMaterial] = useState(
    defaultSelectedMaterial ??
      materials.find((item) => item.selected)?.id ??
      null
  );

  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions
  );

  useEffect(() => {
    const material = materials.find((item) => item.id === selectedMaterial);

    if (material !== undefined) {
      if (!material.children) {
        setSelectedOptions({});
        onPassValue({
          texture: material.default.images,
          id: material.id,
          price: material.default.price,
          name: material.name,
          sku: material.sku,
          selectedOptions: selectedOptions,
        });
      } else {
        const opt = {};

        for (const item of material.options) {
          let sel = item.values.find((value) => value.selected === true);
          if (sel !== undefined) opt[item.key] = sel.key;
          else {
            opt[item.key] = item.values[0].key;
          }
        }

        setSelectedOptions(opt);
      }
    }
  }, [selectedMaterial]);

  useEffect(() => {
    const material = materials.find((item) => item.id === selectedMaterial);

    const cmp = (a, b) => {
      if (a.attribute_key > b.attribute_key) {
        return 1;
      } else if (a.attribute_key < b.attribute_key) return -1;
      else {
        return a.value_key < b.value_key ? 1 : -1;
      }
    };

    if (material !== undefined && material.children) {
      const searched = [];

      for (const selected in selectedOptions) {
        searched.push({
          attribute_key: selected,
          value_key: selectedOptions[selected],
        });
      }
      searched.sort(cmp);

      let found = null;
      for (const variant_item of material.variant_items) {
        variant_item.key_array.sort(cmp);
        if (JSON.stringify(variant_item.key_array) === JSON.stringify(searched))
          found = variant_item;
      }

      if (found !== null) {
        onPassValue({
          texture: found.images,
          id: found.id,
          price: found.price,
          name: found.name,
          sku: found.sku,
          selectedOptions: selectedOptions,
        });
      } else {
        onPassValue({
          texture: material.default.images,
          id: material.id,
          price: material.default.price,
          name: material.name,
          sku: material.sku,
          selectedOptions: selectedOptions,
        });
      }
    }
  }, [selectedOptions]);

  return (
    <div>
      <div className={styles["container"]}>
        {types.map((item) => (
          <InputSelectImage
            key={item.id}
            title={item.name}
            name='material-type'
            value={item.id}
            onPassValue={setSelectedType}
            defaultChecked={item.id === selectedType}
            image={item.image}
            className={styles.inputSelectImage}
          />
        ))}
      </div>
      <hr />
      <div style={{ clear: "both" }} className={styles["container"]}>
        {materials.map((item) => (
          <InputSelectImage
            key={item.id}
            title={item.name}
            name='material'
            value={item.id}
            onPassValue={setSelectedMaterial}
            defaultChecked={item.id === selectedMaterial}
            image={item.image}
            className={styles.inputSelectImage}
          />
        ))}
      </div>
      <div style={{ clear: "both" }}>
        {(
          materials.find((item) => item.id === selectedMaterial)?.options ?? []
        ).map((item) => (
          <div key={item.key} style={{ clear: "both" }}>
            <h6>{item.name}</h6>
            <div style={{ clear: "both" }} className={styles["container"]}>
              {item.values.map((value) => {
                return (
                  <InputSelectImage
                    key={Math.random()}
                    title={value.name}
                    name={item.key}
                    value={value.key}
                    onPassValue={(v) => {
                      setSelectedOptions({ ...selectedOptions, [item.key]: v });
                    }}
                    defaultChecked={selectedOptions[item.key] === value.key}
                    image={value.image}
                    className={styles.inputSelectImage}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MaterialSelect;
