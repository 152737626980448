import axios from "axios";
import ApiConfig from "../configs/api.config";

const axiosInstance = axios.create({
  baseURL: ApiConfig.useLocal ? ApiConfig.localURL : ApiConfig.baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const customerToken = localStorage.getItem("customer-token");

    if (customerToken) {
      config.headers["customer-token"] = customerToken;
      config.headers["device-token"] = customerToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const executeApiRequest = async (url, method, data = null) => {
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const get = (url) => {
  return executeApiRequest(url, "GET");
};

export const post = (url, data) => {
  return executeApiRequest(url, "POST", data);
};
