import { useState } from "react";
import Modal from "../Modal/Modal";
import styles from "./CheckoutModal.module.scss";

const CheckoutModal = ({ isOpen, setIsOpen, state }) => {
  const [formData, setFormData] = useState({});

  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title='Checkout'>
      <form className={styles.formContainer} onSubmit={formSubmitHandler}>
        <div className={styles.formInputs}>
          <div className={styles.inputContainer}>
            <input
              type='text'
              name='email'
              placeholder='Email'
              onChange={formChangeHandler}
              value={formData?.email}
              className={styles.input}
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type='text'
              name='name'
              placeholder='Ime'
              onChange={formChangeHandler}
              value={formData?.name}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.submitContainer}>
          <button type='submit' className={styles.submitButton}>
            Order
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CheckoutModal;
