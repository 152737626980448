import CheckoutItem from "./CheckoutItem";
import styles from "./CheckoutElements.module.scss";

const CheckoutItems = ({ items }) => {
  return (
    <div className={styles.cartItems}>
      <h2 className={styles.title}>Proizvodi u korpi:</h2>
      <div className={styles.items}>
        {items?.map((item) => (
          <CheckoutItem
            key={item.id}
            image={item?.item_data?.model_image}
            title={item?.item_token}
            sku={item?.item_token}
            item_token={item?.item_token}
            quantity={item?.quantity}
            price={0}
          />
        ))}
      </div>
      <div className={styles.radiosContainer}>
        <h1 className={styles.title}>Vrednost vaše korpe:</h1>
        <div className={styles.cartPrice}>
          <span>Ukupna vrednost korpe:</span> <span>34.560,00 RSD</span>
        </div>
        <div className={styles.cartPrice}>
          <span>Ukupna vrednost korpe sa popustom:</span>
          <span>34.560,00 RSD</span>
        </div>
        <div className={styles.cartPrice}>
          <span>Iznos popusta u korpi:</span> <span>0,00 RSD</span>
        </div>
        <div className={styles.cartPrice}>
          <span>Iznos koštanja transporta:</span> <span>0,00 RSD</span>
        </div>
        <div className={styles.cartSummary}>
          <span>Ukupna za naplatu:</span> <span>34.560,00 RSD</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutItems;
