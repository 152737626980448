import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Plane } from "../Plane/Plane";
import { OrbitControls } from "@react-three/drei";
import { Box } from "../Box/Box";
import InteriorElement from "../Interiorelement/InteriorElement";

const PI = Math.PI;
const PI_HALF = Math.PI / 2;

const pod_boja = 0x9d9d9d;
const zid_boja = 0xe6e6e2;

const ManualGraphics = ({ structure, step = -1, current }) => {
  const target = [0, 0, 0];

  let elems = [];
  for (let i = 0; i <= step; i++) {
    let struct = structure.find((e) => e.manual.order === i);
    if (struct !== undefined) {
      switch (struct.type) {
        case "interior-element":
          elems.push(
            <InteriorElement
              key={struct.key}
              element={struct.element.type}
              width={struct.args.width}
              height={struct.args.height}
              depth={struct.args.thickness}
              position={[
                struct.position.x,
                struct.position.y,
                struct.position.z,
              ]}
              material={struct.material.texture}
              materialThickness={struct.element.materialThickness}
              innerThickness={struct.element.innerThickness}
            />
          );
          break;
        default:
          elems.push(
            <Box
              key={struct.key}
              args={[
                struct.args.width,
                struct.args.thickness,
                struct.args.height,
              ]}
              position={[
                struct.position.x,
                struct.position.y,
                struct.position.z,
              ]}
              rotation={[
                struct.rotation.x,
                struct.rotation.y,
                struct.rotation.z,
              ]}
              textures={struct.material.texture}
              highlighted={struct?.key === current?.key}
            />
          );
          break;
      }
    }
  }

  return (
    <Canvas
      shadows
      style={{ height: `100%`, background: "black" }}
      camera={{
        position: [0.7, 1, 5],
        near: 0.1,
        far: 100,
        fov: 45,
      }}
    >
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[10, 5, 10]}
        rotation={[0, PI / 6, 0]}
        intensity={0.5}
        castShadow
        mapSize={{ width: 128, height: 128 }}
      />
      <OrbitControls
        //args={[camera, gl.domElement]}

        minPolarAngle={0}
        maxPolarAngle={PI_HALF}
        maxDistance={9}
        minDistance={3}
        enableZoom={true}
        enableDamping={false}
        enablePan={false}
        enableRotate={true}
        target={[0.7, 0.5, 0]}
        enabled={true}
        autoRotate={false}
      />
      <Suspense fallback={null}>
        <Plane
          color={pod_boja}
          position={[0, 0, 0]}
          args={[100, 100]}
          rotation={[PI_HALF, 0, 0]}
          receiveShadow
        />
        <Plane
          color={zid_boja}
          position={[0, 0, -10]}
          args={[100, 100]}
          rotation={[0, 0, 0]}
          receiveShadow
        />
        <Plane
          color={zid_boja}
          position={[0, 0, 10]}
          args={[100, 100]}
          rotation={[0, 0, 0]}
          receiveShadow
        />
        <Plane
          color={zid_boja}
          position={[10, 0, 0]}
          args={[100, 100]}
          rotation={[0, PI_HALF, 0]}
          receiveShadow
        />
        <Plane
          color={zid_boja}
          position={[-10, 0, 0]}
          args={[100, 100]}
          rotation={[0, PI_HALF, 0]}
          receiveShadow
        />
        <group>{elems.map((elem) => elem)}</group>
      </Suspense>
    </Canvas>
  );
};

export default ManualGraphics;
