import { Box } from "../Box/Box";

const SkirtingBoard = ({
  height = 0,
  depth = 0,
  width = 0,
  color = "white",
}) => {
  return (
    <Box
      args={[width, height, depth]}
      position={[0, height / 2, depth / 2]}
      color={color}
    />
  );
};

export default SkirtingBoard;
