import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { OrbitControls } from "@react-three/drei";
import { Plane } from "../../graphics/Plane/Plane";
import STLModel from "../../graphics/Object3D/STLModel";
import TestingMoving from "./TestingMoving/TestingMoving";
import Overlay from "./TestingMoving/Overlay";

import * as THREE from "three";

const PI = Math.PI;
const PI_HALF = Math.PI / 2;

const pod_boja = 0x9d9d9d;
const zid_boja = 0xe6e6e2;

const TestPage = () => {
  const center = new THREE.Vector3([(0, 1, 0)]);

  return (
    <Canvas
      shadows
      style={{ height: `100vh` }}
      camera={{ position: [0, 1, 4], near: 0.1, far: 100, fov: 45 }}
    >
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[10, 5, 10]}
        rotation={[0, PI / 6, 0]}
        intensity={0.5}
        castShadow
        mapSize={{ width: 128, height: 128 }}
      />
      <OrbitControls
        enableZoom={false}
        enableDamping={false}
        enablePan={false}
        enableRotate={false}
        target={center}
      />
      <Suspense fallback={null}>
        <Plane
          color={pod_boja}
          position={[0, -1, 0]}
          args={[100, 100]}
          rotation={[PI_HALF, 0, 0]}
          receiveShadow
        />
        <TestingMoving center={center} />
      </Suspense>
    </Canvas>
  );
};

export default TestPage;
