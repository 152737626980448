import React, { cloneElement } from "react";
import { cmToM } from "../../utils/Math";

const Police = ({
  elems,
  state,
  width,
  height,
  depth,
  onChangeCenter = () => null,
  clickedCompartmentHeight,
  clickedCompartment,
  ...props
}) => {
  width = cmToM(state.width);
  height = cmToM(state.height);

  depth = cmToM(state.depth);
  onChangeCenter(width / 2, height / 2, depth / 2);

  return (
    <group {...props}>
      {elems.map((elem) => {
        if (
          elem.props.position[1] === clickedCompartmentHeight &&
          elem.props.elementKey !== clickedCompartment
        ) {
          return cloneElement(elem, { highlighted: true });
        }
        return elem;
      })}
    </group>
  );
};

export default Police;
