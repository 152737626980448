import "./assets/style/global.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Checkout from "./components/Pages/Checkout";
import Manual from "./components/Pages/Manual";
import ConfiguratorPage from "./components/Pages/ConfiguratorPage/ConfiguratorPage";
import ErrorPage from "./components/Pages/ErrorPage";
import { AuthProvider } from "./components/utils/AuthContext";
import { CartProvider } from "./components/utils/CartContext";
import CheckoutSuccess from "./components/Pages/CheckoutSuccess/CheckoutSuccess";
import TestPage from "./components/Pages/Testing/Testing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header/Header";

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <CartProvider>
          <Header />
          <div className='page'>
            <BrowserRouter>
              <Routes>
                <Route path='/checkout' Component={Checkout} />
                <Route
                  path='/checkout/success/:orderToken'
                  Component={CheckoutSuccess}
                />
                <Route path='/manual/:orderToken' Component={Manual} />
                <Route path='/:token?' Component={ConfiguratorPage} />
                <Route path='/error' Component={ErrorPage} />
                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </BrowserRouter>
          </div>
        </CartProvider>
      </AuthProvider>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  );
}

export default App;
