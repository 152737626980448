import { useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({
  isOpen,
  setIsOpen = () => null,
  title,
  children,
  size = "s",
  closeable = true,
}) => {
  const handleBacgroundClick = (event) => {
    if (event.target === event.currentTarget && closeable) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && closeable) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [setIsOpen]);

  if (!isOpen) return null;
  return createPortal(
    <div className={styles.background} onClick={handleBacgroundClick}>
      <div className={`${styles.modal} ${styles[size]}`}>
        <div className={styles.modalTitleContainer}>
          <h3 className={styles.modalTitle}>{title}</h3>
          {closeable && (
            <button
              onClick={() => setIsOpen(false)}
              className={styles.closeButton}
            >
              <CloseIcon />
            </button>
          )}
        </div>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;
