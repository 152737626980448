import { Html } from "@react-three/drei";
import React from "react";
import NumberInput from "../NumberInput/NumberInput";
import styles from "./ColumnForm.module.scss";

const ColumnForm = ({
  onlyWidth = false,
  className,
  position,
  index,
  outHtml = () => null,
  overHtml = () => null,
  passWidth = () => null,
  passHeight = () => null,
  passCompartments = () => null,
  ...props
}) => {
  const outHandler = () => {
    outHtml(undefined);
  };

  const overHandler = () => {
    overHtml(position);
  };

  const passValue = (value, minValue, maxValue, passFunction) => {
    if (!onlyWidth) {
      passFunction(Math.round(value), index, minValue, maxValue);
    }
  };

  return (
    <Html position={position} className={className}>
      <div
        className={styles["form"]}
        onMouseOver={overHandler}
        onMouseOut={outHandler}
      >
        {onlyWidth ? (
          <label>{Math.round(props.width)}</label>
        ) : (
          <>
            <label>Height</label>
            <NumberInput
              value={props.height}
              hasButtons
              min={props.minHeight}
              max={props.maxHeight}
              label='cm'
              className={styles["input"]}
              inputClass={styles["input-field"]}
              buttonsClass={styles["buttons"]}
              onPassValue={(value) =>
                passValue(value, props.minHeight, props.maxHeight, passHeight)
              }
            />
            <label>Compartments</label>
            <NumberInput
              value={props.compartments}
              hasButtons
              min={props.minCompartments}
              max={props.maxCompartments}
              className={styles["input"]}
              inputClass={styles["input-field"]}
              buttonsClass={styles["buttons"]}
              onPassValue={(value) =>
                passValue(
                  value,
                  props.minCompartments,
                  props.maxCompartments,
                  passCompartments
                )
              }
            />
            <label>Width</label>
            <NumberInput
              value={props.width}
              hasButtons
              min={props.minWidth}
              max={props.maxWidth}
              label='cm'
              className={styles["input"]}
              inputClass={styles["input-field"]}
              buttonsClass={styles["buttons"]}
              onPassValue={(value) =>
                passValue(value, props.minWidth, props.maxWidth, passWidth)
              }
            />
          </>
        )}
      </div>
    </Html>
  );
};

export default ColumnForm;
