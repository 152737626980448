import { useState } from "react";
import SaveModal from "../form/SaveModal/SaveModal";
import styles from "./RightMenu.module.scss";

const Save = ({ sizingImage, modelImage, state }) => {
  const [isSaveOpen, setIsSaveOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsSaveOpen(true)} className={styles.save}>
        Save
      </div>
      <SaveModal
        isOpen={isSaveOpen}
        setIsOpen={setIsSaveOpen}
        state={state}
        sizingImage={sizingImage}
        modelImage={modelImage}
      />
    </>
  );
};

export default Save;
