import { useNavigate, useParams } from "react-router-dom";
import ConfigForm from "../../form/ConfigForm/ConfigForm";
import ManualGraphics from "../../graphics/ManualGraphics/ManualGraphics";
import { useEffect, useState } from "react";

//JSON
import manualForm from "../../../data/demo_manualForm.json";
import demoStructure from "../../../data/demo_structure.json";

//STYLES
import styles from "./Manual.module.scss";
import ManualControls from "../../manual/controls/ManualControls";
import ManualDescription from "../../manual/description/ManualDescription";
import ManualParts from "../../manual/parts/ManualParts";
import { get } from "../../../api/api";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const Manual = () => {
  const [partsShown, setPartsShown] = useState(true);
  const [step, setStep] = useState(-1);
  const { orderToken } = useParams();
  const navigate = useNavigate();

  const [structure, setStructure] = useState(demoStructure);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        let response = await get(
          `api.php?action=structure_data&item_token=${orderToken}`
        );
        if (response?.payload === null) {
          navigate("/error", {
            state: {
              code: "404",
              path: "/",
              message: "There is no manual for that token!",
            },
          });
        }
        let resStruct = response?.payload?.structure;

        resStruct = resStruct
          .filter((item) => item.manual !== undefined)
          .sort((a, b) => a?.manual?.order - b?.manual?.order);

        setStructure(resStruct);
        setLoading(false);
      } catch (e) {
        console.warn(e);
      }
    };

    getData();
  }, [orderToken]);

  let current = structure[step];

  const nextStep = () => {
    if (step < structure.length - 1) setStep(step + 1);
  };

  const prevStep = () => {
    if (step >= 0) setStep(step - 1);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div className={styles.page}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <ManualGraphics
                  structure={structure}
                  step={step}
                  current={current}
                />
                <ManualControls
                  prev={prevStep}
                  next={nextStep}
                  current={current}
                />
              </div>
              <ManualDescription
                structure={structure}
                step={step}
                current={current}
                setPartsShown={setPartsShown}
              />
            </div>
          </div>
          <ManualParts isOpen={partsShown} setIsOpen={setPartsShown} />
        </>
      )}
    </>
  );
};

export default Manual;
