import { useContext, useEffect, useState } from "react";
import { get } from "../../../api/api";
import { setConfig } from "../../utils/Math";
import {
  setMaterialTypes,
  setMaterials,
} from "../../form/MaterialSelect/materials_handlers";
import { setLesonitMaterials } from "../../form/LesonitMaterial/lesonit_material_handlers";
//STYLES
import styles from "./ConfigurationPage.module.scss";

//JSON
import config from "../../../data/config.json";
import form from "../../../data/form.json";
import lesonit from "../../../data/lesonitMaterial.json";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import Configurator from "../../Configurator";
import { useNavigate, useParams } from "react-router-dom";

const ConfiguratorPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [configurationData, setConfigurationData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [savedState, setSavedState] = useState(null);

  useEffect(() => {
    const getAppData = async () => {
      setLoading(true);
      try {
        let response = await get("api.php?action=configuration_data");

        setConfigurationData(response?.payload);
        setConfig(response?.payload);

        /* response = await get("api.php?action=configuration_form");
        setFormData(response?.payload);*/
        setFormData(form);

        response = await get("api.php?action=materials_categories");
        setMaterialTypes(response?.payload);

        response = await get("api.php?action=materials_products");
        setMaterials(response?.payload);

        if (token) {
          response = await get(
            `api.php?action=structure_data&item_token=${token}`
          );
          if (response?.payload === null) {
            navigate("/error", {
              state: {
                code: "404",
                path: "/",
                message: "There is no saved configuration!",
              },
            });
          }

          console.log(response);
          setSavedState(response?.payload);
        }

        setLesonitMaterials(lesonit);
        /*setConfigurationData(config);
        setFormData(form);
        setConfig(config);*/

        setLoading(false);
      } catch (e) {
        console.warn(e);
        //setLoading(false);
      }
    };

    getAppData();
  }, [token]);

  return (
    <div className={styles.page}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Configurator
          config={configurationData}
          savedState={savedState?.configuration_data}
          formData={formData}
        />
      )}
    </div>
  );
};

export default ConfiguratorPage;
