import Connections from "../../../data/connection";
import styles from "./ManualDescription.module.scss";
import parse from "html-react-parser";

const ManualDescription = ({
  current,
  structure,
  step = -1,
  setPartsShown = () => null,
}) => {
  return (
    <div className={styles.container}>
      <h3>Current element: {current?.key}</h3>
      <div className={styles.sides}>
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='front'
        />
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='back'
        />
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='right'
        />
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='left'
        />
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='up'
        />
        <SideDescription
          current={current}
          structure={structure}
          step={step}
          side='down'
        />
      </div>
      <div className={styles.buttons}>
        <button onClick={() => setPartsShown(true)} className={styles.button}>
          All parts
        </button>
      </div>
    </div>
  );
};

const SideDescription = ({ current, structure, step = -1, side = "front" }) => {
  let connections = current?.sides[side].connections.filter((item) => {
    let key = item.element;
    let found = structure.find((elem) => elem.key === key);
    if (found) {
      return found.manual.order < step;
    }
    return false;
  });

  return (
    connections?.length > 0 && (
      <div className={styles.sideDescription}>
        <h4>{side}</h4>
        {connections.map((elem) => (
          <div key={elem.element} className={styles.connection}>
            {elem.element}:{parse(Connections[elem.macro]?.description ?? "")}
          </div>
        ))}
      </div>
    )
  );
};

export default ManualDescription;
