import { calculateExpression } from "./Convert";

export const calculateFrontPosition = (
  doorData,
  dimensions,
  top,
  right,
  bottom,
  left
) => {
  if (top && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];
    let topValue = calculateExpression(top, doorData.dimensions[1]);
    let leftValue = calculateExpression(left, doorData.dimensions[0]);

    startingPosition[1] -= topValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (top && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[1]);
    let rightValue = calculateExpression(right, doorData.dimensions[0]);

    startingPosition[1] -= topValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }

  if (bottom && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[1]);
    let leftValue = calculateExpression(left, doorData.dimensions[0]);

    startingPosition[1] += bottomValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (bottom && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[1]);
    let rightValue = calculateExpression(right, doorData.dimensions[0]);

    startingPosition[1] += bottomValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }
};

//TODO
export const calculateTopPosition = (
  doorData,
  dimensions,
  top,
  right,
  bottom,
  left
) => {
  if (top && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (top && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }

  if (bottom && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (bottom && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }
};

export const calculateBottomPosition = (
  doorData,
  dimensions,
  top,
  right,
  bottom,
  left
) => {
  if (top && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (top && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }

  if (bottom && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (bottom && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }
};

export const calculateLeftPosition = (
  doorData,
  dimensions,
  top,
  right,
  bottom,
  left
) => {
  if (top && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (top && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }

  if (bottom && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (bottom && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }
};

export const calculateRightPosition = (
  doorData,
  dimensions,
  top,
  right,
  bottom,
  left
) => {
  if (top && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (top && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] + doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let topValue = calculateExpression(top, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] -= topValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }

  if (bottom && left) {
    const startingPosition = [
      doorData.position[0] - doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let leftValue = calculateExpression(left, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] += leftValue;

    return [...startingPosition];
  }

  if (bottom && right) {
    const startingPosition = [
      doorData.position[0] + doorData.dimensions[0] / 2,
      doorData.position[1] - doorData.dimensions[1] / 2,
      doorData.position[2] + doorData.dimensions[2] / 2,
    ];

    let bottomValue = calculateExpression(bottom, doorData.dimensions[0]);
    let rightValue = calculateExpression(right, doorData.dimensions[1]);

    startingPosition[1] += bottomValue;
    startingPosition[0] -= rightValue;

    return [...startingPosition];
  }
};
