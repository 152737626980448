const DefaultCheckoutData = {
  payment_method: "pay_on_delivery",
  payment_method_options: [],
  delivery_method: "delivery_to_address",
  delivery_method_options: [],
  accept_rules: false,
  note: null,
  customer_type_billing: "personal",
  company_name_billing: null,
  pib_billing: null,
  maticni_broj_billing: null,
  first_name_billing: "",
  last_name_billing: "",
  phone_billing: "",
  email_billing: "",
  address_billing: "",
  object_number_billing: "",
  floor_billing: null,
  apartment_number_billing: null,
  id_town_billing: null,
  town_name_billing: "",
  zip_code_billing: "",
  id_municipality_billing: null,
  municipality_name_billing: null,
  id_country_billing: null,
  country_name_billing: "",
  note_billing: null,
  company_name_shipping: null,
  pib_shipping: null,
  maticni_broj_shipping: null,
  first_name_shipping: "",
  last_name_shipping: "",
  phone_shipping: "",
  email_shipping: "",
  address_shipping: "",
  object_number_shipping: "",
  floor_shipping: "",
  apartment_number_shipping: "",
  id_town_shipping: null,
  town_name_shipping: "",
  zip_code_shipping: "",
  id_municipality_shipping: null,
  municipality_name_shipping: null,
  id_country_shipping: "",
  country_name_shipping: "",
  note_shipping: null,
};

export default DefaultCheckoutData;
