import { useEffect, useState } from "react";
import InputSelectImage from "../InputSelectImage/InputSelectImage";
import { getAllLesonitMaterials } from "./lesonit_material_handlers";

const LesonitMaterials = ({ onPassValue, selected }) => {
  const materials = getAllLesonitMaterials();
  const [selectedMaterial, setSelectedMaterial] = useState(selected ?? null);

  useEffect(() => {
    let found = materials.find((item) => item.id === selectedMaterial);
    if (found !== undefined) {
      onPassValue({ id: selectedMaterial, texture: found?.default?.images });
    }
  }, [selectedMaterial]);

  return (
    <div>
      {materials.map((item) => (
        <InputSelectImage
          key={item.id}
          title={item.name}
          name='material'
          value={item.id}
          onPassValue={setSelectedMaterial}
          defaultChecked={item.id === selectedMaterial}
          image={item.image}
        />
      ))}
    </div>
  );
};

export default LesonitMaterials;
