import { useState, useEffect } from "react";
import { post } from "../../../api/api";
import { generate } from "../../utils/Math";
import Modal from "../Modal/Modal";
import styles from "./SaveModal.module.scss";

const SaveModal = ({
  isOpen,
  setIsOpen,
  state,
  sizingImage = "",
  modelImage = "",
}) => {
  const [formData, setFormData] = useState({});
  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const [status, setStatus] = useState("closed");

  const [token, setToken] = useState(null);

  useEffect(() => {
    setStatus(isOpen ? "open" : "closed");
  }, [isOpen]);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setStatus("saving");
    try {
      const response = await post("api.php?action=set_saved_item", {
        customer: { ...formData },
        item_data: { technical_image: sizingImage, model_image: modelImage },
        file_data: { configuration_data: state, structure: generate(state)[1] },
      });
      setStatus("saved");
      setToken(response?.payload?.item_token);
      //window.open(response?.payload?.redirection_url);
    } catch (error) {
      console.warn(error);
    }
  };

  const title = {
    open: "Save your configuration",
    saving: "Saving your configuration",
    saved: "",
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={title[status]}>
      {status === "open" && (
        <div className={styles.formContainer}>
          <p className={styles.description}>
            Enter your e-mail address and we will send you a link to your piece
            of furniture. By doing so, you can order it directly or customise it
            further according to your ideas.
          </p>
          <form onSubmit={formSubmitHandler}>
            <div className={styles.formInputs}>
              <div className={styles.inputContainer}>
                <input
                  type='text'
                  name='email'
                  placeholder='Email'
                  onChange={formChangeHandler}
                  defaultValue={formData?.email}
                  className={styles.input}
                />
              </div>
              <div className={styles.inputContainer}>
                <textarea
                  name='comment'
                  placeholder='Comment'
                  onChange={formChangeHandler}
                  value={formData?.comment}
                  className={styles.input}
                  rows='10'
                />
              </div>
            </div>
            <div className={styles.submitContainer}>
              <button type='submit' className={styles.submitButton}>
                Save now
              </button>
            </div>
          </form>
        </div>
      )}
      {status === "saving" && (
        <div className={styles.saved}>
          <p>Your work is being saved...</p>
          <img src='/loading/handsaw.gif' alt='' width='30%' />
        </div>
      )}
      {status === "saved" && (
        <div className={styles.saved}>
          <h2>Your configuration has been saved!</h2>
          <p>Check your e-mail for the link to your furniture.</p>
          {token && (
            <a
              href={`https://roverkonfigurator.croonus.com/${token}`}
            >{`https://roverkonfigurator.croonus.com/${token}`}</a>
          )}
        </div>
      )}
    </Modal>
  );
};

export default SaveModal;
