/**
 * Creates arrow fitting in fiven rectangle with thew tip on the side given by orientation
 * @param {object} context canvas 2d context
 * @param {number} x
 * @param {number} y
 * @param {number} w
 * @param {number} h
 * @param {"up"|"down"|"left"|"right"} orientation
 */

const CreateArrow = (context, x, y, w, h, orientation) => {
  let region = new Path2D();
  context.translate(x + w / 2, y + h / 2);
  switch (orientation) {
    case "up":
      context.rotate(Math.PI / 2);
      break;
    case "right":
      context.rotate(Math.PI);
      break;
    case "down":
      context.rotate((3 * Math.PI) / 2);
      break;
    case "left":
      context.rotate(0);
      break;
    default:
      break;
  }
  context.translate(-(x + w / 2), -(y + h / 2));
  region.moveTo(x, y + h / 2);
  region.lineTo(x + w, y);
  region.lineTo(x + w, y + h);
  region.lineTo(x, y + h / 2);
  region.closePath();
  context.fill(region);

  context.setTransform(1, 0, 0, 1, 0, 0);
};

/**
 *
 * @param {object} context canvas 2d context
 * @param {number} x
 * @param {number} y
 * @param {number} w
 * @param {number} h
 * @param {"up"|"down"|"left"|"right"} orientation
 * @param {number|string} value number displayed in arrow
 * @param {string} dimension dimension of given number
 */

export const CreateSizingArrow = (
  context,
  x = 0,
  y = 0,
  w = 0,
  h = 0,
  orientation,
  value,
  dimension
) => {
  context.save();

  context.strokeStyle = "black";
  context.fillStyle = "black";
  context.lineWidth = 0.5;

  context.font = "15px sanserif";
  context.textAlign = "center";

  const arrowWidth = 8;

  if (!isNaN(value)) {
    value = Math.round(value * 100) / 100;
  }

  let text = value + dimension;

  if (context.measureText(text).width > w - 2 * arrowWidth) {
    text = value;
  }

  let drawArrows = true;
  if (2 * arrowWidth > w) {
    drawArrows = false;
  }

  switch (orientation) {
    case "up":
      context.strokeRect(x, y, 0, h);
      context.strokeRect(x + w, y, 0, h);
      context.strokeRect(x, y + 0.3 * h, w, 0);

      if (text !== undefined) {
        context.fillText(text, x + w / 2, y + 0.2 * h, w);
      }

      if (drawArrows) {
        CreateArrow(
          context,
          x + w - arrowWidth,
          y + 0.3 * h - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "right"
        );

        CreateArrow(
          context,
          x,
          y + 0.3 * h - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "left"
        );
      }
      break;
    case "right":
      context.strokeRect(x, y, h, 0);
      context.strokeRect(x, y + w, h, 0);
      context.strokeRect(x + 0.7 * h, y, 0, w);

      if (text !== undefined) {
        context.translate(x + 0.8 * h, y + w / 2);
        context.rotate(Math.PI / 2);
        context.translate(-(x + 0.8 * h), -(y + w / 2));

        context.fillText(text, x + 0.8 * h, y + w / 2, w);

        context.setTransform(1, 0, 0, 1, 0, 0);
      }

      if (drawArrows) {
        CreateArrow(
          context,
          x + 0.7 * h - arrowWidth / 2,
          y + w - arrowWidth,
          arrowWidth,
          arrowWidth,
          "down"
        );

        CreateArrow(
          context,
          x + 0.7 * h - arrowWidth / 2,
          y,
          arrowWidth,
          arrowWidth,
          "up"
        );
      }
      break;
    case "down":
      context.strokeRect(x, y, 0, h);
      context.strokeRect(x + w, y, 0, h);
      context.strokeRect(x, y + 0.7 * h, w, 0);

      if (text !== undefined) {
        context.fillText(text, x + w / 2, y + 0.6 * h, w);
      }

      if (drawArrows) {
        CreateArrow(
          context,
          x + w - arrowWidth,
          y + 0.7 * h - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "right"
        );

        CreateArrow(
          context,
          x,
          y + 0.7 * h - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "left"
        );
      }
      break;
    case "left":
      context.strokeRect(x, y, h, 0);
      context.strokeRect(x, y + w, h, 0);
      context.strokeRect(x + 0.3 * h, y, 0, w);

      if (text !== undefined) {
        context.translate(x + 0.2 * h, y + w / 2);
        context.rotate(-Math.PI / 2);
        context.translate(-(x + 0.2 * h), -(y + w / 2));

        context.fillText(text, x + 0.2 * h, y + w / 2, w);

        context.setTransform(1, 0, 0, 1, 0, 0);
      }

      if (drawArrows) {
        CreateArrow(
          context,
          x + 0.3 * h - arrowWidth / 2,
          y + w - arrowWidth,
          arrowWidth,
          arrowWidth,
          "down"
        );

        CreateArrow(
          context,
          x + 0.3 * h - arrowWidth / 2,
          y,
          arrowWidth,
          arrowWidth,
          "up"
        );
      }
      break;
    default:
      break;
  }

  context.restore();
};

/**
 * @param {object} context canvas 2d context
 * @param {number} x X coordinate of the starting point
 * @param {number} y Y coordinate of the starting point
 * @param {number} w Width of an arrow (or height in case of vertical)
 * @param {"horizontal"|"vertical"} orientation
 * @param {number|string} value Value displayed in arrow
 * @param {string} dimension Dimension of the value
 */
export const CreateInnerSizingArrow = (
  context,
  x = 0,
  y = 0,
  w = 0,
  orientation,
  value,
  dimension = ""
) => {
  context.save();

  context.strokeStyle = "black";
  context.fillStyle = "black";
  context.lineWidth = 0.5;

  context.font = "15px sanserif";
  context.textAlign = "center";

  const arrowWidth = 8;

  if (!isNaN(value)) {
    value = Math.round(value * 100) / 100;
  }

  let text = value + dimension;

  if (context.measureText(text).width > w - 2 * arrowWidth) {
    text = value;
  }

  const textWidth = context.measureText(text).width;

  let drawArrows = true;
  if (2 * arrowWidth > w) {
    drawArrows = false;
  }

  switch (orientation) {
    case "horizontal":
      context.strokeRect(x, y, w, 0);
      if (drawArrows) {
        CreateArrow(
          context,
          x + w - arrowWidth,
          y - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "right"
        );

        CreateArrow(
          context,
          x,
          y - arrowWidth / 2,
          arrowWidth,
          arrowWidth,
          "left"
        );
      }
      if (text !== undefined) {
        context.save();
        context.fillStyle = "white";
        context.fillRect(x + w / 2 - textWidth / 2, y - 5, textWidth, 12);
        context.restore();
        context.fillText(text, x + w / 2, y + 5, w);
      }

      break;
    case "vertical":
      context.strokeRect(x, y, 0, w);
      if (drawArrows) {
        CreateArrow(
          context,
          x - arrowWidth / 2,
          y + w - arrowWidth,
          arrowWidth,
          arrowWidth,
          "down"
        );

        CreateArrow(
          context,
          x - arrowWidth / 2,
          y,
          arrowWidth,
          arrowWidth,
          "up"
        );
      }
      if (text !== undefined) {
        context.save();
        context.fillStyle = "white";
        context.fillRect(x - textWidth / 2, y + w / 2 - 8, textWidth, 16);
        context.restore();
        context.fillText(text, x, y + w / 2 + 4, w);
      }

      break;
    default:
      break;
  }

  context.restore();
};

export const CreateLabelRect = (context, x, y, w, text) => {
  context.save();

  context.strokeStyle = "gray";
  context.fillStyle = "black";
  context.lineWidth = 1;

  context.font = w * 0.7 + "px sanserif";
  context.textAlign = "center";
  context.textBaseline = "middle";

  context.strokeRect(x, y, w, w);

  context.fillText(text, x + w / 2, y + w / 2);

  context.restore();
};
