import OldSaveButton from "../TEMPORARY/SaveButton";
import Checkout from "./Checkout";
import styles from "./RightMenu.module.scss";
import Save from "./Save";

const RightMenu = ({ siznigImage, modelImage, state }) => {
  return (
    <div className={styles.menuContainer}>
      <Checkout />
      <Save siznigImage={siznigImage} modelImage={modelImage} state={state} />
      <div className={styles.menuOption}>
        <OldSaveButton state={state} />
      </div>
    </div>
  );
};

export default RightMenu;
