import { useContext } from "react";
import styles from "./CheckoutElements.module.scss";
import { CartContext } from "../utils/CartContext";

const CheckoutItem = ({ image, title, sku, price, quantity, item_token }) => {
  const { removeFromCart } = useContext(CartContext);

  return (
    <div className={styles.checkoutItem}>
      <div className={styles.itemImage}>
        <img src={image} alt='' width={80} />
      </div>
      <div className={styles.itemData}>
        <h2 className={styles.itemTitle}>{title}</h2>
        <p className={styles.itemDataRow}>Šifra: {sku}</p>
        <p className={styles.itemDataRow}>Količina: {quantity}</p>
        <p className={styles.itemDataRow}>
          Ukupan iznos: <b>{price}</b>
        </p>
      </div>
      <button
        className={styles.close}
        onClick={() => removeFromCart(item_token)}
      >
        X
      </button>
    </div>
  );
};

export default CheckoutItem;
