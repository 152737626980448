import styles from "./InputNumber.module.scss";

const InputNumber = ({ label, min, max, value, onPassValue = () => null }) => {
  return (
    <span className={styles.container}>
      {label}:
      <input
        type='number'
        min={min}
        max={max}
        value={value}
        onChange={({ target }) => {
          if (target.value > max) {
            onPassValue(max);
          } else if (target.value < min) {
            onPassValue(min);
          } else {
            onPassValue(target.value);
          }
        }}
        className={styles.input}
      />
    </span>
  );
};

export default InputNumber;
