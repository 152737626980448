import { useEffect, useRef } from "react";
import { generateSizing } from "../utils/Math";
import generateSizingImage from "../utils/generateSizingImage/generateSizingImage";

const SaveSizing = ({ state, saveImage = () => {} }) => {
  const canvasContainer = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    canvasRef.current.setAttribute(
      "width",
      canvasContainer.current.clientWidth
    );
    canvasRef.current.setAttribute(
      "height",
      canvasContainer.current.clientHeight
    );
  }, [canvasContainer]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const drawCanvasContent = async () => {
      try {
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.fillStyle = "white";
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        //await generateSizing(state, context, 0.9);
        await generateSizingImage(state, context);
        saveImage(canvas.toDataURL("image/png"));
      } catch (error) {
        console.error("Error generating canvas content:", error);
      }
    };

    drawCanvasContent();
  }, [state]);

  return (
    <div style={{}}>
      <div ref={canvasContainer}>
        <canvas
          style={{
            backgroundColor: "white",
            position: "absolute",
            left: "-99999px",
          }}
          ref={canvasRef}
          width='2500'
          height='1100'
        />
      </div>
    </div>
  );
};

export default SaveSizing;
