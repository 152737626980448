let materials = [];

export const setLesonitMaterials = (newMaterials) => {
  if (!Array.isArray(newMaterials)) {
    newMaterials = Object.values(newMaterials);
  }
  materials = newMaterials;
};

export const getAllLesonitMaterials = () => {
  return materials;
};
