import { degToRad } from "three/src/math/MathUtils";
import STLModel from "../Object3D/STLModel";
import { convertToMeters } from "../../utils/Convert";
import { cloneDeep } from "lodash";
import { RotateDimensions } from "../../utils/RotateDimensions";
import { useEffect, useState } from "react";
import {
  calculateBottomPosition,
  calculateFrontPosition,
  calculateLeftPosition,
  calculateRightPosition,
  calculateTopPosition,
} from "../../utils/CalculateHandlePosition";
import { config } from "../../utils/Math";

const Handle = ({
  fileType = "stl",
  model,
  dimensions,
  rotation,
  positions,
  doorData,
}) => {
  const [dimension, setDimension] = useState({ width: 0, height: 0, depth: 0 });
  const [position, setPosition] = useState([0, 0, 0]);

  useEffect(() => {
    let newDimensions = {};
    newDimensions.width = convertToMeters(dimensions.width);
    newDimensions.height = convertToMeters(dimensions.height);
    newDimensions.depth = convertToMeters(dimensions.depth);

    let rotatedDims = RotateDimensions(newDimensions, rotation);
    newDimensions.width = Math.abs(rotatedDims.width);
    newDimensions.height = Math.abs(rotatedDims.height);
    newDimensions.depth = Math.abs(rotatedDims.depth);

    let newPosition = calculatePosition(
      cloneDeep(doorData),
      cloneDeep(newDimensions),
      cloneDeep(positions)
    );
    setPosition(newPosition);
    setDimension(newDimensions);
  }, [positions, doorData, dimensions, rotation]);

  switch (fileType) {
    case "stl":
    default:
      return (
        <STLModel
          stlPath={model}
          position={position}
          rotation={[
            degToRad(rotation.x),
            degToRad(rotation.y),
            degToRad(rotation.z),
          ]}
          width={dimension.width}
          scale={0.01}
          dynamicScale
        />
      );
  }
};

export default Handle;

const calculatePosition = (doorData, dimensions, positions) => {
  const topHeight = doorData.position[1] + doorData.dimensions[1] / 2;
  const bottomHeight = doorData.position[1] - doorData.dimensions[1] / 2;

  const mountigHeight = config?.DEFAULTS?.handle.mountingHeight / 100;

  const height = topHeight;

  let maxHeight = null;

  if (
    mountigHeight > bottomHeight + dimensions.height &&
    mountigHeight < topHeight - dimensions.height
  ) {
    maxHeight = {};
    if (positions.centered) {
      maxHeight.left = positions.centered.left;
      maxHeight.right = positions.centered.right;
      maxHeight.side = positions.centered.side;
      maxHeight.bottom = `${mountigHeight - bottomHeight}m`;
    } else {
      maxHeight = positions?.default;
    }
  } else {
    for (const key in positions) {
      if (!isNaN(Number(key))) {
        if (Number(key) >= height * 1000) {
          maxHeight = positions[key];
          break;
        }
      }
    }

    if (maxHeight == null) {
      maxHeight = positions?.default;
    }
  }

  if (maxHeight == null) {
    throw new Error("Handle positions doesnt have value for height:" + height);
  }

  switch (maxHeight.side) {
    case "top":
      return calculateTopPosition(
        doorData,
        dimensions,
        maxHeight.top,
        maxHeight.right,
        maxHeight.bottom,
        maxHeight.left
      );
    case "bottom":
      return calculateBottomPosition(
        doorData,
        dimensions,
        maxHeight.top,
        maxHeight.right,
        maxHeight.bottom,
        maxHeight.left
      );
    case "left":
      return calculateLeftPosition(
        doorData,
        dimensions,
        maxHeight.top,
        maxHeight.right,
        maxHeight.bottom,
        maxHeight.left
      );
    case "right":
      return calculateRightPosition(
        doorData,
        dimensions,
        maxHeight.top,
        maxHeight.right,
        maxHeight.bottom,
        maxHeight.left
      );
    case "front":
    default:
      return calculateFrontPosition(
        doorData,
        dimensions,
        maxHeight.top,
        maxHeight.right,
        maxHeight.bottom,
        maxHeight.left
      );
  }
};
