import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Plane } from "./Plane/Plane";
import { Stats } from "@react-three/drei";
import * as THREE from "three";
import Police from "./Police/Police";
import Overlay from "./Overlay/Overlay";
import Controls from "./OrbitControls/Controls";
import {
  generate,
  generateColumnForms,
  generateCompartmentInputFields,
  generateIndicators,
} from "../utils/Math";
import DoorsSelect from "./DoorsSelect/DoorsSelect";
import Object3D from "./Object3D/Object3D";
import SkirtingBoard from "./SkirtingBoard/SkirtingBoard";
import InteriorOptionsSelect from "./InteriorOptionsSelect/InteriorOptionSelect";

const PI = Math.PI;
const PI_HALF = Math.PI / 2;

const pod_boja = 0x9d9d9d;
const zid_boja = 0xe6e6e2;

const Graphics = ({ clickedCompartment, doorDisplayed, ...props }) => {
  let position = [0, 0, 0];

  if (props.state.wallMount.enabled) {
    position = [0, 0.5, 0];
  }

  const [center, setCenter] = useState(
    new THREE.Vector3(0 + position[0], 0.8 + position[1], 0 + position[2])
  );

  useEffect(() => {
    let position = [0, 0, 0];

    if (props.state.wallMount.enabled) {
      position = [0, 0.5, 0];
    }
    setCenter(
      new THREE.Vector3(0 + position[0], 0.8 + position[1], 0 + position[2])
    );
  }, [props.state.wallMount.enabled]);

  const [overlayShown, setOverlayShown] = useState(false);
  let oldCenter = center;

  function changeCenter(x, y, z) {
    if (oldCenter.x !== x || oldCenter.y !== y || oldCenter.z !== z)
      setCenter(
        new THREE.Vector3(x + position[0], y + position[1], z + position[2])
      );
  }

  const [elems, , area, volume, clickedCompartmentHeight] = generate(
    props.state,
    clickedCompartment,
    doorDisplayed
  );

  useEffect(() => {
    props.overlayHandlers.areaChangeHandler(area);
    props.overlayHandlers.volumeChangeHandler(volume);
  }, [area, volume]);

  const indicators = generateIndicators(props.indicatorsState);
  const compartmentInputFields = generateCompartmentInputFields(props.state);
  const columnForms = generateColumnForms(props.state);

  return (
    <Canvas
      shadows
      style={props.style}
      camera={{
        position: [
          center.x + props.state.width / 200,
          center.y + props.state.height / 50,
          5,
        ],
        near: 0.1,
        far: 100,
        fov: 45,
      }}
      onMouseOver={() => {
        setOverlayShown(true);
      }}
      onMouseOut={() => {
        setOverlayShown(false);
      }}
    >
      <Stats />
      <Controls
        target={center}
        state={props.state}
        enableRotate={props.enableRotate}
        enableZoom={props.enableRotate}
      />
      <ambientLight intensity={0.3} />
      <directionalLight
        position={[10, 5, 10]}
        rotation={[0, PI / 6, 0]}
        intensity={0.2}
        castShadow
        shadow-mapSize={[1024, 1024]}
      />
      <directionalLight
        position={[-10, 5, 10]}
        rotation={[0, -PI / 6, 0]}
        intensity={0.2}
        castShadow
        shadow-mapSize={[1024, 1024]}
      />
      <Suspense fallback={null}>
        <Plane
          color={pod_boja}
          position={[0, 0, 0]}
          args={[100, 100]}
          rotation={[PI_HALF, 0, 0]}
          receiveShadow={true}
        />
        <Plane
          color={zid_boja}
          position={[0, 0, 0]}
          args={[100, 100]}
          rotation={[0, 0, 0]}
          receiveShadow={true}
        />
        <Police
          position={position}
          onChangeCenter={changeCenter}
          state={props.state}
          elems={elems}
          clickedCompartmentHeight={clickedCompartmentHeight}
          clickedCompartment={clickedCompartment}
        />
        {props.state.skirtingBoard?.enabled &&
          props.state.skirtingBoard?.dimensions?.height *
            props.state.skirtingBoard?.dimensions?.depth !==
            0 && (
            <SkirtingBoard
              width={100}
              height={props.state.skirtingBoard?.dimensions?.height / 100}
              depth={props.state.skirtingBoard?.dimensions?.depth / 100}
              color={pod_boja}
            />
          )}
      </Suspense>
      {!props.enableRotate &&
        overlayShown &&
        props.overlayOption === "indicators" && (
          <Overlay
            center={center}
            position={position}
            state={props.state}
            indicators={indicators}
            compartmentInputFields={compartmentInputFields}
            columnForms={columnForms}
            handlers={props.overlayHandlers}
          />
        )}
      {!props.enableRotate && props.overlayOption === "doors" && (
        <DoorsSelect
          key='doors'
          center={center}
          state={props.state}
          handler={props.overlayHandlers.selectDoors}
        />
      )}
      {!props.enableRotate && props.overlayOption === "interior" && (
        <InteriorOptionsSelect
          key='interior'
          center={center}
          state={props.state}
          handler={props.overlayHandlers.selectDoors}
        />
      )}
    </Canvas>
  );
};

export default Graphics;
