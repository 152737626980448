import React, { useEffect, useState } from "react";
import useNumberInput from "../../hooks/useNumberInput";
//styles
import styles from "./InputSlider.module.scss";
import parse from "html-react-parser";

const InputSlider = ({ minMessage = null, maxMessage = null, ...props }) => {
  const [
    value,
    newValue,
    setValue,
    onValueChangeHandler,
    onInputBlurHandler,
    onKeyPressHandler,
    setNewValue,
  ] = useNumberInput(props);

  useEffect(() => {
    setNewValue(props.value);
  }, [props.undos]);

  const max = props.max !== undefined ? props.max : 100;
  const min = props.min !== undefined ? props.min : 0;

  function decrement() {
    setValue(value - 1);
  }

  function increment() {
    setValue(value + 1);
  }

  const [minInfoClosed, setMinInfoClosed] = useState(true);

  function minInfoShowHandler() {
    setMinInfoClosed(false);
  }

  function minInfoHideHandler() {
    setMinInfoClosed(true);
  }

  const [maxInfoClosed, setMaxInfoClosed] = useState(false);

  function maxInfoShowHandler() {
    setMaxInfoClosed(false);
  }

  function maxInfoHideHandler() {
    setMaxInfoClosed(true);
  }

  return (
    <div className={styles["input-slider"]}>
      <p className={styles["title"]}>{props.title}</p>
      <button
        onClick={decrement}
        className={`${styles["control-button"]}`}
        disabled={props.disabled}
        onMouseOver={minInfoShowHandler}
        onMouseOut={minInfoHideHandler}
      >
        -
        {minMessage && value === min && (
          <div
            className={`${styles["info"]} ${
              minInfoClosed ? styles["info-hidden"] : ""
            } `}
          >
            <span>{parse(minMessage)}</span>
          </div>
        )}
      </button>

      <input
        value={newValue}
        type='text'
        pattern='[0-9]*'
        min={min}
        max={max}
        onChange={onValueChangeHandler}
        onBlur={onInputBlurHandler}
        onKeyDown={onKeyPressHandler}
        className={styles["input-field"]}
        disabled={props.disabled}
      />
      <input
        value={newValue}
        type='range'
        min={min}
        max={max}
        step={1}
        onChange={onInputBlurHandler}
        onKeyDown={onKeyPressHandler}
        disabled={props.disabled}
        className={styles["range"]}
      />
      <button
        onClick={increment}
        className={styles["control-button"]}
        disabled={props.disabled}
        onMouseOver={maxInfoShowHandler}
        onMouseOut={maxInfoHideHandler}
      >
        +
        {maxMessage && value === max && (
          <div
            className={`${styles["info"]} ${
              maxInfoClosed ? styles["info-hidden"] : ""
            } `}
          >
            <span>{parse(maxMessage)}</span>
          </div>
        )}
      </button>
    </div>
  );
};

export default InputSlider;
