import defaultConnections from "./connections.json";

const Connections = {
  excentar_base: defaultConnections.excentar,
  excentar_connect: defaultConnections.excentar,
  basic_connection: defaultConnections.tipl,
  inner_shell_connection: defaultConnections.tipl,
  drill_for_back: defaultConnections.drill_for_back,
  inner_shell_base: defaultConnections.tipl,
  plinth_base: defaultConnections.excentar,
  plinth_connection: defaultConnections.excentar,
};

export default Connections;
