import { Html } from "@react-three/drei";
import styles from "./MovePaddle.module.scss";
import { useEffect, useRef, useState } from "react";
import { Box } from "../Box/Box";
import { debounce } from "lodash";

const MovePaddle = ({
  position = [0, 0, 0],
  max = 100,
  min = 0,
  type,
  disabled,
  scaleHeight = 0,
  scaleWidth = 0,
  scaleMeasure = 0,
  scaleStartPosition = [0, 0, 0],
  overHtml = () => null,
  outHtml = () => null,
  index,
  handler = () => {},
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const prevPosition = useRef([...position]);
  const [currentPosition, setCurrentPosition] = useState([...position]);

  const debouncedSetCurrentPosition = debounce(setCurrentPosition, 0.01);

  useEffect(() => {
    if (!isClicked) {
      prevPosition.current = [...position];
    }
  }, [position, isClicked]);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsClicked(false);
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    let move, side;
    switch (type) {
      case 1:
        move = currentPosition[0] - prevPosition.current[0];

        side = move > 0 ? "right" : "left";
        move = Math.abs(move);
        handler(move * 100, index, side, isClicked);
        break;
      case 2:
      case 3:
        move = currentPosition[1] - prevPosition.current[1];
        side = move > 0 ? "up" : "down";
        move = Math.abs(move);
        handler(move * 100, index, side, isClicked);
        break;
      default:
        break;
    }

    prevPosition.current = [...currentPosition];
  }, [currentPosition, isClicked]);

  function outHandler() {
    outHtml(undefined);
  }

  function overHandler() {
    overHtml(currentPosition);
  }

  let indicatorStyle;
  let indicatorContent;
  let [elems, setElems] = useState(null);
  switch (type) {
    case 1:
      indicatorStyle = styles["indicator-left-right"];
      indicatorContent = <img src='/icons/left-right.png' alt={""} />;
      break;
    case 2:
    case 3:
      indicatorStyle = styles["indicator-up-down"];
      indicatorContent = <img src='/icons/up-down.png' alt={""} />;

      break;
    default:
      break;
  }

  useEffect(() => {
    if (isClicked) {
      switch (type) {
        case 1:
          setElems(
            generateLeftRightScale(
              scaleStartPosition,
              scaleHeight,
              scaleWidth,
              scaleMeasure,
              currentPosition,
              debouncedSetCurrentPosition,
              setIsClicked,
              min,
              max
            )
          );
          break;
        case 2:
        case 3:
          setElems(
            generateUpDownScale(
              scaleStartPosition,
              scaleHeight,
              scaleWidth,
              scaleMeasure,
              currentPosition,
              debouncedSetCurrentPosition,
              setIsClicked,
              min,
              max
            )
          );
          break;
        default:
          break;
      }
    } else {
      setElems(null);
    }
  }, [isClicked]);
  return (
    <>
      <mesh visible position={position}>
        <Html
          className={`${styles["html"]} + ${
            disabled ? styles["disabled-html"] : ""
          }`}
        >
          <div
            className={`${indicatorStyle} + ${
              disabled ? styles["disabled"] : ""
            } +  + ${styles["indicator"]}`}
            onMouseOver={overHandler}
            onMouseOut={outHandler}
            onPointerDown={() => {
              setIsClicked(true);
            }}
            onPointerUp={() => {
              setIsClicked(false);
            }}
            style={{ pointerEvents: isClicked ? "none" : "auto" }}
          >
            {!disabled && indicatorContent}
          </div>
          <div className={styles["indicator-back"]} />
        </Html>
      </mesh>
      {elems}
    </>
  );
};

export default MovePaddle;

const generateUpDownScale = (
  startPos = [0, 0, 0],
  height = 0,
  width = 0,
  measure = 0,
  currentPosition = [0, 0, 0],
  setCurrentPosition = () => {},
  setIsClicked = () => {},
  min,
  max
) => {
  startPos[1] = min;
  let elems = [];
  let j = 0;
  let pos = [...startPos];

  for (let i = startPos[1]; i < Math.min(height, max); i += measure) {
    elems.push(
      <Box
        key={i}
        position={[...pos]}
        args={[width, measure, 0.0]}
        color={j % 2 === 0 ? "blue" : "red"}
        onPointerOver={() => {
          setCurrentPosition([currentPosition[0], i, currentPosition[2]]);
        }}
        opacity={0.0}
        castShadow={false}
        receiveShadow={false}
        onPointerUp={() => {
          setIsClicked(false);
        }}
      />
    );
    j++;
    pos[1] += measure;
  }

  return elems;
};

const generateLeftRightScale = (
  startPos = [0, 0, 0],
  height = 0,
  width = 0,
  measure = 0,
  currentPosition = [0, 0, 0],
  setCurrentPosition = () => {},
  setIsClicked = () => {},
  min,
  max
) => {
  startPos[0] = min;
  let elems = [];
  let j = 0;
  let pos = [...startPos];

  for (let i = startPos[0]; i < Math.min(width, max); i += measure) {
    elems.push(
      <Box
        key={i}
        position={[...pos]}
        args={[measure, height, 0.0]}
        color={j % 2 === 0 ? "blue" : "red"}
        onPointerOver={() => {
          setCurrentPosition([i, currentPosition[0], currentPosition[2]]);
        }}
        opacity={0.0}
        castShadow={false}
        receiveShadow={false}
        onPointerUp={() => {
          setIsClicked(false);
        }}
      />
    );
    j++;
    pos[0] += measure;
  }

  return elems;
};
