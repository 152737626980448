import { Box } from "../graphics/Box/Box";
import { cloneDeep } from "lodash";
import { degToRad } from "three/src/math/MathUtils";
import { loadImage } from "./LoadImage";
import {
  CreateInnerSizingArrow,
  CreateLabelRect,
  CreateSizingArrow,
} from "./CreateSizingArrow";
import macros from "../../data/macros.json";
import DoorElement from "../graphics/DoorElement/DoorElement";
import doorsDimensions from "../../data/doorsAndDrawers.json";
import { toast } from "react-toastify";
import InteriorElement from "../graphics/Interiorelement/InteriorElement";

export let config = null;

export const setConfig = (newConfig) => {
  config = newConfig;
};

/* Matematičke funkcije za rad sa dimenzijama */
export function mToCm(meters = 0) {
  return meters * 100;
}

export function cmToM(centimeters = 0) {
  return centimeters / 100;
}

export function isNumber(value) {
  return /^-?\d+(\.\d*)?$/.test(value) || value === "";
}

/* FUNKCIJE ZA IZMENU STATE-A POLICA */
/* Matematičke funkcije za izmenu state-a*/
export function changeWidth(oldState = {}, newWidth = 0) {
  let ret = {};
  let scale = config?.WIDTH_SCALE;
  let n = scale.length;
  let cols = 1;
  for (let i = 0; i < n; i++) {
    if (newWidth >= scale[i].min_width && newWidth <= scale[i].max_width) {
      cols = scale[i].columns;
      break;
    }
  }

  let columns = changeColumns({ ...oldState, width: newWidth }, cols, {
    keepOldColumns: true,
  });
  ret = cloneDeep(oldState);
  ret = {
    ...ret,
    width: newWidth,
    columns: columns,
  };

  return ret;
}

export function changeHeight(oldState = {}, newHeight = 0) {
  let ret = {};
  let columns = changeColumns(
    {
      ...oldState,
      height: newHeight,
    },
    oldState.columns.length
  );
  ret = cloneDeep(oldState);
  ret = {
    ...ret,
    height: newHeight,
    columns: [...columns],
  };

  return ret;
}

export function changeColumns(
  oldState = {},
  n = 0,
  settings = { keepOldColumns: false, plinthChanged: false, plinthValue: 0 }
) {
  let state = cloneDeep(oldState);
  let columns = [];
  let h1 = 0;
  for (h1 = 0; h1 < state.columns.length; h1++) {
    if (state.columns[h1].compartments.length > 1) break;
  }

  for (let i = 0; i < n; i++) {
    let obj;
    let colWidth = (state.width - (n + 1) * state.materialThickness) / n;

    if (settings.keepOldColumns && state.columns[i] !== undefined) {
      obj = {
        ...state.columns[i],
        height: state.height,
        width: colWidth,
      };
    } else {
      obj = {
        width: colWidth,
        height: state.height,
        compartments: [],
      };
    }
    if (
      settings.keepOldColumns &&
      state.columns[i] !== undefined &&
      state.columns[i].compartments !== undefined
    ) {
      obj.compartments = [...state.columns[i].compartments];
      obj.height = state.columns[i].height;
      if (settings.plinthChanged) {
        let j = obj.compartments.length - 1;
        let val = settings.plinthValue;
        if (val > 0) {
          while (
            j >= 0 &&
            obj.compartments[j].height === config?.MIN.compartment.height
          ) {
            j--;
          }
        }

        if (j <= 0) {
          obj.height += val;
        } else {
          while (val) {
            if (
              obj.compartments[j].height - val >=
              config?.MIN.compartment.height
            ) {
              obj.compartments[j].height -= val;
              break;
            } else {
              obj.compartments[j].height = config?.MIN.compartment.height;
              val -=
                obj.compartments[j].height - config?.MIN.compartment.height;
            }
            j--;
          }
        }
      }
    } else {
      obj.compartments = [];
      let scale = config?.HEIGHT_SCALE;
      let m = config?.DEFAULTS.compartments;
      for (let j = 0; j < scale.length; j++) {
        if (
          obj.height >= scale[j].min_height &&
          obj.height <= scale[j].max_height
        ) {
          m = scale[j].compartments;
          break;
        }
      }
      if (h1 === state.columns.length) {
        m = 1;
      }
      for (let j = 0; j < m; j++) {
        let hasDoor = oldState?.columns[i]?.compartments[j]?.hasDoor ?? false;
        let door = oldState?.columns[i]?.compartments[j]?.door ?? {};

        let cmp = {
          height:
            (obj.height -
              (state.plinth.enabled ? state.plinth.height : 0) -
              (m - 1) * state.innerThickness -
              2 * state.materialThickness) /
            m,
          hasDoor,
          door,
        };

        obj.compartments.push(cmp);
      }
    }
    columns.push(obj);
  }
  return columns;
}

export function changeCompartments(oldState = {}, number = 0) {
  let state = cloneDeep(oldState);
  let n = state.columns.length;
  let columns = [];

  for (let i = 0; i < n; i++) {
    let obj;
    let colWidth = (state.width - (n + 1) * state.materialThickness) / n;

    if (state.columns[i] !== undefined) {
      obj = state.columns[i] = {
        ...state.columns[i],
        height: state.height,
        width: colWidth,
      };
    } else {
      obj = {
        width: colWidth,
        height: state.height,
        compartments: [],
      };
    }
    obj.compartments = [];
    let m = number;
    for (let j = 0; j < m; j++) {
      let hasDoor = oldState.columns[i]?.compartments[j]?.hasDoor ?? false;
      let door = oldState.columns[i]?.compartments[j]?.door ?? {};
      let cmp = {
        height:
          (obj.height -
            (state.plinth.enabled ? state.plinth.height : 0) -
            (m - 1) * state.innerThickness -
            2 * state.materialThickness) /
          m,
        hasDoor,
        door,
      };

      obj.compartments.push(cmp);
    }
    columns.push(obj);
  }
  return columns;
}

export function changeDepth(oldState = {}, newDepth = 0) {
  let ret = cloneDeep(oldState);
  ret.depth = newDepth;
  return ret;
}

export function changeThickness(oldState = {}, newThickness = {}) {
  let ret = {};
  let cols = changeColumns(
    {
      ...oldState,
      innerThickness: newThickness.innerThickness,
      materialThickness: newThickness.materialThickness,
    },
    oldState.columns.length
  );
  ret = cloneDeep(oldState);
  ret = {
    ...ret,
    columns: cols,
    innerThickness: newThickness.innerThickness,
    materialThickness: newThickness.materialThickness,
  };

  return ret;
}

export function changePlinthEnabled(oldState = {}, enabled = true) {
  let ret = {};
  let height = enabled ? oldState.plinth.height : 0;
  let cols = changeColumns(
    {
      ...oldState,
      plinth: {
        height: height,
        enabled: enabled,
      },
    },
    oldState.columns.length,
    {
      keepOldColumns: true,
      plinthChanged: true,
      plinthValue: enabled ? oldState.plinth.height : -oldState.plinth.height,
    }
  );
  ret = cloneDeep(oldState);
  ret = {
    ...ret,
    columns: cols,
    plinth: {
      height: oldState.plinth.height,
      enabled: enabled,
    },
  };

  return ret;
}

export function changePlinthHeight(oldState = {}, height = 0) {
  let ret = {};
  let cols = changeColumns(
    {
      ...oldState,
      plinth: {
        height: oldState.plinth.enabled ? height : 0,
        enabled: oldState.plinth.enabled,
      },
    },
    oldState.columns.length,
    {
      keepOldColumns: true,
      plinthChanged: true,
      plinthValue: oldState.plinth.enabled
        ? height - oldState.plinth.height
        : 0,
    }
  );
  ret = cloneDeep(oldState);
  ret = {
    ...ret,
    columns: cols,
    plinth: {
      height: oldState.plinth.enabled ? height : config?.DEFAULTS.plinth.height,
      enabled: oldState.plinth.enabled,
    },
  };

  return ret;
}

export function changeColumnWidth(
  oldState = {},
  columnKey = "",
  value = 0,
  direction = "right"
) {
  value = Math.round(value);
  let columns = [...oldState.columns];
  let column = Number(columnKey.split("")[1]) - 1;

  if (direction === "right") {
    if (columns[column].width >= config?.MAX.column.width) {
      return;
    }
    let i = column + 1;
    let val = value;
    if (columns[column].width + value >= config?.MAX.column.width) {
      val = config?.MAX.column.width - columns[column].width;
    }
    let cnt = 0;
    let sum = 0;
    while (i < columns.length) {
      if (columns[i].width === config?.MIN.column.width) {
        cnt++;
      }
      sum += columns[i].width;
      i++;
    }
    if (sum - val < (columns.length - column - 1) * config?.MIN.column.width) {
      value = sum - (columns.length - column - 1) * config?.MIN.column.width;
    }

    if (cnt === columns.length - column - 1) {
      return;
    }

    i = column + 1;
    let total = 0;
    while (i < columns.length) {
      if (columns[i].width - val >= config?.MIN.column.width) {
        columns[i].width -= val;
        total += val;
        break;
      } else {
        val -= columns[i].width - config?.MIN.column.width;
        total += columns[i].width - config?.MIN.column.width;
        columns[i].width = config?.MIN.column.width;
      }
      i++;
    }

    if (columns[column].width + total >= config?.MAX.column.width) {
      columns[column].width = config?.MAX.column.width;
    } else {
      columns[column].width += total;
    }
  } else if (direction === "left") {
    if (columns[column + 1].width >= config?.MAX.column.width) {
      return;
    }
    if (columns[column + 1].width + value >= config?.MAX.column.width) {
      value = config?.MAX.column.width - columns[column + 1].width;
    }

    let i = column;
    let val = value;

    let cnt = 0;
    while (i >= 0) {
      if (columns[i].width === config?.MIN.column.width) {
        cnt++;
      }
      i--;
    }
    if (cnt === column + 1) {
      return;
    }

    i = column;
    let total = 0;
    while (i >= 0 && val > 0) {
      if (columns[i].width - val >= config?.MIN.column.width) {
        columns[i].width -= val;
        total += val;
        val = 0;
      } else {
        val -= columns[i].width - config?.MIN.column.width;
        total += columns[i].width - config?.MIN.column.width;
        columns[i].width = config?.MIN.column.width;
      }
      i--;
    }
    value -= val;
    if (columns[column + 1].width + total >= config?.MAX.column.width) {
      columns[column + 1].width = config?.MAX.column.width;
    } else {
      columns[column + 1].width += total;
    }
  }

  return { ...oldState, columns: [...columns] };
}

export function changeCompartmentHeight(
  oldState = {},
  compartmentKey = "",
  value = 0,
  direction = "up"
) {
  value = Math.round(value);
  const columnKey = Number(compartmentKey.split("")[1]);
  const compartment = Number(compartmentKey.split("")[2]);
  const columns = [...oldState.columns];
  const column = columns[columnKey];

  const plinthHeight = oldState.plinth.enabled
    ? cmToM(oldState.plinth.height)
    : 0;
  const materialThickness = cmToM(oldState.materialThickness);
  const innerThickness = cmToM(oldState.innerThickness);

  let i;
  let val = value;
  let cnt;

  let maxHeight =
    column.height -
    plinthHeight * 100 -
    200 * materialThickness -
    (column.compartments.length - 1) *
      (innerThickness * 100 + config?.MIN.compartment.height);

  if (direction === "up") {
    if (column.compartments[compartment].height >= column.height) {
      return;
    }

    if (column.compartments[compartment].height + value > maxHeight) {
      value = maxHeight - column.compartments[compartment].height;
      val = value;
    }

    i = compartment - 1;
    cnt = 0;
    while (i >= 0) {
      if (column.compartments[i].height <= config?.MIN.compartment.height) {
        cnt++;
      }
      i--;
    }
    if (cnt === compartment) {
      return;
    }

    i = compartment - 1;
    let total = 0;
    while (i >= 0) {
      if (
        column.compartments[i].height - val >=
        config?.MIN.compartment.height
      ) {
        column.compartments[i].height -= val;
        total += val;
        break;
      } else {
        val -= column.compartments[i].height - config?.MIN.compartment.height;
        total += column.compartments[i].height - config?.MIN.compartment.height;
        column.compartments[i].height = config?.MIN.compartment.height;
      }
      i--;
    }

    column.compartments[compartment].height += total;
  } else if (direction === "down") {
    if (column.compartments[compartment - 1].height >= column.height) {
      return;
    }

    if (column.compartments[compartment - 1].height + value > maxHeight) {
      value = maxHeight - column.compartments[compartment - 1].height;
      val = value;
    }

    i = compartment;
    cnt = 0;

    while (i < column.compartments.length) {
      if (column.compartments[i].height === config?.MIN.compartment.height) {
        cnt++;
      }
      i++;
    }
    if (cnt === column.compartments.length - compartment) {
      return;
    }

    i = compartment;
    let total = 0;
    while (i < column.compartments.length) {
      if (
        column.compartments[i].height - val >=
        config?.MIN.compartment.height
      ) {
        column.compartments[i].height -= val;
        total += val;
        break;
      } else {
        val -= column.compartments[i].height - config?.MIN.compartment.height;
        total += column.compartments[i].height - config?.MIN.compartment.height;
        column.compartments[i].height = config?.MIN.compartment.height;
      }
      i++;
    }

    column.compartments[compartment - 1].height += total;
  }

  columns[columnKey] = column;
  return { ...oldState, columns: [...columns] };
}

export function changeColumnHeight(
  oldState = {},
  compartmentKey = "",
  value = 0,
  direction = "up"
) {
  value = Math.round(value);
  const columnKey = compartmentKey.split("")[1];
  const compartment = compartmentKey.split("")[2];
  const columns = [...oldState.columns];
  const column = columns[columnKey];

  let i;
  let val = value;
  let cnt;

  if (direction === "up") {
    if (column.height + value >= oldState.height) {
      value = oldState.height - column.height;
    }

    column.compartments[compartment].height += value;
    column.height += value;
  } else if (direction === "down") {
    i = compartment;
    cnt = 0;
    while (i < column.compartments.length) {
      if (column.compartments[i].height === config?.MIN.compartment.height) {
        cnt++;
      }
      i++;
    }
    if (cnt === column.compartments.length - compartment) {
      return;
    }

    i = compartment;
    let total = 0;
    while (i < column.compartments.length) {
      if (
        column.compartments[i].height - val >=
        config?.MIN.compartment.height
      ) {
        column.compartments[i].height -= val;
        total += val;
        break;
      } else {
        val -= column.compartments[i].height - config?.MIN.compartment.height;
        total += column.compartments[i].height - config?.MIN.compartment.height;
        column.compartments[i].height = config?.MIN.compartment.height;
      }
      i++;
    }

    column.height -= total;
  }

  columns[columnKey] = column;

  let maxHeight = 0;
  for (const column of columns) {
    maxHeight = Math.max(maxHeight, column.height);
  }

  return { ...oldState, height: maxHeight, columns: [...columns] };
}

export function setCompartmentHeight(
  oldState = {},
  value = 0,
  compartmentKey = "",
  min = config?.MIN.compartment.height,
  max = 100
) {
  const columnKey = compartmentKey.split("")[1];
  const compartment = Number(compartmentKey.split("")[2]);
  const columns = [...oldState.columns];
  const column = columns[columnKey];

  if (value >= max) {
    value = max;
  } else if (value <= min) {
    value = min;
  }

  let changeVal = value - column.compartments[compartment].height;
  let i;

  if (changeVal > 0) {
    column.compartments[compartment].height = value;
    i = compartment + 1;
    while (i < column.compartments.length) {
      if (column.compartments[i].height - changeVal > min) {
        column.compartments[i].height -= changeVal;
        changeVal = 0;
        break;
      } else {
        changeVal -= column.compartments[i].height - min;
        column.compartments[i].height = min;
      }
      i++;
    }

    if (changeVal !== 0) {
      i = compartment - 1;
      while (i >= 0) {
        if (column.compartments[i].height - changeVal > min) {
          column.compartments[i].height -= changeVal;
          changeVal = 0;
          break;
        } else {
          changeVal -= column.compartments[i].height - min;
          column.compartments[i].height = min;
        }
        i--;
      }
    }
  } else {
    column.compartments[compartment].height = value;
    changeVal = -changeVal;
    if (compartment + 1 < column.compartments.length) {
      column.compartments[compartment + 1].height += changeVal;
    } else {
      column.compartments[0].height += changeVal;
    }
  }

  return { ...oldState, columns: [...columns] };
}

export function setColumnHeight(
  oldState = {},
  value = 0,
  columnKey = "",
  min = config?.MIN.column.height,
  max = 100
) {
  if (value > max || value < min) return { ...oldState };

  let columnNr = Number(columnKey.split("")[1]);
  let columns = [...oldState.columns];
  let column = oldState.columns[columnNr];
  let m = column.compartments.length;

  let plinthHeight = oldState.plinth.enabled ? oldState.plinth.height : 0;
  let innerThickness = oldState.innerThickness;
  let materialThickness = oldState.materialThickness;
  columns[columnNr].height = value;
  for (let j = 0; j < m; j++) {
    let hasDoor = oldState.columns[columnNr]?.compartments[j]?.hasDoor ?? false;
    let door = oldState.columns[columnNr]?.compartments[j]?.door ?? {};

    let cmp = {
      height:
        (value -
          plinthHeight -
          (m - 1) * innerThickness -
          2 * materialThickness) /
        m,
      hasDoor,
      door,
    };

    columns[columnNr].compartments[j] = cmp;
  }

  let maxHeight = 0;
  for (const column of columns) {
    maxHeight = Math.max(maxHeight, column.height);
  }

  return { ...oldState, height: maxHeight, columns: [...columns] };
}

export function setColumnWidth(
  oldState = {},
  value = 0,
  columnKey = "",
  min = config?.MIN.column.width,
  max = 100
) {
  if (value > max || value < min) return { ...oldState };

  let columnNr = Number(columnKey.split("")[1]);
  let columns = [...oldState.columns];

  let change = columns[columnNr].width - value;
  if (columnNr === columns.length - 1) {
    columns[columnNr].width = value;
    columns[0].width += change;
  } else {
    columns[columnNr].width = value;
    columns[columnNr + 1].width += change;
  }

  return { ...oldState, columns: [...columns] };
}

export function setColumnCompartments(
  oldState = {},
  value = 0,
  columnKey = "",
  min = config?.MIN.column.height,
  max = 100
) {
  if (value > max || value < min) return { ...oldState };

  let columnNr = Number(columnKey.split("")[1]);
  let columns = [...oldState.columns];
  let column = cloneDeep(oldState.columns[columnNr]);
  oldState = cloneDeep(oldState);
  let m = value;

  let plinthHeight = oldState.plinth.enabled ? oldState.plinth.height : 0;
  let innerThickness = oldState.innerThickness;
  let materialThickness = oldState.materialThickness;

  columns[columnNr].compartments = [];

  for (let j = 0; j < m; j++) {
    let hasDoor = oldState.columns[columnNr]?.compartments[j]?.hasDoor ?? false;
    let door = oldState.columns[columnNr]?.compartments[j]?.door ?? {};
    let cmp = {
      height:
        (column.height -
          plinthHeight -
          (m - 1) * innerThickness -
          2 * materialThickness) /
        m,
      hasDoor,
      door,
    };

    columns[columnNr].compartments[j] = cmp;
  }

  return { ...oldState, columns: [...columns] };
}

export function setDoors(oldState = {}, selected = [], door = {}) {
  let newState = cloneDeep(oldState);
  let clm = selected[0][0];

  for (let i = 0; i < selected.length; i++) {
    let cmp = selected[i][1];

    if (door.doorType !== "none") {
      newState.columns[clm].compartments[cmp].hasDoor = true;
      newState.columns[clm].compartments[cmp].door = cloneDeep(door);
    } else {
      newState.columns[clm].compartments[cmp].hasDoor = false;
      newState.columns[clm].compartments[cmp].door = null;
    }
  }

  return newState;
}

export function recalculateDoors(oldState = {}) {
  let globalDoorOptions = oldState.globalDoorOptions;
  let replaced = {};
  let removed = [];

  for (const column of oldState.columns) {
    for (const compartment of column.compartments) {
      if (compartment.hasDoor) {
        let key = compartment.door.key;
        if (replaced[key] !== undefined) {
          compartment.door = replaced[key];
        } else {
          let newDoor = cloneDeep(compartment.door);

          let [, clm, start, end] = key.split("_");
          let selected = [];
          for (let i = start; i <= end; i++) {
            selected.push([clm, i]);
          }

          if (globalDoorOptions.enabled) {
            newDoor.options = cloneDeep(globalDoorOptions);
            delete newDoor.options.enabled;
          }
          let newDimensions;
          try {
            newDimensions = calculateDoorDimensions(
              oldState,
              selected,
              newDoor.options
            );
          } catch {
            removed.push(key);
          }
          if (!checkDoor(newDimensions, newDoor.doorType)) {
            removed.push(key);
          }
          newDoor.dimensions = cloneDeep(newDimensions);

          compartment.door = newDoor;
          replaced[key] = cloneDeep(newDoor);
        }
      }
    }
  }

  if (removed.length > 0) {
    console.warn("REMOVED SOME DOORS");
    toast.warn("Some doors have been removed!");
    for (const column of oldState.columns) {
      for (const compartment of column.compartments) {
        if (compartment.hasDoor) {
          let key = compartment.door.key;
          if (removed.includes(key)) {
            compartment.hasDoor = false;
            compartment.door = null;
          }
        }
      }
    }
  }

  return oldState;
}

export function calculateDoorDimensions(state = {}, selected, options) {
  let short = 3 / 10;
  let ret = {};
  if (selected.length > 0) {
    ret.depth = state.depth;
    ret.multipleCompartments = !!(selected.length > 1);
    let clm = selected[0][0];
    let column = state.columns[clm];
    ret.width = column.width;

    ret.width -= 2 * short;
    if (!options.inlaid) {
      ret.width += state.materialThickness;
    }
    ret.height = (selected.length - 1) * state.innerThickness;
    for (let i = 0; i < selected.length; i++) {
      let j = selected[i][1];
      ret.height += column.compartments[j].height;
    }

    ret.height -= 2 * short;
    if (!options.inlaid) {
      ret.height += state.innerThickness;
    }

    ret.position = [0, 0, 0];
    ret.position[2] = state.depth / 100 - state.materialThickness / 200;
    for (let i = 0; i < clm; i++) {
      ret.position[0] +=
        state.materialThickness / 100 + state.columns[i].width / 100;
    }
    ret.position[0] +=
      state.materialThickness / 100 + state.columns[clm].width / 200;

    ret.position[1] =
      state.columns[clm].height / 100 - state.materialThickness / 100;

    for (let i = 0; i < selected[0][1]; i++) {
      ret.position[1] -=
        state.columns[clm].compartments[i].height / 100 +
        state.innerThickness / 100;
    }

    ret.position[1] -= ret.height / 200;

    ret.position[1] -= short / 100;
    if (!options.inlaid) {
      ret.position[1] += state.innerThickness / 200;
      ret.position[2] += state.materialThickness / 100;
    }

    if (column.compartments[selected[0][1]].hasDoor) {
      ret.doorType = column.compartments[selected[0][1]].door.doorType;
    } else {
      ret.doorType = "none";
    }
    ret.selected = cloneDeep(selected);
  } else {
    ret = null;
  }

  return ret;
}

export function checkDoor(dimensions = {}, doorType = "") {
  let limitations = doorsDimensions.find(
    (item) => item.value === doorType
  )?.limitations;
  if (!limitations) {
    throw new Error("Sistemska greska!");
  }
  if (
    limitations?.width?.min > dimensions?.width ||
    limitations?.width?.max < dimensions?.width
  ) {
    return false;
  }
  if (
    limitations?.height?.min > dimensions?.height ||
    limitations?.hright?.max < dimensions?.height
  ) {
    return false;
  }

  if (
    limitations?.depth?.min > dimensions?.depth ||
    limitations?.depth?.max < dimensions?.depth
  ) {
    return false;
  }
  if (dimensions.multipleCompartments && !limitations?.multipleCompartments) {
    return false;
  }
  return true;
}

/* Izračunavanje maksimalnog i minimalnog broja kolona i redova u odnosu na state*/
export function getMaxCompartments(state = {}) {
  return Math.min(
    Math.floor(
      (state.height -
        (state.plinth.enabled ? state.plinth.height : 0) -
        state.materialThickness) /
        (config?.MIN.compartment.height + state.materialThickness)
    ),
    config?.MAX.compartments
  );
}

export function getMaxColumnCompartments(state = {}, column = {}) {
  return Math.min(
    Math.floor(
      (column.height -
        (state.plinth.enabled ? state.plinth.height : 0) -
        state.materialThickness) /
        (config?.MIN.compartment.height + state.materialThickness)
    ),
    config?.MAX.compartments
  );
}

export function getMinCompartments(state = {}) {
  return config?.MIN.compartments;
}

export function getMaxColumns(state = {}) {
  return Math.min(
    Math.floor(
      (state.width - state.materialThickness) /
        (config?.MIN.column.width + state.materialThickness)
    ),
    config?.MAX.columns
  );
}

export function getMinColumns(state = {}) {
  return Math.ceil(
    (state.width - 2 * state.materialThickness) / config?.MAX.column.width
  );
}

/*Funkcija za generisanje 3d polica*/
export function generate(state = {}, clickedCompartment, doorDisplayed = true) {
  const width = cmToM(state.width);

  const depth = cmToM(state.depth);

  const materialThickness = cmToM(state.materialThickness);
  const innerThickness = cmToM(state.innerThickness);
  const plinthHeight = cmToM(state.plinth.height);

  const cols = state.columns.length;

  const material = state.material.texture;

  const normalMap = "/textures/wood_normal2.jpg";

  const elems = [];
  const structure = [];

  let area = 0, //ukupna povrsina materijala
    volume = 0; //ukupna zapremina materijala

  //generisanje polica po delovima
  let h,
    w,
    d,
    pos = [0, 0, 0]; // h -visina ploče, w - debljina ploče, d - dubina, pos - pozicija

  //Postolje -------------
  if (state.plinth.enabled) {
    w = width - 2 * materialThickness;
    d = cmToM(config?.DEFAULTS?.pThickness) ?? materialThickness;
    h = plinthHeight;
    area += w * h;
    volume += w * h * d;
    structure.push({
      key: "p",
      args: {
        width: w,
        thickness: d,
        height: h,
      },
      area: w * h,
      volume: w * h * d,
      position: {
        x: width / 2,
        y: plinthHeight / 2,
        z: depth - 2 * materialThickness,
      },
      rotation: { x: degToRad(90), y: 0, z: 0 },
      sides: {
        left: {
          connections: [],
        },
        right: {
          connections: [],
        },
        up: {
          connections: [
            {
              element: "pr",
              macro: macros.excentar_base,
              macro_opt: { side: "back" },
            },
          ],
        },
        down: {
          connections: [],
        },
        front: {
          connections: [],
        },
        back: {
          connections: [],
        },
      },
      edge_tape: {
        left: false,
        right: false,
        up: false,
        down: true,
        front: false,
        back: false,
      },
      material: state.material,
    });
    elems.push(
      <Box
        key={"p"}
        args={[w, d, h]}
        position={[width / 2, plinthHeight / 2, depth - 2 * materialThickness]}
        rotation={[degToRad(90), 0, 0]}
        textures={material}
        normalMapImage={normalMap}
      />
    );

    w = width - 2 * materialThickness;
    d = materialThickness;
    h = depth;
    area += w * h;
    volume += w * d * h;
    structure.push({
      key: "pr",
      args: {
        width: width - 2 * materialThickness,
        thickness: materialThickness,
        height: depth,
      },
      area: w * h,
      volume: w * h * d,
      position: {
        x: width / 2,
        y: plinthHeight + materialThickness / 2,
        z: depth / 2,
      },
      rotation: { x: 0, y: 0, z: 0 },
      sides: {
        left: {
          connections: [],
        },
        right: {
          connections: [],
        },
        up: {
          connections: [],
        },
        down: {
          connections: [
            { element: "p", macro: macros.excentar_connect, macro_opt: {} },
          ],
        },
        front: {
          connections: [],
        },
        back: {
          connections: [],
        },
      },
      edge_tape: {
        left: false,
        right: false,
        up: false,
        down: false,
        front: true,
        back: true,
      },
      material: state.material,
    });
    elems.push(
      <Box
        key={"pr"}
        args={[w, d, h]}
        position={[width / 2, plinthHeight + materialThickness / 2, depth / 2]}
        rotation={[0, 0, 0]}
        textures={material}
        normalMapImage={normalMap}
      />
    );
  } else {
    w = width;
    d = materialThickness;
    h = depth;
    area += w * h;
    volume += w * h * d;
    structure.push({
      key: "pr",
      args: {
        width: width,
        thickness: materialThickness,
        height: depth,
      },
      area: w * h,
      volume: w * h * d,
      position: {
        x: width / 2,
        y: materialThickness / 2,
        z: depth / 2,
      },
      rotation: { x: 0, y: 0, z: 0 },
      sides: {
        left: {
          connections: [],
        },
        right: {
          connections: [],
        },
        up: {
          connections: [],
        },
        down: {
          connections: [],
        },
        front: {
          connections: [],
        },
        back: {
          connections: [],
        },
      },
      edge_tape: {
        left: true,
        right: true,
        up: false,
        down: false,
        front: true,
        back: true,
      },
      material: state.material,
    });
    elems.push(
      <Box
        key={"pr"}
        args={[width, materialThickness, depth]}
        position={[width / 2, materialThickness / 2, depth / 2]}
        rotation={[0, 0, 0]}
        textures={material}
        normalMapImage={normalMap}
      />
    );
  }

  // Uspravne ploče----------------------------

  // Uspravna u0
  if (state.plinth.enabled) {
    h = state.columns[0].height / 100 - materialThickness;

    w = materialThickness;
    d = depth;
    pos = [materialThickness / 2, h / 2, depth / 2];
    structure
      .find((item) => item.key === "p")
      .sides.left.connections.push({
        element: "u0",
        macro: macros.plinth_base,
        macro_opt: {},
      });
    structure
      .find((item) => item.key === "pr")
      .sides.left.connections.push({
        element: "u0",
        macro: macros.excentar_base,
        macro_opt: { side: "up" },
      });
  } else {
    h = state.columns[0].height / 100 - 2 * materialThickness;
    w = materialThickness;
    d = depth;
    pos = [materialThickness / 2, h / 2 + materialThickness, depth / 2];
    structure
      .find((item) => item.key === "pr")
      .sides.up.connections.push({
        element: "u0",
        macro: macros.excentar_connect,
        macro_opt: {},
      });
  }

  if (state.columns[0].height > 185) {
    h += materialThickness;
  }

  area += d * h;
  volume += w * d * h;
  structure.push({
    key: "u0",
    args: {
      width: h,
      thickness: w,
      height: d,
    },
    area: d * h,
    voulme: w * d * h,
    position: {
      x: pos[0],
      y: pos[1],
      z: pos[2],
    },
    rotation: { x: 0, y: 0, z: degToRad(90) },
    sides: {
      left: {
        connections: [],
      },
      right: {
        connections: state.plinth.enabled
          ? [
              { element: "p", macro: macros.plinth_connection, macro_opt: {} },
              { element: "pr", macro: macros.excentar_connect, macro_opt: {} },
            ]
          : [],
      },
      up: {
        connections: [],
      },
      down: {
        connections: state.plinth.enabled
          ? []
          : [
              {
                element: "pr",
                macro: macros.excentar_base,
                macro_opt: { side: "right" },
              },
            ],
      },
      front: {
        connections: [],
      },
      back: {
        connections: [],
      },
    },
    edge_tape: {
      left: false,
      right: false,
      up: true,
      down: true,
      front: true,
      back: true,
    },
    material: state.material,
  });
  elems.push(
    <Box
      key={"u0"}
      args={[h, w, d]}
      position={[...pos]}
      rotation={[0, 0, degToRad(90)]}
      textures={material}
      normalMapImage={normalMap}
    />
  );

  // Uspravne u1 - u(cols-1)
  for (let i = 1; i < cols; i++) {
    if (state.plinth.enabled) {
      h =
        Math.max(state.columns[i].height, state.columns[i - 1].height) / 100 -
        2 * materialThickness -
        plinthHeight;

      w = materialThickness;
      d = depth;
      pos = [
        pos[0] + state.columns[i - 1].width / 100 + materialThickness,
        h / 2 + materialThickness + plinthHeight,
        depth / 2,
      ];
    } else {
      h =
        Math.max(state.columns[i].height, state.columns[i - 1].height) / 100 -
        2 * materialThickness;
      w = materialThickness;
      d = depth;
      pos = [
        pos[0] + state.columns[i - 1].width / 100 + materialThickness,
        h / 2 + materialThickness,
        depth / 2,
      ];
    }

    if (Math.max(state.columns[i].height, state.columns[i - 1].height) > 185) {
      h += materialThickness;
    }

    structure
      .find((item) => item.key === "pr")
      .sides.up.connections.push({
        element: "u" + i,
        macro: macros.excentar_connect,
        macro_opt: {},
      });

    area += d * h;
    volume += w * d * h;
    structure.push({
      key: "u" + i,
      args: {
        width: h,
        thickness: w,
        height: d,
      },
      area: d * h,
      volume: w * d * h,
      position: {
        x: pos[0],
        y: pos[1],
        z: pos[2],
      },
      rotation: { x: 0, y: 0, z: degToRad(90) },
      sides: {
        left: {
          connections: [],
        },
        right: {
          connections: [],
        },
        up: {
          connections: [],
        },
        down: {
          connections: [
            {
              element: "pr",
              macro: macros.excentar_base,
              macro_opt: { side: "left" },
            },
          ],
        },
        front: {
          connections: [],
        },
        back: {
          connections: [],
        },
      },
      edge_tape: {
        left: false,
        right: false,
        up:
          Math.max(state.columns[i].height, state.columns[i - 1].height) > 185,
        down: false,
        front: true,
        back: true,
      },
      material: state.material,
    });
    elems.push(
      <Box
        key={"u" + i}
        args={[h, w, d]}
        position={[...pos]}
        rotation={[0, 0, degToRad(90)]}
        textures={material}
        normalMapImage={normalMap}
      />
    );
  }

  //Uspravna u(cols)
  if (state.plinth.enabled) {
    h = state.columns[cols - 1].height / 100 - materialThickness;

    w = materialThickness;
    d = depth;
    pos = [
      pos[0] + state.columns[cols - 1].width / 100 + materialThickness,
      h / 2,
      depth / 2,
    ];
    structure
      .find((item) => item.key === "p")
      .sides.right.connections.push({
        element: "u" + cols,
        macro: macros.plinth_base,
        macro_opt: {},
      });
    structure
      .find((item) => item.key === "pr")
      .sides.right.connections.push({
        element: "u" + cols,
        macro: macros.excentar_base,
        macro_opt: {
          side: "up",
        },
      });
  } else {
    h = state.columns[cols - 1].height / 100 - 2 * materialThickness;
    w = materialThickness;
    d = depth;
    pos = [
      pos[0] + state.columns[cols - 1].width / 100 + materialThickness,
      h / 2 + materialThickness,
      depth / 2,
    ];
    structure
      .find((item) => item.key === "pr")
      .sides.up.connections.push({
        element: "u" + cols,
        macro: macros.excentar_connect,
        macro_opt: {},
      });
  }

  if (state.columns[cols - 1].height > 185) {
    h += materialThickness;
  }

  area += d * h;
  volume += w * d * h;
  structure.push({
    key: "u" + cols,
    args: {
      width: h,
      thickness: w,
      height: d,
    },
    area: d * h,
    volume: w * d * h,
    position: {
      x: pos[0],
      y: pos[1],
      z: pos[2],
    },
    rotation: { x: 0, y: 0, z: degToRad(90) },
    sides: {
      left: {
        connections: state.plinth.enabled
          ? [
              { element: "p", macro: macros.plinth_connection, macro_opt: {} },
              { element: "pr", macro: macros.excentar_connect, macro_opt: {} },
            ]
          : [],
      },
      right: {
        connections: [],
      },
      up: {
        connections: [],
      },
      down: {
        connections: state.plinth.enabled
          ? []
          : [
              {
                element: "pr",
                macro: macros.excentar_base,
                macro_opt: { side: "left" },
              },
            ],
      },
      front: {
        connections: [],
      },
      back: {
        connections: [],
      },
    },
    edge_tape: {
      left: false,
      right: false,
      up: true,
      down: true,
      front: true,
      back: true,
    },
    material: state.material,
  });
  elems.push(
    <Box
      key={"u" + cols}
      args={[h, w, d]}
      position={[...pos]}
      rotation={[0, 0, degToRad(90)]}
      textures={material}
      normalMapImage={normalMap}
    />
  );

  //Gornje ploče
  const HEIGHT_CHANGE = config.DEFAULTS.columnHeightChange;
  let pr = 0;
  h = materialThickness;
  w = 0;
  d = depth;
  pos = [0, 0, 0];
  let ch = 0;
  let gP = {};
  let nalegajuca = true;
  if (state.columns[0].height > HEIGHT_CHANGE) {
    pr = 1;
    nalegajuca = false;
  }

  for (let i = 0; i < cols; i++) {
    const down = [];
    const keysUp = [];
    const keysLeft = [];
    const keysRight = [];
    nalegajuca = true;

    pos[0] += w / 2;
    let curr = [];
    if (pr === 0 && state.columns[i].height <= HEIGHT_CHANGE) {
      w = materialThickness;

      down.push("u" + i);
      keysUp.push("u" + i);
    } else {
      w = 0;
      keysLeft.push("u" + i);
    }

    if (i === 0 && state.columns[i].height > HEIGHT_CHANGE) {
      w -= materialThickness;
    }

    if (state.columns[i].height > HEIGHT_CHANGE) {
      pr = 1;
      nalegajuca = false;
      w = state.columns[i].width / 100;
      pos[0] += materialThickness;
      keysRight.push("u" + (i + 1));
      ch = 1;
      curr.push(i);
    } else {
      do {
        curr.push(i);
        if (ch === 1) {
          pos[0] += materialThickness;
          ch = 0;
        }
        w += state.columns[i].width / 100;

        if (i < cols - 1) {
          if (state.columns[i].height < state.columns[i + 1].height) {
            keysRight.push("u" + (i + 1));
            pr = 0;
            break;
          }

          if (state.columns[i].height > state.columns[i + 1].height) {
            w += materialThickness;
            down.push("u" + (i + 1));
            keysUp.push("u" + (i + 1));
            pr = 1;
            break;
          }
          w += materialThickness;
        } else {
          w += materialThickness;
          if (state.height > HEIGHT_CHANGE) {
            keysRight.push("u" + (i + 1));
          } else {
            down.push("u" + (i + 1));
            keysUp.push("u" + (i + 1));
          }

          break;
        }
        down.push("u" + (i + 1));
        keysUp.push("u" + (i + 1));
      } while (
        state.columns[i].height === state.columns[++i].height &&
        i < cols
      );
    }
    pos[0] += w / 2;
    pos = [
      pos[0],
      state.columns[i].height > HEIGHT_CHANGE
        ? state.columns[i].height / 100 - materialThickness
        : state.columns[i].height / 100 - materialThickness / 2,
      depth / 2,
    ];

    for (const key of keysLeft) {
      structure
        .find((item) => item.key === key)
        .sides.right.connections.push({
          element: "g" + i,
          macro: macros.excentar_connect,
          macro_opt: {},
        });
    }

    for (const key of keysRight) {
      structure
        .find((item) => item.key === key)
        .sides.left.connections.push({
          element: "g" + i,
          macro: macros.excentar_connect,
          macro_opt: {},
        });
    }

    for (let index = 0; index < keysUp.length; index++) {
      let key = keysUp[index];
      structure
        .find((item) => item.key === key)
        .sides.up.connections.push({
          element: "g" + i,
          macro: macros.excentar_base,
          macro_opt: { side: index === 0 ? "right" : "left" },
        });
    }

    area += w * d;
    volume += w * h * d;

    gP["g" + i] = curr;

    structure.push({
      key: "g" + i,
      args: {
        width: w,
        thickness: h,
        height: d,
      },
      area: w * d,
      volume: w * h * d,
      position: {
        x: pos[0],
        y: pos[1],
        z: pos[2],
      },
      rotation: { x: 0, y: 0, z: 0 },
      sides: {
        left: {
          connections: keysLeft.map((item) => {
            return {
              element: item,
              macro: macros.excentar_base,
              macro_opt: {
                side: "bottom",
              },
            };
          }),
        },
        right: {
          connections: keysRight.map((item) => {
            return {
              element: item,
              macro: macros.excentar_base,
              macro_opt: { side: "bottom" },
            };
          }),
        },
        up: {
          connections: [],
        },
        down: {
          connections: down.map((item) => {
            return {
              element: item,
              macro: macros.excentar_connect,
              macro_opt: {},
            };
          }),
        },
        front: {
          connections: [],
        },
        back: {
          connections: [],
        },
      },
      edge_tape: {
        left: keysLeft.length === 0,
        right: keysRight.length === 0,
        up: false,
        down: false,
        front: true,
        back: true,
      },
      material: state.material,
      additional_data: {
        nalegajuca,
      },
    });
    elems.push(
      <Box
        key={"g" + i}
        args={[w, h, d]}
        position={[...pos]}
        rotation={[0, 0, 0]}
        textures={material}
        normalMapImage={normalMap}
      />
    );
  }

  // odeljci
  h = innerThickness;
  w = 0;
  d = state?.closedBack?.enabled ? depth - cmToM(2.1) : depth;
  pos = [0, 0, 0];
  let doors = [];
  let interiors = [];
  let clickedCompartmentHeight = -1;
  for (let i = 0; i < cols; i++) {
    w = state.columns[i].width / 100;
    pos[0] += materialThickness + w / 2;
    pos[1] = state.plinth.enabled
      ? plinthHeight + materialThickness
      : materialThickness;
    let start = -1,
      end = -1;
    for (let j = state.columns[i].compartments.length - 1; j >= 0; j--) {
      d = state?.closedBack?.enabled ? depth - cmToM(2.1) : depth;
      const leftParent = structure.find((item) => item.key === "u" + i);
      const rightParent = structure.find((item) => item.key === "u" + (i + 1));

      if (state.columns[i].compartments[j].hasDoor) {
        let door = state.columns[i].compartments[j].door;
        [, , start, end] = door.key.split("_");
        if (j > start && j <= end) {
          d -= materialThickness;
        }
        if (doors.find((item) => item === door.key) === undefined) {
          doors.push(door.key);
          let macro = macros.door[door.doorType];
          if (leftParent && macro?.left) {
            leftParent.sides.right.connections.push({
              element: door.key,
              macro: macro.macro,
              macro_opt: {},
            });
          }

          if (rightParent && macro?.right) {
            rightParent.sides.left.connections.push({
              element: door.key,
              macro: macro.macro,
              macro_opt: {},
            });
          }

          let m = material;
          if (door.options.material != null) {
            m = door.options.material.texture;
          }

          structure.push({
            key: door.key,
            args: {
              width: door.dimensions.width / 100,
              thickness: door.dimensions.height / 100,
              height: materialThickness,
            },
            area: 0,
            volume: 0,
            position: {
              x: door.dimensions.position[0],
              y: door.dimensions.position[1],
              z: door.dimensions.position[2],
            },
            rotation: { x: 0, y: 0, z: 0 },
            sides: {
              left: {
                connections:
                  leftParent && macro.left
                    ? [
                        {
                          element: "u" + i,
                          macro: macro.macro,
                          macro_opt: {},
                        },
                      ]
                    : [],
              },
              right: {
                connections:
                  rightParent && macro.right
                    ? [
                        {
                          element: "u" + (i + 1),
                          macro: macro.macro,
                          macro_opt: {},
                        },
                      ]
                    : [],
              },
              up: {
                connections: [],
              },
              down: {
                connections: [],
              },
              front: {
                connections: [],
              },
              back: {
                connections: [],
              },
            },
            edge_tape: {
              left: false,
              right: false,
              up: false,
              down: false,
              front: false,
              back: false,
            },
            material: door.options.material ?? state.material,
          });
          if (doorDisplayed) {
            elems.push(
              <DoorElement
                key={door.key}
                dimensions={[
                  door.dimensions.width / 100,
                  door.dimensions.height / 100,
                  materialThickness,
                ]}
                position={door.dimensions.position}
                rotation={[0, 0, 0]}
                textures={m}
                doorType={door.doorType}
                handleCode={door.options.handle}
              />
            );
          }
        }
      }

      pos[2] = d / 2;
      if (state?.closedBack?.enabled) {
        pos[2] += cmToM(2.1);
      }
      if (
        state.columns[i].compartments[j].interior != null &&
        state.columns[i].compartments[j].interior.length > 0
      ) {
        for (const interior of state.columns[i].compartments[j].interior) {
          interiors.push("o" + i + "" + j + interior + w);
          elems.push(
            <InteriorElement
              key={"o" + i + "" + j + interior + w}
              element={interior}
              width={w}
              height={state.columns[i].compartments[j].height / 100}
              depth={d}
              position={[
                pos[0],
                pos[1] + state.columns[i].compartments[j].height / 200,
                pos[2],
              ]}
              material={material}
              materialThickness={state.materialThickness / 100}
              innerThickness={state.innerThickness / 100}
            />
          );
          structure.push({
            key: "o" + i + "" + j + interior + w,
            type: "interior-element",
            args: {
              width: w,
              thickness: d,
              height: state.columns[i].compartments[j].height / 100,
            },
            area: 0,
            volume: 0,
            position: {
              x: pos[0],
              y: pos[1] + state.columns[i].compartments[j].height / 200,
              z: pos[2],
            },
            rotation: { x: 0, y: 0, z: 0 },
            sides: {
              left: {
                connections: leftParent
                  ? [
                      {
                        element: "u" + i,
                      },
                    ]
                  : [],
              },
              right: {
                connections: rightParent
                  ? [
                      {
                        element: "u" + (i + 1),
                      },
                    ]
                  : [],
              },
              up: {
                connections: [],
              },
              down: {
                connections: [],
              },
              front: {
                connections: [],
              },
              back: {
                connections: [],
              },
            },
            edge_tape: {
              left: false,
              right: false,
              up: false,
              down: false,
              front: false,
              back: false,
            },
            material: state.material,
            element: {
              type: interior,
              materialThickness: state.materialThickness / 100,
              innerThickness: state.innerThickness / 100,
            },
          });
        }
      }
      if (j > 0) {
        pos[1] +=
          state.columns[i].compartments[j].height / 100 + innerThickness / 2;

        if (leftParent) {
          leftParent.sides.right.connections.push({
            element: "o" + i + "" + j,
            macro: macros.inner_shell_connection,
            macro_opt: {},
          });
        }

        if (rightParent) {
          rightParent.sides.left.connections.push({
            element: "o" + i + "" + j,
            macro: macros.inner_shell_connection,
            macro_opt: {},
          });
        }

        area += w * d;
        volume += w * d * h;
        structure.push({
          key: "o" + i + "" + j,
          args: {
            width: w,
            thickness: h,
            height: d,
          },
          area: w * d,
          volume: w * d * h,
          position: {
            x: pos[0],
            y: pos[1],
            z: pos[2],
          },
          rotation: { x: 0, y: 0, z: 0 },
          sides: {
            left: {
              connections: leftParent
                ? [
                    {
                      element: "u" + i,
                      macro: macros.inner_shell_base,
                      macro_opt: {},
                    },
                  ]
                : [],
            },
            right: {
              connections: rightParent
                ? [
                    {
                      element: "u" + (i + 1),
                      macro: macros.inner_shell_base,
                      macro_opt: {},
                    },
                  ]
                : [],
            },
            up: {
              connections: [],
            },
            down: {
              connections: [],
            },
            front: {
              connections: [],
            },
            back: {
              connections: [],
            },
          },
          edge_tape: {
            left: false,
            right: false,
            up: false,
            down: false,
            front: true,
            back: !state.closedBack.enabled,
          },
          material: state.material,
        });
        if (clickedCompartment === "o" + i + "" + j) {
          clickedCompartmentHeight = pos[1];
        }
        elems.push(
          <Box
            key={"o" + i + "" + j}
            elementKey={"o" + i + "" + j}
            args={[w, h, d]}
            position={[...pos]}
            rotation={[0, 0, 0]}
            textures={material}
            normalMapImage={normalMap}
          />
        );

        pos[1] += innerThickness / 2;
      }
    }
    pos[0] += w / 2;
  }

  //ledja
  pos[0] = materialThickness;
  if (state.closedBack.enabled) {
    for (let i = 0; i < cols; i++) {
      w = state.columns[i].width / 100;
      h = state.columns[i].height / 100 - 2 * materialThickness;
      pos[0] += w / 2;
      pos[1] = h / 2;
      pos[2] = cmToM(1.9);
      let leftConnection = "u" + i;
      let rightConnection = "u" + (i + 1);

      if (state.plinth.enabled) {
        h -= plinthHeight;
        pos[1] = h / 2 + plinthHeight + materialThickness;
      }
      d = 0;
      let m = [];
      let macroParent = null;
      let macroBack = null;
      let macroOptParent = {};
      let macroOptBack = {};
      let top = false,
        bottom = false;
      let dodatnaObrada = false;
      switch (state.closedBack.option) {
        case "lesonit":
          d = cmToM(0.4);
          w += cmToM(1);
          macroParent = macros.drill_for_back;
          top = true;
          bottom = true;
          dodatnaObrada = true;
          break;
        case "wood":
        default:
          d = cmToM(1.8);
          macroParent = macros.excentar_connect;
          macroBack = macros.excentar_base;
          macroOptBack.side = "front";
          break;
      }

      if (state.backMaterial.texture !== undefined) {
        m = state.backMaterial.texture;
      } else {
        m = material;
      }
      pos[2] -= d / 2;

      const leftParent = structure.find((item) => item.key === "u" + i);
      const rightParent = structure.find((item) => item.key === "u" + (i + 1));

      if (leftParent) {
        leftParent.sides.right.connections.push({
          element: "l" + i,
          macro: macroParent,
          macro_opt: macroOptParent,
        });
      }

      if (rightParent) {
        rightParent.sides.left.connections.push({
          element: "l" + i,
          macro: macroParent,
          macro_opt: macroOptParent,
        });
      }

      let topConnection = null;
      if (top) {
        for (const key in gP) {
          if (gP[key].includes(i)) {
            let topParent = structure.find((item) => item.key === key);
            topParent.sides.down.connections.push({
              element: "l" + i,
              macro: macroParent,
              macro_opt: macroOptParent,
            });

            topParent.additional_data.dodatnaObrada =
              topParent.additional_data.nalegajuca && dodatnaObrada;
            topConnection = key;
          }
        }
      }

      if (bottom) {
        let pr = structure.find((item) => item.key === "pr");
        pr.sides.up.connections.push({
          element: "l" + i,
          macro: macroParent,
          macro_opt: macroOptParent,
        });
      }

      area += w * d;
      volume += w * d * h;
      structure.push({
        key: "l" + i,
        args: {
          width: w,
          thickness: d,
          height: h,
        },
        area: w * d,
        volume: w * d * h,
        position: {
          x: pos[0],
          y: pos[1],
          z: pos[2],
        },
        rotation: { x: degToRad(90), y: 0, z: 0 },
        sides: {
          left: {
            connections: [
              {
                element: leftConnection,
                macro: macroBack,
                macro_opt: macroOptBack,
              },
            ],
          },
          right: {
            connections: [
              {
                element: rightConnection,
                macro: macroBack,
                macro_opt: macroOptBack,
              },
            ],
          },
          up: {
            connections: top
              ? [
                  {
                    element: topConnection,
                    macro: macroBack,
                    macro_opt: macroOptBack,
                  },
                ]
              : [],
          },
          down: {
            connections: bottom
              ? [
                  {
                    element: "pr",
                    macro: macroBack,
                    macro_opt: macroOptBack,
                  },
                ]
              : [],
          },
          front: {
            connections: [],
          },
          back: {
            connections: [],
          },
        },
        edge_tape: {
          left: false,
          right: false,
          up: false,
          down: false,
          front: false,
          back: false,
        },
        material:
          Object.keys(state.backMaterial).length === 0
            ? state.material
            : state.backMaterial,
      });
      elems.push(
        <Box
          key={"l" + i}
          args={[w, d, h]}
          position={[...pos]}
          rotation={[degToRad(90), 0, 0]}
          textures={m}
          normalMapImage={normalMap}
        />
      );

      pos[0] += w / 2 + materialThickness;
    }
  }

  let manualOrder = 0;
  let elem;
  const addOrder = (key) => {
    elem = structure.find((item) => item.key === key);
    if (elem !== undefined) {
      elem.manual = {};
      elem.manual.order = manualOrder++;
    }
  };

  addOrder("p");
  addOrder("pr");

  for (let i = 0; i <= cols; i++) {
    addOrder("u" + i);
  }

  for (let i = 0; i < cols; i++) {
    addOrder("l" + i);
  }

  for (let i = 0; i < cols; i++) {
    addOrder("g" + i);
  }

  for (let i = 0; i < cols; i++) {
    for (let j = 0; j < state.columns[i].compartments.length; j++)
      addOrder(`o${i}${j}`);
  }

  for (const interiorKey of interiors) {
    addOrder(interiorKey);
  }

  for (const doorKey of doors) {
    addOrder(doorKey);
  }

  return [elems, structure, area, volume, clickedCompartmentHeight];
}

/* Funkcija za generisanje indikatora */
export function generateIndicators(state = {}) {
  const depth = cmToM(state.depth);

  const materialThickness = cmToM(state.materialThickness);
  const innerThickness = cmToM(state.innerThickness);
  const plinthHeight = cmToM(state.plinth.enabled ? state.plinth.height : 0);

  const cols = state.columns.length;

  const indicators = [];

  let h,
    w,
    d,
    pos = [0, 0, 0]; // h -visina ploče, w - debljina ploče, d - dubina, pos - pozicija

  let min, max;

  let scalePosStart = [0, 0, 0];
  let measure = cmToM(1);

  // Uspravne ploče----------------------------

  if (state.plinth.enabled) {
    h = state.columns[0].height / 100 - materialThickness;
    w = materialThickness;
    d = depth;
    pos = [materialThickness / 2, h / 2, d];
  } else {
    h = state.columns[0].height / 100 - 2 * materialThickness;
    w = materialThickness;
    d = depth;
    pos = [materialThickness / 2, h / 2 + materialThickness, d];
  }

  scalePosStart = [measure / 2, state.height / 100 / 2, depth];

  min = materialThickness / 2;
  max =
    state.width / 100 -
    materialThickness / 2 -
    (cols * (config?.MIN.column.width + materialThickness)) / 100;
  for (let i = 1; i < cols; i++) {
    if (state.plinth.enabled) {
      h =
        Math.max(state.columns[i].height, state.columns[i - 1].height) / 100 -
        2 * materialThickness -
        plinthHeight;
      w = materialThickness;
      d = depth;
      pos = [
        pos[0] + state.columns[i - 1].width / 100 + materialThickness,
        h / 2 + materialThickness + plinthHeight,
        depth,
      ];
    } else {
      h =
        Math.max(state.columns[i].height, state.columns[i - 1].height) / 100 -
        2 * materialThickness;
      w = materialThickness;
      d = depth;
      pos = [
        pos[0] + state.columns[i - 1].width / 100 + materialThickness,
        h / 2 + materialThickness,
        depth,
      ];
    }
    min += config?.MIN.column.width / 100 + materialThickness;
    max += config?.MIN.column.width / 100 + materialThickness;
    indicators.push({
      position: [pos[0], plinthHeight + 0.1, pos[2]],
      type: 1,
      disabled: false,
      min: min,
      max: max,
      key: "u" + i,
      scale: {
        startPosition: [...scalePosStart],
        width: state.width / 100,
        height: 10,
        measure,
      },
    });
  }

  //Gornje ploče
  h = materialThickness;
  w = 0;
  d = depth;
  pos = [materialThickness / 2, 0, depth];
  for (let i = 0; i < cols; i++) {
    pos[0] += materialThickness / 2 + state.columns[i].width / 100 / 2;
    pos[1] = state.columns[i].height / 100 - materialThickness / 2;
    scalePosStart[0] = pos[0];
    scalePosStart[1] = state.plinth.enabled
      ? state.plinth.height / 100
      : 0 + materialThickness + measure / 2;
    scalePosStart[2] = pos[2];
    max = state.height / 100;
    min =
      plinthHeight +
      (state.columns[i].compartments.length - 1) * innerThickness +
      (state.columns[i].compartments.length * config?.MIN.compartment.height) /
        100 +
      materialThickness / 2;
    indicators.push({
      position: [...pos],
      type: 3,
      disabled: false,
      min: min,
      max: max,
      key: "o" + i + "0",
      scale: {
        startPosition: [...scalePosStart],
        width: 10,
        height: state.height / 100,
        measure,
      },
    });

    pos[0] += materialThickness / 2 + state.columns[i].width / 100 / 2;
  }

  // odeljci
  h = innerThickness;
  w = 0;
  d = depth;
  pos = [0, 0, 0];

  for (let i = 0; i < cols; i++) {
    w = state.columns[i].width / 100;
    pos[0] += materialThickness + w / 2;
    pos[1] = state.plinth.enabled
      ? plinthHeight + materialThickness
      : materialThickness;
    pos[2] = depth;

    scalePosStart[0] = pos[0];
    scalePosStart[1] = pos[1] + measure / 2;
    scalePosStart[2] = pos[2];

    min =
      plinthHeight + materialThickness + config?.MIN.compartment.height / 100;
    max =
      state.columns[i].height / 100 -
      materialThickness -
      (((state.columns[i].compartments.length - 1) *
        config?.MIN.compartment.height) /
        100 +
        innerThickness);
    for (let j = state.columns[i].compartments.length - 1; j > 0; j--) {
      pos[1] +=
        state.columns[i].compartments[j].height / 100 + innerThickness / 2;
      indicators.push({
        position: [...pos],
        type: 2,
        disabled: false,
        min: min,
        max: max,
        key: "o" + i + j,
        scale: {
          startPosition: [...scalePosStart],
          width: 10,
          height: state.columns[i].height / 100,
          measure,
        },
      });
      min += config?.MIN.compartment.height / 100 + innerThickness;
      max += config?.MIN.compartment.height / 100 + innerThickness;
      pos[1] += innerThickness / 2;
    }
    pos[0] += w / 2;
  }
  return indicators;
}

export function generateCompartmentInputFields(state = {}) {
  const inputFields = [];
  let materialThickness = cmToM(state.materialThickness);
  let innerThickness = cmToM(state.innerThickness);
  let depth = cmToM(state.depth);
  let plinthHeight = state.plinth.enabled ? cmToM(state.plinth.height) : 0;

  let pos = [0, 0, depth],
    i,
    j;
  let max,
    min = config?.MIN.compartment.height;
  pos[0] += materialThickness;
  for (i = 0; i < state.columns.length; i++) {
    pos[0] += state.columns[i].width / 100 / 2;
    pos[1] = materialThickness + plinthHeight;
    max =
      state.columns[i].height / 100 -
      plinthHeight -
      2 * materialThickness -
      (state.columns[i].compartments.length - 1) * (innerThickness + min / 100);
    max = Math.round(max * 100);
    for (j = state.columns[i].compartments.length - 1; j >= 0; j--) {
      pos[1] += state.columns[i].compartments[j].height / 100 / 2;
      inputFields.push({
        position: [...pos],
        key: "i" + i + j,
        value: state.columns[i].compartments[j].height,
        label: "cm",
        max: max,
        min: min,
      });
      pos[1] += state.columns[i].compartments[j].height / 100 / 2;
      pos[1] += innerThickness;
    }
    pos[0] += state.columns[i].width / 100 / 2;
    pos[0] += innerThickness;
  }

  return inputFields;
}

export function generateColumnForms(state = {}) {
  const forms = [];

  let materialThickness = cmToM(state.materialThickness);
  let innerThickness = cmToM(state.innerThickness);
  let depth = cmToM(state.depth);
  let plinthHeight = state.plinth.enabled ? cmToM(state.plinth.height) : 0;

  let pos = [0, 0, depth],
    i;
  let maxWidth,
    minWidth =
      state.columns.length === 1
        ? state.columns[0].width
        : config?.MIN.column.width;
  let maxHeight = state.height,
    minHeight = config?.MIN.column.height;
  let minCompartments = getMinCompartments(state),
    maxCompartments;
  let columns = state.columns;

  pos[1] += -0.05;

  maxWidth = Math.min(
    state.width -
      (columns.length + 1) * materialThickness * 100 -
      (columns.length - 1) * minWidth,
    config?.MAX.column.width
  );

  pos[0] += materialThickness;
  for (i = 0; i < columns.length; i++) {
    pos[0] += columns[i].width / 100 / 2;
    maxCompartments = getMaxColumnCompartments(state, columns[i]);
    minHeight =
      plinthHeight * 100 +
      2 * materialThickness * 100 +
      state.columns[i].compartments.length * config?.MIN.compartment.height +
      (state.columns[i].compartments.length - 1) * innerThickness * 100;

    if (i === columns.length - 1) {
      minWidth = Math.max(
        minWidth,
        columns[i].width - (config?.MAX.column.width - columns[0].width)
      );
      maxWidth = Math.min(
        maxWidth,
        columns[i].width + (columns[0].width - config?.MIN.column.width)
      );
    } else {
      minWidth = Math.max(
        minWidth,
        columns[i].width - (config?.MAX.column.width - columns[i + 1].width)
      );
      maxWidth = Math.min(
        maxWidth,
        columns[i].width + (columns[i + 1].width - config?.MIN.column.width)
      );
    }

    forms.push({
      position: [...pos],
      key: "c" + i,
      width: columns[i].width,
      height: columns[i].height,
      compartments: columns[i].compartments.length,
      minWidth: minWidth,
      maxWidth: maxWidth,
      minHeight: Math.ceil(minHeight),
      maxHeight: Math.floor(maxHeight),
      minCompartments: minCompartments,
      maxCompartments: maxCompartments,
    });
    pos[0] += columns[i].width / 100 / 2 + materialThickness;
  }

  return forms;
}

export async function generateSizing(state = {}, context, size = 0.6) {
  let H = context.canvas.height;
  let W = context.canvas.width;

  let center = {
    x: W / 2,
    y: H / 2,
  };

  /* context.strokeStyle = "red";
  context.strokeRect(center.x, 0, 0, H);

  context.strokeStyle = "blue";
  context.strokeRect(0, center.y, W, 0); */

  const w = state.width;
  const h = state.height;
  const d = state.depth;
  const cols = state.columns.length;
  const sizingArrowHeight = 50;
  const lableRectWidth = 18;

  let heights = [];
  for (let i = 0; i < cols; i++) {
    if (i === 0) heights.push(state.columns[i].height);
    else
      heights.push(
        Math.max(state.columns[i].height, state.columns[i - 1].height)
      );
  }
  heights.push(state.columns[cols - 1].height);

  heights = heights.filter(
    (value, index, self) => self.indexOf(value) === index
  );
  heights.sort((a, b) => a - b);

  let correction = 1;

  let tw = w + 2 * d + heights.length * sizingArrowHeight;
  let th = h + 4 * sizingArrowHeight;
  if (tw / W > th / H) {
    let w1 = size * W;
    correction = w1 / tw;
  } else {
    let h1 = size * H;
    correction = h1 / th;
  }

  const thickness = state.materialThickness * correction;
  const innerThickness = state.innerThickness * correction;
  const plinthHeight = state.plinth.height * correction;

  context.strokeStyle = "black";

  try {
    const img = await loadImage("./sizing/pattern.jpg");
    const pattern = context.createPattern(img, "repeat");
    context.fillStyle = pattern;
  } catch (e) {
    context.fillStyle = "gray";
  }

  let fill = context.fillStyle;
  context.fillStyle = "white";
  context.fillRect(0, 0, H, W);
  context.fillStyle = fill;

  context.lineWidth = 2;

  center.x =
    (heights.length + 1) * 2 * sizingArrowHeight + (w / 2) * correction;

  let leftSizing =
    center.x - (w / 2) * correction - (3 / 2) * sizingArrowHeight;

  let bottomSizing = center.y + (h / 2) * correction + sizingArrowHeight / 2;

  let ex = 0,
    ey = 0,
    ew = 0,
    eh = 0;

  /* Odeljci */
  ex = center.x - (w / 2) * correction + thickness;
  eh = innerThickness;

  let count = 1;
  for (let i = 0; i < cols; i++) {
    ey =
      center.y +
      (h / 2) * correction -
      state.columns[i].height * correction +
      thickness;
    ew = state.columns[i].width * correction;

    for (let j = 0; j < state.columns[i].compartments.length - 1; j++) {
      ey += state.columns[i].compartments[j].height * correction;

      CreateLabelRect(
        context,
        ex,
        ey - state.columns[i].compartments[j].height * correction,
        lableRectWidth,
        count++
      );

      context.strokeRect(ex, ey, ew, eh);
      context.fillRect(ex, ey, ew, eh);
      CreateInnerSizingArrow(
        context,
        ex + ew / 2,
        ey - state.columns[i].compartments[j].height * correction,
        state.columns[i].compartments[j].height * correction,
        "vertical",
        state.columns[i].compartments[j].height,
        ""
      );

      ey += innerThickness;
    }
    CreateLabelRect(context, ex, ey, lableRectWidth, count++);

    CreateInnerSizingArrow(
      context,
      ex + ew / 2,
      ey,
      state.columns[i].compartments[state.columns[i].compartments.length - 1]
        .height * correction,
      "vertical",
      state.columns[i].compartments[state.columns[i].compartments.length - 1]
        .height,
      ""
    );
    ex += thickness + ew;
  }

  /* Uspravna ploče (1,cols) */
  ew = thickness;
  ex = center.x - (w / 2) * correction;
  for (let i = 1; i < cols; i++) {
    CreateSizingArrow(
      context,
      ex + ew,
      bottomSizing,
      state.columns[i - 1].width * correction,
      sizingArrowHeight,
      "down",
      `${state.columns[i - 1].width}`,
      "cm"
    );
    ex += thickness + state.columns[i - 1].width * correction;
    if (state.plinth.enabled) {
      eh =
        Math.max(state.columns[i].height, state.columns[i - 1].height) *
          correction -
        2 * thickness -
        plinthHeight;
      ey = center.y + (h / 2) * correction - eh - thickness - plinthHeight;
    } else {
      eh =
        Math.max(state.columns[i].height, state.columns[i - 1].height) *
          correction -
        2 * thickness;
      ey = center.y + (h / 2) * correction - eh - thickness;
    }

    if (Math.max(state.columns[i].height, state.columns[i - 1].height) > 185) {
      eh += thickness;
      ey -= thickness;
    }

    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  }

  CreateSizingArrow(
    context,
    ex + ew,
    bottomSizing,
    state.columns[cols - 1].width * correction,
    sizingArrowHeight,
    "down",
    `${state.columns[cols - 1].width}`,
    "cm"
  );

  bottomSizing += (3 / 2) * sizingArrowHeight;

  /* Postolje */
  if (state.plinth.enabled) {
    eh = plinthHeight;
    ex = center.x - (w / 2) * correction + thickness;
    ey = center.y + (h / 2) * correction - eh;
    ew = w * correction - 2 * thickness;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);

    CreateSizingArrow(
      context,
      leftSizing,
      ey,
      eh,
      sizingArrowHeight,
      "left",
      `${state.plinth.height}`,
      ""
    );

    leftSizing -= (3 / 2) * sizingArrowHeight;

    ey = ey - thickness;
    eh = thickness;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  } else {
    eh = thickness;
    ex = center.x - (w / 2) * correction;
    ey = center.y + (h / 2) * correction - eh;
    ew = w * correction;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  }

  /* Strelice - uspravne ploče (1,cols) */

  for (let i = 0; i < heights.length; i++) {
    if (state.plinth.enabled) {
      eh = heights[i] * correction - plinthHeight;
      ey = center.y + (h / 2) * correction - eh - plinthHeight;
    } else {
      eh = heights[i] * correction;
      ey = center.y + (h / 2) * correction - eh;
    }

    CreateSizingArrow(
      context,
      leftSizing,
      ey,
      heights[i] * correction,
      sizingArrowHeight,
      "left",
      `${heights[i]}`,
      i === heights.length - 1 ? "cm" : ""
    );

    leftSizing -= (3 / 2) * sizingArrowHeight;
  }

  /* Uspravna ploča 0 */
  ew = thickness;
  ex = center.x - (w / 2) * correction;
  if (state.plinth.enabled) {
    eh = state.columns[0].height * correction - thickness;
    if (state.columns[0].height > 185) {
      eh += thickness;
    }
    ey = center.y + (h / 2) * correction - eh;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  } else {
    eh = state.columns[0].height * correction - 2 * thickness;
    if (state.columns[0].height > 185) {
      eh += thickness;
    }
    ey = center.y + (h / 2) * correction - eh - thickness;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  }

  /*uspravn ploča cols */
  ex = center.x + (w / 2) * correction - thickness;
  if (state.plinth.enabled) {
    eh = state.columns[cols - 1].height * correction - thickness;
    if (state.columns[cols - 1].height > 185) {
      eh += thickness;
    }

    ey = center.y + (h / 2) * correction - eh;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  } else {
    eh = state.columns[cols - 1].height * correction - 2 * thickness;
    if (state.columns[cols - 1].height > 185) {
      eh += thickness;
    }

    ey = center.y + (h / 2) * correction - eh - thickness;
    context.strokeRect(ex, ey, ew, eh);
    context.fillRect(ex, ey, ew, eh);
  }

  /* Gornje ploče */
  eh = thickness;
  ew = 0;
  ex = center.x - (w / 2) * correction;
  let pr = 0,
    ch = 0;

  for (let i = 0; i < cols; i++) {
    if (pr === 0) {
      ew = state.materialThickness;
    } else {
      ew = 0;
    }

    if (i === 0 && state.height > 185) {
      ew -= state.materialThickness;
    }

    ey = center.y + (h / 2) * correction - state.columns[i].height * correction;

    if (state.columns[i].height > 185) {
      pr = 1;
      ew = state.columns[i].width;
      ex += thickness;
      ch = 1;
    } else {
      do {
        if (ch === 1) {
          ex += thickness;
          ch = 0;
        }
        ew += state.columns[i].width;

        if (i < cols - 1) {
          if (state.columns[i].height < state.columns[i + 1].height) {
            pr = 0;
            break;
          }

          if (state.columns[i].height > state.columns[i + 1].height) {
            ew += state.materialThickness;
            pr = 1;
            break;
          }

          ew += state.materialThickness;
        } else {
          ew += state.materialThickness;
          break;
        }
      } while (
        state.columns[i].height === state.columns[++i].height &&
        i < cols
      );
    }

    context.strokeRect(
      state.columns[i].height > 185 ? ex + context.lineWidth / 2 : ex,
      ey,
      state.columns[i].height > 185
        ? ew * correction - context.lineWidth
        : ew * correction,
      eh
    );
    context.fillRect(
      state.columns[i].height > 185 ? ex + context.lineWidth / 2 : ex,
      ey,
      state.columns[i].height > 185
        ? ew * correction - context.lineWidth
        : ew * correction,
      eh
    );
    if (Math.round(ew) !== w) {
      CreateSizingArrow(
        context,
        ex,
        bottomSizing,
        ew * correction,
        sizingArrowHeight,
        "down",
        `${ew}`,
        ""
      );
    }
    ex += ew * correction;
  }

  if (Math.round(ew) !== w) {
    bottomSizing += (3 / 2) * sizingArrowHeight;
  }

  CreateSizingArrow(
    context,
    center.x - (w / 2) * correction,
    bottomSizing,
    w * correction,
    sizingArrowHeight,
    "down",
    `${state.width}`,
    "cm"
  );
  bottomSizing += (3 / 2) * sizingArrowHeight;

  /* Bočni prikaz */
  center.x +=
    (w / 2) * correction + 4 * sizingArrowHeight + (d / 2) * correction;

  leftSizing = center.x - (d / 2) * correction - (3 / 2) * sizingArrowHeight;
  bottomSizing = center.y + (h / 2) * correction + sizingArrowHeight / 2;

  ex = center.x - (d / 2) * correction;
  ey = center.y - (h / 2) * correction;
  ew = d * correction;
  eh = h * correction;
  context.strokeRect(ex, ey, ew, eh);
  context.fillRect(ex, ey, ew, eh);
  eh = thickness;
  context.strokeRect(ex, ey, ew, eh);

  if (!state.plinth.enabled) {
    ey += h * correction - eh;
    context.strokeRect(ex, ey, ew, eh);
    ey -= h * correction - eh;
  }

  CreateSizingArrow(
    context,
    center.x - (d / 2) * correction,
    bottomSizing,
    ew,
    sizingArrowHeight,
    "down",
    `${state.depth}`,
    "cm"
  );

  CreateSizingArrow(
    context,
    leftSizing,
    ey,
    h * correction,
    sizingArrowHeight,
    "left",
    `${state.height}`,
    "cm"
  );
}

/* FUNKCIJE ZA IZMENU STATE-A FORME */

export function changeFormState(oldFormState = {}, state = {}) {
  if (state) {
    let newFormState = {
      ...oldFormState,
      height: state.height,
      width: state.width,
      depth: state.depth,
      plinth: {
        height: state.plinth.height,
        enabled: state.plinth.enabled,
      },
      compartments: {
        number: state.columns[0].compartments.length,
        max: getMaxCompartments(state),
        min: getMinCompartments(state),
      },
      columns: {
        number: state.columns.length,
        max: getMaxColumns(state),
        min: getMinColumns(state),
      },
      innerThickness: state.innerThickness,
      materialThickness: state.materialThickness,
      material: state.material,
      closedBack: { ...state.closedBack },
      wallMount: { ...state.wallMount },
      backMaterial: { ...state.backMaterial },
      skirtingBoard: { ...cloneDeep(state.skirtingBoard) },
    };

    return newFormState;
  } else {
    return null;
  }
}
