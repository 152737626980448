import handles from "../../../data/handles";
import InputSelectImage from "../InputSelectImage/InputSelectImage";

const HandleSelect = ({
  selected,
  onPassValue = () => {},
  doorType = "none",
}) => {
  return (
    <div>
      {Object.entries(handles).map(
        ([key, handle]) =>
          checkType(handle, doorType) && (
            <InputSelectImage
              title={handle.name}
              value={key}
              onPassValue={(val) => {
                onPassValue(val);
              }}
              key={key}
              defaultChecked={key === selected}
            />
          )
      )}
    </div>
  );
};

const checkType = (handle, doorType) => {
  return (
    handle.positions === "all" ||
    handle.positions.find((item) => item.doorType === doorType) !== undefined
  );
};

export default HandleSelect;
