import React, { useEffect, useRef, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";

const STLModel = ({
  stlPath,
  position,
  rotation,
  scale = 1,
  dynamicScale = false,
  width = 1,
  height = 1,
  depth = 1,
}) => {
  const geom = useLoader(STLLoader, stlPath);

  if (typeof scale === "number") {
    scale = [scale, scale, scale];
  }
  const [meshScale, setMeshScale] = useState(scale);

  const meshRef = useRef();
  useEffect(() => {
    const boundingBox = new THREE.Box3().setFromObject(meshRef.current);
    const size = boundingBox.getSize(new THREE.Vector3());

    if (dynamicScale === true) {
      if (size.x > 0 && width > 0) {
        let newScale = width / (size.x / meshScale[0]);
        setMeshScale([newScale, newScale, newScale]);
      }
    } else {
      let newScale = [...meshScale];
      switch (dynamicScale) {
        case "x":
          if (size.x > 0 && width > 0) {
            newScale[0] = width / (size.x / meshScale[0]);

            setMeshScale(newScale);
          }
          break;
        case "y":
          if (size.y > 0 && height > 0) {
            newScale[1] = height / (size.y / meshScale[1]);
            setMeshScale(newScale);
          }
          break;
        case "z":
          if (size.z > 0 && depth > 0) {
            newScale[2] = depth / (size.z / meshScale[2]);
            setMeshScale(newScale);
          }
          break;
        default:
          break;
      }
    }
  }, [geom, width, depth, height, dynamicScale]);

  return (
    <mesh
      geometry={geom}
      scale={meshScale}
      ref={meshRef}
      rotation={rotation}
      position={[position[0], position[1], position[2]]}
    >
      <meshPhongMaterial color='gray' />
    </mesh>
  );
};

export default STLModel;
