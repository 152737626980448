const ruckica1 = {
  name: "Rucka 1",
  fileType: "stl",
  model: "./models/ruckica/ruckica_test.stl",
  dimensions: {
    width: "100mm",
    height: "6mm",
    depth: "4mm",
  },
  rotation: {
    x: 0,
    y: -90,
    z: 90,
  },
  price: {},
  positions: [
    {
      doorType: "left_door",
      max_heights: {
        centered: { side: "front", right: "5cm" },
        1250: { side: "front", top: "10cm", right: "5cm" },
        1600: { side: "front", top: "50%", right: "5cm" },
        default: { side: "front", bottom: "10cm", right: "5cm" },
      },
    },
    {
      doorType: "right_door",
      max_heights: {
        centered: { side: "front", left: "5cm" },
        1250: { side: "front", top: "10cm", left: "5cm" },
        1600: { side: "front", top: "50%", left: "5cm" },
        default: { side: "front", bottom: "10cm", left: "5cm" },
      },
    },
    {
      doorType: "drawer",
      rotation: {
        x: -90,
        y: 0,
        z: 0,
      },
      max_heights: {
        default: { side: "front", top: "50%", left: "50%" },
      },
    },
    {
      doorType: "flap_door_up",
      rotation: {
        x: -90,
        y: 0,
        z: 0,
      },
      max_heights: {
        default: { side: "front", bottom: "5cm", left: "50%" },
      },
    },
    {
      doorType: "flap_door_down",
      rotation: {
        x: -90,
        y: 0,
        z: 0,
      },
      max_heights: {
        default: { side: "front", top: "5cm", left: "50%" },
      },
    },
    {
      doorType: "double_door",
    },
  ],
};

export default ruckica1;
