import styles from "./RightMenu.module.scss";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          navigate("/checkout");
        }}
        className={styles.checkout}
      >
        Checkout
      </div>
    </>
  );
};

export default Checkout;
