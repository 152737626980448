import classes from "./LeftMenu.module.scss";
import MenuOption from "./MenuOption";

/**
 *
 * @param {{key:string,title:string,image:string,handler:function}[]} menuOptions options displayed in menu
 * @param {string} selectedOption key of currently selected option in menu
 * @returns {JSX.Element}
 */
const LeftMenu = ({ menuOptions, selectedOption }) => {
  return (
    <div className={classes.menuContainer}>
      {(menuOptions ?? []).map((item) => (
        <MenuOption
          key={item.key}
          itemKey={item.key}
          selected={item.key === selectedOption}
          title={item.title}
          image={item.image}
          handler={item.handler}
        />
      ))}
    </div>
  );
};

export default LeftMenu;
