import React, { useState } from "react";
import parse from "html-react-parser";
//mui Icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
//Styles
import styles from "./FormSection.module.scss";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const FormSection = (props) => {
  const isCollapsed = props.isCollapsed;
  const collapseClass = isCollapsed ? "collapsed" : "";
  const info = parse(props.info);
  const description = parse(props.description);

  const [infoClosed, setInfoClosed] = useState(true);

  function infoShowHandler() {
    setInfoClosed(false);
  }

  function infoHideHandler() {
    setInfoClosed(true);
  }

  function openHandler() {
    props.onOpen(props.number);
  }

  function openNextHandler() {
    props.onOpen(props.number + 1);
  }

  return (
    <div className={`${styles["form-section"]} ${styles[collapseClass]} `}>
      <div className={styles["form-section-header"]}>
        <div className={styles["back-button"]}>
          {!props.isFirst && <KeyboardArrowLeft onClick={props.backButton} />}
        </div>
        <div className={styles["form-section-title"]}>
          <span className={styles["title"]}>
            {props.number + 1}. {props.title}
          </span>
          <div className={styles["info-holder"]}>
            <InfoOutlinedIcon
              onMouseOver={infoShowHandler}
              onMouseOut={infoHideHandler}
            />
            <div
              className={`${styles["info"]} ${
                infoClosed ? styles["info-hidden"] : ""
              } `}
            >
              <span>{info}</span>
            </div>
          </div>
        </div>

        <div className={styles.collapseButton}>
          {isCollapsed ? (
            <KeyboardArrowDownIcon onClick={openHandler} />
          ) : (
            <KeyboardArrowUpIcon />
          )}
        </div>
        <div className={styles["back-button"]}>
          {!props.isLast && <KeyboardArrowRight onClick={openNextHandler} />}
        </div>
      </div>
      <div className={styles["form-section-content"]}>
        <span className={styles["form-section-description"]}>
          {description}
        </span>
        {props.children}
        {!props.isLast && (
          <button className={styles["next-button"]} onClick={openNextHandler}>
            ✓ NEXT
          </button>
        )}
      </div>
    </div>
  );
};

export default FormSection;
