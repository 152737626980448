/* Conversions from strings to mesurment units */

export const convertToMeters = (input) => {
  input = String(input);
  const regex = /^([\d.]+)(cm|mm|m)?$/;

  const match = input.match(regex);

  if (match) {
    const numericValue = parseFloat(match[1]);
    const unit = match[2];

    switch (unit) {
      case "cm":
        return numericValue / 100;
      case "mm":
        return numericValue / 1000;
      case "m":
      default:
        return numericValue;
    }
  } else {
    return null;
  }
};

export const convertPercent = (input, value) => {
  const regex = /^([\d.]+)%$/;

  const match = input.match(regex);

  if (match) {
    return (value * match[1]) / 100;
  } else {
    return null;
  }
};

export const calculateExpression = (inputExpression, value) => {
  let components = inputExpression.split(/\s+/);

  let vars = [],
    signs = [];

  for (const component of components) {
    if (component === "+" || component === "-") {
      signs.push(component);
    } else {
      vars.push(component);
    }
  }

  let currentValue = 0;

  if (vars[0].includes("%")) {
    currentValue = convertPercent(vars[0], value);
  } else {
    currentValue = convertToMeters(vars[0]);
  }

  for (let i = 0; i < signs.length; i++) {
    let nextValue = 0;
    if (vars[i + 1].includes("%")) {
      nextValue = convertPercent(vars[i + 1], value);
    } else {
      nextValue = convertToMeters(vars[i + 1]);
    }
    if (signs[i] === "+") {
      currentValue += nextValue;
    } else {
      currentValue -= nextValue;
    }
  }

  return currentValue;
};
