import { useState, useEffect } from "react";
import interiors from "../../../data/interiors";
import STLModel from "../Object3D/STLModel";
import { Box } from "../Box/Box";
import { SpotLight } from "@react-three/drei";
import LightElement from "./LightElement";

const InteriorElement = ({
  element,
  position = [0, 0, 0],
  height = 0,
  width = 0,
  depth = 0,
  material = [],
  materialThickness = 0,
  innerThickness = 0,
}) => {
  const [model, setModel] = useState(null);

  useEffect(() => {
    if (interiors[element] != null) {
      setModel(interiors[element]);
    } else {
      setModel(null);
    }
  }, [element]);

  switch (element) {
    case "rail":
    case "lift":
      if (model) {
        return (
          <STLModel
            key={model.model}
            stlPath={model.model}
            position={[
              position[0],
              position[1] + height / 2 - 0.05,
              position[2],
            ]}
            rotation={model?.rotation}
            width={width}
            dynamicScale={"x"}
            scale={model?.scale}
          />
        );
      } else {
        return null;
      }
    case "trousersHolder":
      if (model) {
        return (
          <STLModel
            key={model.model}
            stlPath={model.model}
            position={[
              position[0] - width / 2,
              position[1] - height / 2 + 0.45,
              position[2],
            ]}
            rotation={model?.rotation}
            width={width}
            scale={model?.scale}
          />
        );
      } else {
        return null;
      }
    case "beltHolder":
      if (model) {
        return (
          <STLModel
            key={model.model}
            stlPath={model.model}
            position={[
              position[0] + width / 2,
              position[1] - height / 2 + 0.45,
              position[2],
            ]}
            rotation={model?.rotation}
            width={width}
            scale={model?.scale}
          />
        );
      } else {
        return null;
      }
    case "split":
      return (
        <Box
          position={position}
          args={[innerThickness, height, depth]}
          textures={material}
        />
      );
    case "ledLight":
      return (
        <LightElement
          position={position}
          height={height}
          depth={depth}
          width={width}
        />
      );
    default:
      return 0;
  }
};

export default InteriorElement;
