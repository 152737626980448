import styles from "./Checkout.module.scss";
import { useContext, useState } from "react";
import { CartContext } from "../../utils/CartContext";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../CheckoutElements/CheckoutForm";
import CheckoutItems from "../../CheckoutElements/CheckoutItems";
import { post } from "../../../api/api";
import DefaultCheckoutData from "../../CheckoutElements/DefaultData";

const Checkout = () => {
  const navigate = useNavigate();

  const { cartItems } = useContext(CartContext);

  const [formData, setFormData] = useState(DefaultCheckoutData);

  const inputChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let response = await post("api.php?action=set_checkout", formData);
      console.log(response);
      navigate(`/checkout/success/${response?.payload?.order_token}`);
    } catch (e) {
      console.warn(e);
    }
  };
  return (
    <div>
      <div className={styles.page}>
        <CheckoutForm
          inputChangeHandler={inputChangeHandler}
          formData={formData}
        />
        <CheckoutItems items={cartItems} />
      </div>
      <div>
        <button>Potvrdi porudžbenicu</button>
      </div>
    </div>
  );
};

export default Checkout;
