import { useEffect, useState } from "react";
import doors from "../../../data/doorsAndDrawers.json";
import InputSelectImage from "../InputSelectImage/InputSelectImage";
import styles from "./DoorsSelect.module.scss";
import InputSwitch from "../InputSwitch/InputSwitch";
import { cloneDeep } from "lodash";
import MaterialSelect from "../MaterialSelect/MaterialSelect";
import HandleSelect from "../HandleSelect/HandleSelect";
import LimitationsDisplay from "./LimitationsDisplay";

const checkLimitations = (limitations, dimensions) => {
  let ret = {
    width: true,
    height: true,
    depth: true,
    multipleCompartments: true,
  };
  if (Object.keys(limitations).length === 0 || dimensions === null) {
    return ret;
  }
  if (
    limitations?.width?.min > dimensions?.width ||
    limitations?.width?.max < dimensions?.width
  ) {
    ret.width = false;
  }
  if (
    limitations?.height?.min > dimensions?.height ||
    limitations?.height?.max < dimensions?.height
  ) {
    ret.height = false;
  }

  if (
    limitations?.depth?.min > dimensions?.depth ||
    limitations?.depth?.max < dimensions?.depth
  ) {
    ret.depth = false;
  }
  if (dimensions.multipleCompartments && !limitations?.multipleCompartments) {
    ret.multipleCompartments = false;
  }

  return ret;
};

const DoorsSelect = ({
  selectedDoorDimension,
  onPassValue,
  globalOptions = {},
  setGlobalOptions = () => {},
}) => {
  const [selectedDoor, setSelectedDoor] = useState("none");
  let defaultDoorOptions = { inlaid: true, handle: null };

  const [doorOptions, setDoorOptions] = useState(
    globalOptions.enabled ? cloneDeep(globalOptions) : defaultDoorOptions
  );

  const setInlaid = (value) => {
    setDoorOptions((prevOptions) => {
      return { ...prevOptions, inlaid: value };
    });
  };

  const changeGlobalOptions = ({ enabled }) => {
    if (enabled) {
      setGlobalOptions({ ...doorOptions, enabled });
    } else {
      setGlobalOptions((prevOptions) => {
        return { ...prevOptions, enabled };
      });
    }
  };

  const changeMaterial = (value) => {
    setDoorOptions((oldOptions) => {
      return { ...oldOptions, material: value };
    });
  };

  const changeHandle = (value) => {
    setDoorOptions((oldOptions) => {
      return { ...oldOptions, handle: value };
    });
  };

  useEffect(() => {
    onPassValue(selectedDoor, doorOptions);
  }, [selectedDoor, doorOptions]);

  useEffect(() => {
    if (selectedDoorDimension !== null) {
      setSelectedDoor(selectedDoorDimension.doorType);
    } else {
      setSelectedDoor("none");
    }
    if (!globalOptions.enabled) {
      setDoorOptions(defaultDoorOptions);
    } else {
      setDoorOptions(globalOptions);
    }
  }, [selectedDoorDimension?.selected]);

  return selectedDoorDimension != null ? (
    <div>
      <div>
        {doors.map((item) => {
          let limits = checkLimitations(
            item.limitations,
            selectedDoorDimension
          );
          let disabled = !Object.values(limits).every((item) => item === true);

          return (
            <span key={item.value} className={styles.item}>
              <InputSelectImage
                image={item.image_in}
                title={item.name}
                value={item.value}
                onPassValue={(val) => setSelectedDoor(val)}
                disabled={disabled}
                defaultChecked={item.value === selectedDoor}
              />
              {disabled && (
                <span className={styles.info}>
                  <h4>This front is suitable for compartments of:</h4>
                  <LimitationsDisplay
                    item={item}
                    limits={limits}
                    selectedDoorDimension={selectedDoorDimension}
                  />
                </span>
              )}
            </span>
          );
        })}
      </div>
      {selectedDoor !== "none" && (
        <div>
          <hr />
          <h4>Fronts</h4>
          <div>
            <InputSelectImage
              image='/doors/in/left.svg'
              title='Inlaid fronts'
              value={true}
              onPassValue={(val) => setInlaid(val)}
              defaultChecked={doorOptions.inlaid}
            />
            <InputSelectImage
              image='/doors/out/left.svg'
              title='Surface mounted fronts'
              value={false}
              onPassValue={(val) => setInlaid(val)}
              defaultChecked={!doorOptions.inlaid}
            />
          </div>
          <hr />
          <h4>Material</h4>
          <div>
            <MaterialSelect onPassValue={changeMaterial} />
          </div>
          <hr />
          <h4>Handles</h4>
          <div>
            <HandleSelect
              selected={doorOptions.handle}
              onPassValue={changeHandle}
              doorType={selectedDoor}
            />
          </div>
          <div>
            <InputSwitch
              selected={globalOptions.enabled}
              label='Apply settings for all fronts'
              onPassValue={changeGlobalOptions}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.placeholder}>
      <img src='/icons/door.svg' alt='' width='80%' />
      <p>Select door position on the model.</p>
    </div>
  );
};

export default DoorsSelect;
