import { useEffect, useState } from "react";

import parse from "html-react-parser";
import styles from "./InputSwitch.module.scss";
import ConfigurationInput from "../ConfigurationInput/ConfigurationInput";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InputSwitch = ({
  selected = false,
  label = "",
  options = [],
  disabled = false,
  info = "",
  onPassValue = () => null,
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  let defaultOption = null;
  if (options.length > 0) {
    defaultOption = options.find((item) => item.props.selected)?.props?.value;
  }
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const [infoClosed, setInfoClosed] = useState(true);

  function infoShowHandler() {
    setInfoClosed(false);
  }

  function infoHideHandler() {
    setInfoClosed(true);
  }

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (options.length > 0 && selectedOption === null) {
      let newOption = options.find((item) => item.props.selected);
      setSelectedOption(newOption?.props?.value ?? null);
    }
    onPassValue({ enabled: isSelected, option: selectedOption });
  }, [isSelected, selectedOption]);

  const onOptionChange = (value) => {
    setSelectedOption(value);
  };
  return (
    <div>
      <div
        className={`${styles.inputContainer} ${disabled && styles.disabled}`}
      >
        <div
          className={`${styles.swicthBody} ${
            isSelected ? styles.swicthSelected : ""
          }`}
          onClick={() => {
            if (!disabled) setIsSelected(!isSelected);
          }}
        >
          <div className={styles.switchHead}></div>
        </div>
        {label}
        <div className={styles["info-holder"]}>
          <InfoOutlinedIcon
            onMouseOver={infoShowHandler}
            onMouseOut={infoHideHandler}
          />
          <div
            className={`${styles["info"]} ${
              infoClosed ? styles["info-hidden"] : ""
            } `}
          >
            <span>{parse(info)}</span>
          </div>
        </div>
      </div>
      {isSelected &&
        options?.length > 0 &&
        options.map((option) => (
          <ConfigurationInput
            key={option["value-type"] + option.props.value}
            type={option.type}
            name={option["value-type"]}
            {...option.props}
            defaultChecked={option.props.value === selectedOption}
            onPassValue={onOptionChange}
          />
        ))}
    </div>
  );
};

export default InputSwitch;
