import { Edges } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import React, { useMemo } from "react";
import { TextureLoader } from "three";
import { BoxGeometry } from "three";

import * as THREE from "three";

const textureW = 0.1,
  textureH = 0.1;

export const Box = ({
  color = "white",
  args = [],
  textures = [],
  highlighted = false,
  castShadow = true,
  receiveShadow = true,
  opacity = 1,
  normalMapImage = null,
  ...props
}) => {
  //Load textures and normal map
  const loadedTextures = useLoader(TextureLoader, textures);
  const loadedNormalMap = useLoader(
    TextureLoader,
    normalMapImage ? normalMapImage : []
  );

  // Create a BoxGeometry with the provided args
  const geometry = useMemo(() => new BoxGeometry(...args), [args]);

  // Handle texture loading errors by providing fallback textures
  const materials = useMemo(() => {
    return loadedTextures.map((texture, index) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;

      let repeatX = 1,
        repeatY = 1;
      if (index === 0) {
        repeatX = args[0] / textureW;
        repeatY = args[1] / textureH;
      } else if (index === 1) {
        repeatX = args[0] / textureW;
        repeatY = args[1] / textureH;
      } else if (index === 2 || index === 3) {
        repeatX = args[0] / textureW;
        repeatY = args[2] / textureH;
      } else {
        repeatX = args[1] / textureW;
        repeatY = args[2] / textureH;
      }

      texture.repeat.set(repeatX, repeatY);
      texture.repeat.set(repeatX, repeatY);

      if (normalMapImage) {
        loadedNormalMap.wrapS = THREE.RepeatWrapping;
        loadedNormalMap.wrapT = THREE.RepeatWrapping;

        loadedNormalMap.repeat.set(repeatX, repeatY);
        loadedNormalMap.repeat.set(repeatX, repeatY);
      }
      return (
        <meshStandardMaterial
          color={color}
          attach={`material-${index}`}
          map={texture}
          key={index}
          transparent={opacity < 1}
          opacity={opacity}
          normalMap={normalMapImage ? loadedNormalMap : null}
        />
      );
    });
  }, [color, loadedTextures, opacity]);

  return (
    <mesh
      castShadow={castShadow}
      receiveShadow={receiveShadow}
      {...props}
      geometry={geometry}
    >
      {Array.isArray(textures) && textures.length > 0 ? (
        materials
      ) : (
        <meshStandardMaterial
          color={color}
          transparent={opacity < 1}
          opacity={opacity}
        />
      )}
      {highlighted && (
        <Edges args={args}>
          <lineBasicMaterial color={"green"} linewidth={3} />
        </Edges>
      )}
    </mesh>
  );
};
