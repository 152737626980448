import LesonitMaterials from "../LesonitMaterial/LesonitMaterial";
import MaterialSelect from "../MaterialSelect/MaterialSelect";

const BackMaterialSelect = ({
  displayed,
  selectedOption,
  onPassValue,
  selectedBackMaterial,
  selectedOptions,
  materialId,
}) => {
  let el = null;
  let sel = selectedBackMaterial.id;
  switch (selectedOption) {
    case "wood":
      if (selectedBackMaterial.option !== "wood") {
        sel = null;
      }
      el = (
        <MaterialSelect
          onPassValue={onPassValue}
          selectedOptions={selectedOptions}
          defaultSelectedMaterial={materialId}
        />
      );
      break;
    case "lesonit":
      if (selectedBackMaterial.option !== "lesonit") {
        sel = null;
      }
      el = <LesonitMaterials onPassValue={onPassValue} selected={sel} />;
      break;
    default:
      break;
  }

  return displayed ? el : null;
};

export default BackMaterialSelect;
