import { useEffect, useState } from "react";

import interiorOptions from "../../../data/interiorOptions.json";
import InputSelectImage from "../InputSelectImage/InputSelectImage";
import LimitationsDisplay from "./LimitationsDisplay";

import styles from "./InteriorOptionsSelect.module.scss";
import { cloneDeep, isEqual } from "lodash";

const InteriorOptionSelect = ({ selectedDoorDimension, onPassValue }) => {
  const [selectedInteriors, setSelectedInteriors] = useState(["empty"]);
  const [availableOptions, setAvailableOptions] = useState("all");
  const [loaded, setLoaded] = useState(false);

  const generateAvailableOptions = (interiors) => {
    for (const value of interiors) {
      let item = interiorOptions.find((el) => el.value === value);
      if (value === "empty") {
        setAvailableOptions("all");
        return;
      }
      if (item.type === "single") {
        setAvailableOptions(item.available);
        return;
      }
    }
    setAvailableOptions("all");
  };

  const selectInteriorOption = (item) => {
    const { value, type } = item;

    if (value === "empty") {
      setAvailableOptions("all");
      setSelectedInteriors([value]);
      return;
    }

    if (selectedInteriors.includes(value)) {
      setSelectedInteriors((old) => {
        let newInteriors = old.filter((item) => item !== value);
        if (newInteriors.length === 0) {
          newInteriors = ["empty"];
          setAvailableOptions("all");
        }
        return newInteriors;
      });
    } else {
      if (type === "single") {
        setSelectedInteriors((old) => {
          let newInteriors = old.filter(
            (oldItem) =>
              interiorOptions.find(
                (item) => item.type !== "single" && item.value === oldItem
              ) && oldItem !== "empty"
          );

          return [...newInteriors, value];
        });
        setAvailableOptions(item.available);

        return;
      }

      setSelectedInteriors((old) => {
        let newInteriors = [...old.filter((item) => item !== "empty"), value];
        return newInteriors;
      });
    }
  };

  useEffect(() => {
    if (loaded) {
      let a1 = (cloneDeep(selectedDoorDimension.interior) ?? []).sort();
      let a2 = selectedInteriors.filter((elem) => elem !== "empty").sort();
      if (!isEqual(a1, a2)) {
        onPassValue(selectedInteriors.filter((elem) => elem !== "empty"));
      }
    } else {
      setLoaded(true);
    }
  }, [selectedInteriors]);

  useEffect(() => {
    let a1 = (cloneDeep(selectedDoorDimension.interior) ?? []).sort();
    let a2 = selectedInteriors.filter((elem) => elem !== "empty").sort();
    if (!isEqual(a1, a2)) {
      let newInteriors = (a1 ?? []).length > 0 ? a1 : ["empty"];
      generateAvailableOptions(newInteriors);
      setSelectedInteriors(newInteriors);
    }
  }, [selectedDoorDimension.interior]);

  return (
    <div>
      <div>
        {interiorOptions
          .filter((item) => item.type === "single" || item.type === "none")
          .map((item) => {
            let limits = checkLimitations(
              item.limitations,
              selectedDoorDimension
            );
            let disabled = !Object.values(limits).every(
              (item) => item === true
            );
            let multiples = interiorOptions
              .filter((el) => el.type === "multiple")
              .map((item) => item.value);

            let multiplesAvailable = interiorOptions
              .filter(
                (el) =>
                  el.type === "multiple" && item.available.includes(el.value)
              )
              .map((item) => item.value);

            disabled =
              disabled ||
              !selectedInteriors
                .filter((item) => multiples.includes(item))
                .every((sel) => multiplesAvailable.includes(sel));

            return (
              <span
                key={`${item.value}-${selectedDoorDimension}`}
                className={styles.item}
              >
                <InputSelectImage
                  image={item.image}
                  onPassValue={() => selectInteriorOption(item)}
                  value={item.value}
                  disabled={disabled}
                  title={item.name}
                  defaultChecked={selectedInteriors.includes(item.value)}
                />
                {disabled && (
                  <span className={styles.info}>
                    <h4>This interior is suitable for compartments of:</h4>
                    <LimitationsDisplay
                      item={item}
                      limits={limits}
                      selectedDoorDimension={selectedDoorDimension}
                    />
                  </span>
                )}
              </span>
            );
          })}
      </div>
      <div>
        {interiorOptions
          .filter((item) => item.type === "multiple")
          .map((item) => {
            let limits = checkLimitations(
              item.limitations,
              selectedDoorDimension
            );
            let disabled =
              !Object.values(limits).every((item) => item === true) ||
              !(
                availableOptions === "all" ||
                availableOptions.includes(item.value)
              );
            return (
              <span key={item.value} className={styles.item}>
                <InputSelectImage
                  image={item.image}
                  onPassValue={() => selectInteriorOption(item)}
                  value={item.value}
                  disabled={disabled}
                  title={item.name}
                  defaultChecked={selectedInteriors.includes(item.value)}
                />
                {disabled && (
                  <span className={styles.info}>
                    <h4>This interior is suitable for compartments of:</h4>
                    <LimitationsDisplay
                      item={item}
                      limits={limits}
                      selectedDoorDimension={selectedDoorDimension}
                    />
                  </span>
                )}
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default InteriorOptionSelect;

const checkLimitations = (limitations, dimensions) => {
  let ret = {
    width: true,
    height: true,
    depth: true,
  };
  if (Object.keys(limitations).length === 0 || dimensions === null) {
    return ret;
  }
  if (
    limitations?.width?.min > dimensions?.width ||
    limitations?.width?.max < dimensions?.width
  ) {
    ret.width = false;
  }
  if (
    limitations?.height?.min > dimensions?.height ||
    limitations?.height?.max < dimensions?.height
  ) {
    ret.height = false;
  }

  if (
    limitations?.depth?.min > dimensions?.depth ||
    limitations?.depth?.max < dimensions?.depth
  ) {
    ret.depth = false;
  }

  return ret;
};
