import { useState } from "react";
import styles from "./CheckoutElements.module.scss";

const CheckoutForm = ({ inputChangeHandler, formData }) => {
  return (
    <div className={styles.form}>
      <h1 className={styles.title}>Informacije:</h1>
      <div className={styles.customerType}>
        <div className={styles.customerTypeField}>
          <input
            type='radio'
            checked={formData.customer_type_billing === "personal"}
            onChange={() => {
              inputChangeHandler({
                target: { name: "customer_type_billing", value: "personal" },
              });
            }}
            id='customer'
          />
          <label htmlFor='customer'>Fizičko lice</label>
        </div>
        <div className={styles.customerTypeField}>
          <input
            type='radio'
            checked={formData.customer_type_billing === "company"}
            onChange={() => {
              inputChangeHandler({
                target: { name: "customer_type_billing", value: "company" },
              });
            }}
            id='bss'
          />
          <label htmlFor='bss'>Pravno lice</label>
        </div>
      </div>
      <div className={styles.customerInfo}>
        {formData.customer_type_billing === "personal" ? (
          <div className={styles.customerInfoData}>
            <div className={styles.infoInputs}>
              <input
                className={styles.inputField}
                name='first_name_billing'
                onChange={inputChangeHandler}
                placeholder={`Ime`}
              />
              <input
                className={styles.inputField}
                name='last_name_billing'
                onChange={inputChangeHandler}
                placeholder={`Prezime`}
              />
              <input
                className={styles.inputField}
                name='email_billing'
                onChange={inputChangeHandler}
                placeholder={`Email`}
              />
            </div>
            <div className={styles.infoInputs}>
              <input
                className={styles.inputField}
                name='phone_billing'
                onChange={inputChangeHandler}
                placeholder={`Telefon`}
              />
              <div className={styles.addressData}>
                <div className={styles.infoInputs}>
                  <input
                    className={styles.inputField}
                    name='address_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Adresa dostave`}
                  />
                  <input
                    className={styles.inputField}
                    name='zip_code_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Poštanski broj`}
                  />
                </div>
                <div className={styles.infoInputs}>
                  <input
                    className={styles.inputField}
                    name='object_number_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Broj`}
                  />
                  <input
                    className={styles.inputField}
                    name='town_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Grad`}
                  />
                </div>
              </div>
              <textarea
                className={styles.inputField}
                name='note_billing'
                onChange={inputChangeHandler}
                placeholder={`Napomena`}
              />
            </div>
          </div>
        ) : (
          <div className={styles.customerInfoData}>
            <div className={styles.infoInputs}>
              <input
                className={styles.inputField}
                name='comnpany_name_billing'
                onChange={inputChangeHandler}
                placeholder={`Naziv firme`}
              />
              <input
                className={styles.inputField}
                name='pib_billing'
                onChange={inputChangeHandler}
                placeholder={`PIB`}
              />
              <input
                className={styles.inputField}
                name='maticni_broj_billing'
                onChange={inputChangeHandler}
                placeholder={`Matični broj`}
              />
              <input
                className={styles.inputField}
                name='first_name_billing'
                onChange={inputChangeHandler}
                placeholder={`Ime`}
              />
              <input
                className={styles.inputField}
                name='last_name_billing'
                onChange={inputChangeHandler}
                placeholder={`Prezime`}
              />
            </div>
            <div className={styles.infoInputs}>
              <input
                className={styles.inputField}
                name='email_billing'
                onChange={inputChangeHandler}
                placeholder={`Email`}
              />
              <input
                className={styles.inputField}
                name='phone_billing'
                onChange={inputChangeHandler}
                placeholder={`Telefon`}
              />
              <div className={styles.addressData}>
                <div className={styles.infoInputs}>
                  <input
                    className={styles.inputField}
                    name='address_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Adresa dostave`}
                  />
                  <input
                    className={styles.inputField}
                    name='zip_code_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Poštanski broj`}
                  />
                </div>
                <div className={styles.infoInputs}>
                  <input
                    className={styles.inputField}
                    name='object_number_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Broj`}
                  />
                  <input
                    className={styles.inputField}
                    name='town_billing'
                    onChange={inputChangeHandler}
                    placeholder={`Grad`}
                  />
                </div>
              </div>
              <textarea
                className={styles.inputField}
                name='note_billing'
                onChange={inputChangeHandler}
                placeholder={`Napomena`}
              />
            </div>
          </div>
        )}
      </div>
      <h2 className={styles.title}>Način dostave:</h2>
      <div className={styles.radiosContainer}>
        <div className={styles.radioContainer}>
          <input type='radio' />
          <label>Dostava kurirskom službom na kućnu adresu</label>
        </div>
      </div>
      <h2 className={styles.title}>Način plaćanja:</h2>
      <div className={styles.radiosContainer}>
        <div className={styles.radioContainer}>
          <input type='radio' />
          <label>Plaćanje prilikom preuzimanja robe od kurira</label>
        </div>
        <div className={styles.radioContainer}>
          <input type='radio' />
          <label>Uplata na tekući račun</label>
        </div>
        <div className={styles.radioContainer}>
          <input type='radio' />
          <label>Plaćanje karticama</label>
        </div>
      </div>
      {/* <form onSubmit={submitHandler}>
        <h3>Billing data:</h3>
        <div className={styles.formRow}>
          <div className={styles.formItem}>
            <label htmlFor='firstName'>First name:</label>
            <input
              type='text'
              name='first_name_billing'
              id='firstName'
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.formItem}>
            <label htmlFor='surname'>Surname:</label>
            <input
              type='text'
              name='last_name_billing'
              id='surname'
              onChange={inputChangeHandler}
            />
          </div>
        </div>

        {bussinessOrder && (
          <>
            <div className={styles.formRow}>
              <div className={styles.formItem}>
                <label htmlFor='company'>Company:</label>
                <input
                  type='text'
                  name='company_name_billing'
                  id='company'
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formItem}>
                <label htmlFor='pib'>PIB:</label>
                <input
                  type='text'
                  name='pib'
                  id='pib_billing'
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor='mb'>MB:</label>
                <input
                  type='text'
                  name='maticni_broj_billing'
                  id='mb'
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
          </>
        )}
        <p
          onClick={() => {
            setBussinesOrder(!bussinessOrder);
          }}
          className={styles.question}
        >
          {bussinessOrder ? "Hide company?" : "Is this a business order?"}
        </p>
        <div className={styles.formRow}>
          <div className={styles.formItem}>
            <label htmlFor='address_billing'>Ulica:</label>
            <input
              type='text'
              name='address_billing'
              id='address_billing'
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.formItem}>
            <label htmlFor='object_number_billing'>Broj:</label>
            <input
              type='text'
              name='object_number_billing'
              id='object_number_billing'
              onChange={inputChangeHandler}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formItem}>
            <label htmlFor='zip_code_billing'>Post code:</label>
            <input
              type='text'
              name='zip_code_billing'
              id='zip_code_billing'
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.formItem}>
            <label htmlFor='town_name_billing'>Town:</label>
            <input
              type='text'
              name='town_name_billing'
              id='town_name_billing'
              onChange={inputChangeHandler}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formItem}>
            <label htmlFor='email_billing'>Email:</label>
            <input
              type='text'
              name='email_billing'
              id='email_billing'
              onChange={inputChangeHandler}
            />
          </div>
          <div className={styles.formItem}>
            <label htmlFor='phone_billing'>Phone:</label>
            <input
              type='text'
              name='phone_billing'
              id='phone_billing'
              onChange={inputChangeHandler}
            />
          </div>
        </div>

        {differentShipping && (
          <>
            <h3>Shipping data:</h3>
            <div className={styles.formRow}>
              <div className={styles.formItem}>
                <label htmlFor='first_name_shipping'>First name:</label>
                <input
                  type='text'
                  name='first_name_shipping'
                  id='first_name_shipping'
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor='last_name_shipping'>Surname:</label>
                <input
                  type='text'
                  name='last_name_shipping'
                  id='last_name_shipping'
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            {bussinessOrder && (
              <div className={styles.formRow}>
                <div className={styles.formItem}>
                  <label htmlFor='company_name_shipping'>Company:</label>
                  <input
                    type='text'
                    name='company_name_shipping'
                    id='company_name_shipping'
                    onChange={inputChangeHandler}
                  />
                </div>
              </div>
            )}
            <div className={styles.formItem}>
              <label htmlFor='address_shipping'>Address:</label>
              <input
                type='text'
                name='address_shipping'
                id='address_shipping'
                onChange={inputChangeHandler}
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.formItem}>
                <label htmlFor='postCode_shipping'>Post code:</label>
                <input
                  type='text'
                  name='postCode_shipping'
                  id='postCode_shipping'
                  onChange={inputChangeHandler}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor='town_shipping'>Town:</label>
                <input
                  type='text'
                  name='town_shipping'
                  id='town_shipping'
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className={styles.formItem}>
              <label htmlFor='email_shipping'>Email:</label>
              <input
                type='text'
                name='email_shipping'
                id='email_shipping'
                onChange={inputChangeHandler}
              />
            </div>
            <div className={styles.formItem}>
              <label htmlFor='phone_shipping'>Phone:</label>
              <input
                type='text'
                name='phone_shipping'
                id='phone_shipping'
                onChange={inputChangeHandler}
              />
            </div>
          </>
        )}
        <p
          onClick={() => {
            setDifferentShipping(!differentShipping);
          }}
          className={styles.question}
        >
          {differentShipping
            ? "Use billing address"
            : "Different delivery address?"}
        </p>
        <div className={styles.formItem}>
          <input type='submit' name='submit' id='submit' value='Submit' />
        </div>
      </form> */}
    </div>
  );
};

export default CheckoutForm;
