import { cloneDeep, isEqual } from "lodash";
import { useState } from "react";

const useStateHistory = (firstState = null) => {
  const [history, setHistory] = useState([cloneDeep(firstState)]);

  const addToHistory = (state) => {
    let last = cloneDeep(state);
    if (!isEqual(history[history?.length - 1], last)) {
      setHistory([...history, last]);
    }
  };

  const removeLast = () => {
    let newHistory = [...history];
    if (newHistory.length > 1) {
      newHistory.pop();
      setHistory(newHistory);
    }
    return newHistory[newHistory.length - 1];
  };

  return [history, addToHistory, removeLast];
};

export default useStateHistory;
