import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <h1>
        ERROR <span>{state?.code}</span>
      </h1>
      <p>{state?.message}</p>
      <button
        onClick={() => {
          navigate(state?.path ?? "/");
        }}
      >
        Back
      </button>
    </div>
  );
};

export default ErrorPage;
