import { Html } from "@react-three/drei";
import React from "react";
import useNumberInput from "../../hooks/useNumberInput";
//styles
import styles from "./NumberInput3d.module.scss";

const NumberInput3d = (props) => {
  const max = props.max !== undefined ? props.max : 100;
  const min = props.min !== undefined ? props.min : 0;

  const [
    ,
    newValue,
    ,
    onInputValueChangeHandler,
    onInputBlurHandler,
    onKeyPressHandler,
  ] = useNumberInput(props);

  function outHandler() {
    props.outHtml(undefined);
  }

  function overHandler() {
    props.overHtml(props.position);
  }

  return (
    <Html {...props} className={styles["html"]}>
      <div
        style={{ display: "flex" }}
        className={`${props.className} + ${styles["input"]}`}
        onMouseOver={overHandler}
        onMouseOut={outHandler}
      >
        <input
          value={newValue}
          type='text'
          pattern='([0-9]+)(\.[0-9]*)?'
          min={min}
          max={max}
          onChange={onInputValueChangeHandler}
          onBlur={onInputBlurHandler}
          onKeyDown={onKeyPressHandler}
          className={props.inputClass}
        />
        <label>{props.label}</label>
      </div>
    </Html>
  );
};

export default NumberInput3d;
