import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { post } from "../../../api/api";

const CheckoutSuccess = () => {
  const { orderToken } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await post(
          `api.php?action=get_checkout&order_token=${orderToken}`
        );
        console.log(response);
        setData(response?.payload);
      } catch (e) {
        console.warn(e);
      }
    };
    getData();
  }, []);

  return (
    <div>
      <h1>Success</h1>
      <p>Order number: {data?.order?.order_token}</p>
    </div>
  );
};

export default CheckoutSuccess;
