import React from "react";
import InputSlider from "../InputSlider/InputSlider";
import InputSelectImage from "../InputSelectImage/InputSelectImage";
import MaterialSelect from "../MaterialSelect/MaterialSelect";
import InputSwitch from "../InputSwitch/InputSwitch";
import BackMaterialSelect from "../BackMaterialSelect/BackMaterialSelect";
import DoorsSelect from "../DoorsSelect/DoorsSelect";
import InputNumber from "../InputNumber/InputNumber";
import Description from "../Description/Description";
import InteriorOptionSelect from "../InteriorOptionSelect/InteriorOptionSelect";

const ConfigurationInput = (props) => {
  switch (props.type) {
    case "slider":
      return <InputSlider key={props.value} {...props} />;
    case "selectImage":
      return <InputSelectImage key={props.value} {...props} />;
    case "materialSelect":
      return <MaterialSelect {...props} />;
    case "switch":
      return <InputSwitch {...props} />;
    case "backMaterialSelect":
      return <BackMaterialSelect {...props} />;
    case "doorsSelect":
      return <DoorsSelect {...props} />;
    case "interiorOptionSelect":
      return <InteriorOptionSelect {...props} />;
    case "number":
      return <InputNumber {...props} />;
    case "description":
      return <Description {...props} />;
    default:
      return null;
  }
};

export default ConfigurationInput;
