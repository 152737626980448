import styles from "./DoorsSelect.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const LimitationsDisplay = ({ item, limits, selectedDoorDimension }) => {
  const numberContent = (key, value) => {
    let icon = null;
    if (value) {
      icon = <CheckIcon style={{ fill: "green" }} />;
    } else {
      icon = <CloseIcon style={{ fill: "red" }} />;
    }
    return (
      <>
        <span>{icon}</span>
        <span>
          {`${key} ${item.limitations[key].min} - ${
            item.limitations[key].max
          } cm (current ${
            Math.round(selectedDoorDimension[key] * 100) / 100
          }cm)`}
        </span>
      </>
    );
  };
  const boolContent = (key, value) => {
    let icon = null;
    if (value) {
      icon = <CheckIcon style={{ fill: "green" }} />;
    } else {
      icon = <CloseIcon style={{ fill: "red" }} />;
    }
    switch (key) {
      case "multipleCompartments":
        if (!item.limitations[key]) {
          return (
            <>
              <span>{icon}</span>
              <span>
                The chosen front cannot be integrated across several
                compartments
              </span>
            </>
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  return (
    <div className={styles.limits}>
      {Object.entries(limits).map(([key, value]) => (
        <p key={key} className={styles.limitation}>
          {key === "multipleCompartments"
            ? boolContent(key, value)
            : numberContent(key, value)}
        </p>
      ))}
    </div>
  );
};

export default LimitationsDisplay;
