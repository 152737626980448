import styles from "./Header.module.scss";

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <img src='/images/logo.webp' alt='Knapp' height='100%' />
      </div>
    </div>
  );
};

export default Header;
