import { degToRad } from "three/src/math/MathUtils";

const interiors = {
  rail: {
    model: "/models/rail/garderoberna_sipka_novo.stl",
    rotation: [degToRad(-90), 0, 0],
    scale: [0.02, 0.02, 0.02],
  },
  lift: {
    model: "/models/lift/lift_garderober_novo.stl",
    rotation: [degToRad(-90), 0, 0],
    scale: [0.3, 0.3, 0.3],
  },
  trousersHolder: {
    model: "/models/pants/pants.stl",
    rotation: [degToRad(-90), degToRad(0), 0],
    scale: [0.4, 0.4, 0.4],
  },
  beltHolder: {
    model: "/models/pants/pants.stl",
    rotation: [degToRad(90), degToRad(180), 0],
    scale: [0.3, 0.3, 0.3],
  },
};

export default interiors;
