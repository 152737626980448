function initState(config) {
  if (!config) {
    return null;
  }

  const {
    DEFAULTS: {
      width,
      height,
      plinth: { ENABLED, height: plinthHeight },
      materialThickness,
      innerThickness,
      depth,
      material,
      material_id,
      columns: defaultColumns,
      compartments: defaultCompartments,
      globalDoorOptions,
    },
  } = config;

  const state = {
    width,
    height,
    plinth: {
      enabled: ENABLED,
      height: ENABLED ? plinthHeight : 0,
    },
    columns: [],
    materialThickness,
    innerThickness,
    depth,
    material: {
      texture: material,
      id: material_id,
    },
    closedBack: config.DEFAULTS.closedBack,
    wallMount: config.DEFAULTS.wallMount,
    backMaterial: {},
    price: null,
    currency: "",
    globalDoorOptions: { ...globalDoorOptions },
    mountingHoles: config.DEFAULTS.mountingHoles,
    skirtingBoard: config.DEFAULTS.skirtingBoard,
    heightAdjusters: config.DEFAULTS.heightAdjusters,
  };
  const numColumns = defaultColumns;
  const numCompartments = defaultCompartments;

  for (let i = 0; i < numColumns; i++) {
    const columnWidth =
      (state.width - (numColumns + 1) * state.materialThickness) / numColumns;

    const column = {
      width: columnWidth,
      height: state.height,
      compartments: [],
    };

    for (let j = 0; j < numCompartments; j++) {
      const compartmentHeight =
        (column.height -
          state.plinth.height -
          (numCompartments - 1) * state.innerThickness -
          2 * state.materialThickness) /
        numCompartments;

      const compartment = {
        height: compartmentHeight,
        hasDoor: false,
        door: null,
        interior: [],
      };

      column.compartments.push(compartment);
    }

    state.columns.push(column);
  }

  return state;
}

export default initState;
