import { createContext, useEffect, useState } from "react";
import { post } from "../../api/api";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const getCartItems = async () => {
    try {
      const response = await post("api.php?action=get_cart_items", {});

      setCartItems(response?.payload?.items ?? []);
    } catch (error) {
      console.warn(error);
    }
  };

  const addToCart = async (configuration_data, structure, item_data = {}) => {
    try {
      await post("api.php?action=set_cart_item", {
        customer: { quantity: 1, comment: null },
        item_data,
        file_data: { configuration_data, structure },
      });
      getCartItems();
    } catch (error) {
      console.warn(error);
    }
  };

  const removeFromCart = async (item_token) => {
    try {
      await post(
        `api.php?action=remove_cart_item&item_token=${item_token}`,
        {}
      );
      getCartItems();
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    getCartItems();
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
