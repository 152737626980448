import styles from "./Sizing.module.scss";

const ImageSizing = ({ image = "" }) => {
  return (
    <div className={styles.sizingContainer}>
      <img src={image} alt='' />
    </div>
  );
};

export default ImageSizing;
