import React, { useEffect, useState } from "react";
import styles from "./InputSelectImage.module.scss";

const InputSelectImage = ({
  defaultChecked,
  onPassValue = () => null,
  value,
  title,
  image,
  className,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const inputChangeHandler = () => {
    if (!disabled) {
      setIsChecked(true);
      onPassValue(value);
    }
  };

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const containerClasses = [
    styles["select-image"],
    isChecked && styles["select-image-checked"],
    disabled && styles["select-image-disabled"],
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={containerClasses} onClick={inputChangeHandler}>
      {image && <img src={image} alt={title} />}
      <label className={styles.title}>{title}</label>
    </div>
  );
};

export default InputSelectImage;
