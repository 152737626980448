import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Graphics from "./graphics/Graphics";
import ConfigForm from "./form/ConfigForm/ConfigForm";
import initState from "./utils/initState";
import {
  changeColumns,
  changeDepth,
  changeHeight,
  changeWidth,
  changeCompartments,
  changeThickness,
  changePlinthEnabled,
  changePlinthHeight,
  changeFormState,
  changeColumnWidth,
  changeCompartmentHeight,
  changeColumnHeight,
  setCompartmentHeight,
  setColumnHeight,
  setColumnWidth,
  setColumnCompartments,
  setDoors,
  recalculateDoors,
  calculateDoorDimensions,
} from "./utils/Math";
import { getMaterialBySelector } from "../helpers/materials";
import LeftMenu from "./LeftMenu/LeftMenu";
import Sizing from "./Sizing/Sizing";
import useStateHistory from "./hooks/useStateHistory";
import { cloneDeep } from "lodash";
import UndoButton from "./graphics/UndoButton/UndoButton";
import SaveSizing from "./SaveSizing/SaveSizing";
import ImageSizing from "./Sizing/ImageSizing";
import SaveGraphics from "./SaveGraphics/SaveGraphics";
import RightMenu from "./RightMenu/RightMenu";
import styles from "./Configurator.module.scss";
/*
Početno stanje polica
Biće dodat api poziv koji na osnovu tokena (ako ga dobije preko linka) generiše stanje polica 
u suprotnom generiše default vrednosti iz config.json
*/

const reducer = (state, action) => {
  switch (action.type) {
    case "changeState": {
      return recalculateDoors({ ...state, ...cloneDeep(action.payload) });
    }
    case "changeColumns":
      return recalculateDoors({ ...state, columns: action.payload });
    case "changeMaterial":
      return recalculateDoors({
        ...state,
        material: action.payload,
      });
    default:
      throw new Error();
  }
};

const reducerForm = (state, action) => {
  switch (action.type) {
    case "changeState": {
      return { ...state, ...action.payload };
    }
    default:
      throw new Error();
  }
};

const Configurator = ({ config, formData, savedState = null }) => {
  const [initialState, setInitialState] = useState(
    savedState ?? initState(config)
  );
  const initialFormState = changeFormState({}, initialState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formState, dispatchFormState] = useReducer(
    reducerForm,
    initialFormState
  );
  const [indicatorsState, setIndicatorsState] = useState(initialState);
  const [compartmentsChanged, setCompartmentsChanged] = useState(false);
  const [clickedCompartment, setClickedCompartment] = useState(null);

  const [selectedMenuOption, setSelectedMenuOption] = useState("front");

  const [area, setArea] = useState(0);
  const [volume, setVolume] = useState(0);

  const [overlayOption, setOverlayOption] = useState("indicators");
  //console.log(state);

  const [stateHistory, addState, lastState] = useStateHistory(initialState);

  const oldState = useRef();

  const [undoCalls, setUndoCalls] = useState(0);
  const [selectedDoorDimension, setSelectedDoorDimensions] = useState(null);
  const [doorDisplayed, setDoorDisplayed] = useState(true);

  const [sizingImage, setSizingImage] = useState("");
  const [modelImage, setModelImage] = useState("");

  useEffect(() => {
    if (oldState?.current?.price === state.price) {
      addState(state);
      let newFormState = changeFormState(formState, state);
      dispatchFormState({
        type: "changeState",
        payload: newFormState,
      });
      setIndicatorsState(state);
    }

    oldState.current = state;
  }, [state]);

  const undo = useCallback(() => {
    let newState = lastState();
    if (newState != null) {
      setUndoCalls((pre) => pre + 1);
      dispatch({
        type: "changeState",
        payload: newState,
      });
      let newFormState = changeFormState(formState, newState);
      dispatchFormState({
        type: "changeState",
        payload: newFormState,
      });
      setIndicatorsState(newState);
      setInitialState(newState);
    }
  }, [lastState]);

  /* useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "u" || event.key === "U") {
        undo();
      }
    };

    document.addEventListener("keyup", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyDown);
    };
  }, [undo]); */

  /* Calback funkcija za racunanje cene, trenutno cena zavisi samo od materijala i povrsine */
  useEffect(() => {
    if (state.material?.price && state.material?.price?.price_defined) {
      let priceObj = state.material?.price;
      if (priceObj?.discount?.active) {
        //TODO handlovati popust
      }
      let total_price = Math.ceil(area * priceObj?.price.original);
      dispatch({
        type: "changeState",
        payload: { price: total_price, currency: priceObj?.currency },
      });
    }
  }, [area, state.material]);

  /* Handleri za izmene state-a (pozivaju funkcije iz Math.js) */
  const widthChangeHandler = (value) => {
    let newState = changeWidth(state, Number(value));
    dispatch({
      type: "changeState",
      payload: newState,
    });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
    setIndicatorsState(newState);
  };

  function heightChangeHandler(value) {
    let newState = changeHeight(state, Number(value));
    dispatch({
      type: "changeState",
      payload: newState,
    });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
    setIndicatorsState(newState);
  }

  function depthChangeHandler(value) {
    let newState = changeDepth(state, Number(value));
    dispatch({
      type: "changeState",
      payload: newState,
    });
    setIndicatorsState(newState);
  }

  function columnChangeHandler(value) {
    let newColumns = changeColumns(state, Number(value), {
      keepOldColumns: true,
    });
    dispatch({
      type: "changeColumns",
      payload: newColumns,
    });
    setIndicatorsState({ ...state, columns: newColumns });
  }

  function compartmentsChangeHandler(value) {
    let newColumns = changeCompartments(state, Number(value));
    dispatch({
      type: "changeColumns",
      payload: newColumns,
    });
    setIndicatorsState({ ...state, columns: newColumns });
  }

  function thicknessChangeHandler(value) {
    let newState = changeThickness(state, value);
    dispatch({ type: "changeState", payload: newState });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
    setIndicatorsState(newState);
  }

  function plinthEnabledChangeHandler(value) {
    let newState = changePlinthEnabled(state, value);

    if (value) {
      newState.wallMount.enabled = false;
    }

    dispatch({ type: "changeState", payload: newState });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
    setIndicatorsState(newState);
  }

  function plinthHeightChangeHandler(value) {
    let newState = changePlinthHeight(state, value);
    dispatch({ type: "changeState", payload: newState });
    setIndicatorsState(newState);
  }

  function columnWidthChangeHandler(value, column, direction) {
    let newState = changeColumnWidth(state, column, value, direction);
    dispatch({ type: "changeState", payload: newState });
  }

  function compartmentHeightChangeHandler(
    value,
    compartment,
    direction,
    clicked
  ) {
    if (clicked) {
      setClickedCompartment(compartment);
    } else {
      setClickedCompartment(null);
    }
    let newState = changeCompartmentHeight(
      state,
      compartment,
      value,
      direction
    );
    dispatch({ type: "changeState", payload: newState });
  }
  function columnHeightChangeHandler(value, compartment, direction) {
    let newState = changeColumnHeight(state, compartment, value, direction);
    dispatch({ type: "changeState", payload: newState });
    setInitialState(newState);
  }

  function compartmentHeightSet(value, compartmentKey, min, max) {
    let newState = setCompartmentHeight(state, value, compartmentKey, min, max);
    dispatch({ type: "changeState", payload: newState });
  }

  function columnHeightSet(value, columnKey, min, max) {
    let newState = setColumnHeight(state, value, columnKey, min, max);
    dispatch({ type: "changeState", payload: newState });
    setInitialState(newState);
  }

  function columnWidthSet(value, columnKey, min, max) {
    let newState = setColumnWidth(state, value, columnKey, min, max);
    dispatch({ type: "changeState", payload: newState });
  }

  function columnCompartmentsSet(value, columnKey, min, max) {
    let newState = setColumnCompartments(state, value, columnKey, min, max);
    dispatch({ type: "changeState", payload: newState });
    setCompartmentsChanged(true);
  }

  const materialChangeHandler = (value) => {
    if (value !== undefined) {
      dispatch({ type: "changeMaterial", payload: value });
      let newState = {
        ...state,
        material: value,
      };
      let newFormState = changeFormState(formState, newState);
      dispatchFormState({
        type: "changeState",
        payload: newFormState,
      });
    }
  };

  const closedBackHandler = (value) => {
    let newState = { ...state, closedBack: value };
    dispatch({
      type: "changeState",
      payload: { closedBack: value },
    });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
  };

  const wallMountHandler = (value) => {
    let newState = state;
    if (value.enabled && state.plinth.enabled) {
      newState = changePlinthEnabled(state, false);
    }
    newState = { ...newState, wallMount: value };
    dispatch({ type: "changeState", payload: newState });
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
    setIndicatorsState(newState);
  };

  const backMaterialHandler = (value) => {
    value = { ...value, option: state.closedBack.option };
    dispatch({
      type: "changeState",
      payload: { backMaterial: value },
    });
    let newState = { ...state, backMaterial: value };
    let newFormState = changeFormState(formState, newState);
    dispatchFormState({
      type: "changeState",
      payload: newFormState,
    });
  };

  const doorAddHandler = (value, options) => {
    if (selectedDoorDimension !== null) {
      let selected = selectedDoorDimension.selected;
      let actualDimensions = calculateDoorDimensions(state, selected, options);

      let door = {
        doorType: value,
        key: `d_${selected[0][0]}_${selected[0][1]}_${
          selected[selected.length - 1][1]
        }`,
        dimensions: { ...actualDimensions, doorType: value },
        options,
      };
      let newState = setDoors(state, selected, door);
      if (state.globalDoorOptions.enabled) {
        newState.globalDoorOptions = { ...options, enabled: true };
      }
      dispatch({ type: "changeState", payload: newState });
    }
  };

  const selectedDoorsHandler = (selected) => {
    let ret = {};
    if (selected.length > 0) {
      ret.depth = state.depth;
      ret.multipleCompartments = !!(selected.length > 1);
      let clm = selected[0][0];
      let column = state.columns[clm];
      ret.width = column.width;
      ret.height = (selected.length - 1) * state.innerThickness;
      for (let i = 0; i < selected.length; i++) {
        let j = selected[i][1];
        ret.height += column.compartments[j].height;
      }
      ret.position = [0, 0, 0];
      ret.position[2] = state.depth / 100 - state.materialThickness / 200;
      for (let i = 0; i < clm; i++) {
        ret.position[0] +=
          state.materialThickness / 100 + state.columns[i].width / 100;
      }
      ret.position[0] +=
        state.materialThickness / 100 + state.columns[clm].width / 200;

      ret.position[1] =
        state.columns[clm].height / 100 - state.materialThickness / 100;

      for (let i = 0; i < selected[0][1]; i++) {
        ret.position[1] -=
          state.columns[clm].compartments[i].height / 100 +
          state.innerThickness / 100;
      }

      ret.position[1] -= ret.height / 200;

      if (column.compartments[selected[0][1]].hasDoor) {
        ret.doorType = column.compartments[selected[0][1]].door.doorType;
      } else {
        ret.doorType = "none";
      }

      ret.interior = cloneDeep(column.compartments[selected[0][1]].interior);
      ret.selected = cloneDeep(selected);
    } else {
      ret = null;
    }
    setSelectedDoorDimensions(ret);
  };

  const setGlobalDoorOptions = (value) => {
    dispatch({
      type: "changeState",
      payload: { globalDoorOptions: cloneDeep(value) },
    });
  };

  const mountingHolesHandler = (value) => {
    let newState = state;
    newState = { ...newState, mountingHoles: value };
    dispatch({ type: "changeState", payload: newState });
  };

  const skirtingBoardHandler = (value) => {
    let newState = state;
    newState = {
      ...newState,
      skirtingBoard: { ...newState.skirtingBoard, ...value },
    };
    dispatch({ type: "changeState", payload: newState });
  };

  const heightAdjustersHandler = (value) => {
    let newState = state;
    newState = { ...newState, heightAdjusters: value };
    dispatch({ type: "changeState", payload: newState });
  };

  const skirtingBoardHeightHandler = (value) => {
    console.log(value, Number(value));
    console.log(state);
    let newState = state;
    newState = {
      ...newState,
      skirtingBoard: {
        ...newState.skirtingBoard,
        dimensions: {
          height: Number(value),
          depth: newState.skirtingBoard.dimensions.depth,
        },
      },
    };
    dispatch({ type: "changeState", payload: newState });
  };

  const skirtingBoardDepthhHandler = (value) => {
    let newState = state;
    newState = {
      ...newState,
      skirtingBoard: {
        ...newState.skirtingBoard,
        dimensions: {
          depth: Number(value),
          height: newState.skirtingBoard.dimensions.height,
        },
      },
    };
    dispatch({ type: "changeState", payload: newState });
  };

  const addInteriorOption = (value) => {
    if (selectedDoorDimension !== null) {
      let clm = selectedDoorDimension.selected[0][0],
        row = selectedDoorDimension.selected[0][1];
      let newState = cloneDeep(state);
      newState.columns[clm].compartments[row].interior = value;
      setSelectedDoorDimensions((prevDoorDimensions) => {
        return { ...prevDoorDimensions, interior: value };
      });
      dispatch({ type: "changeState", payload: newState });
    }
  };

  const handlers = {
    width: widthChangeHandler,
    height: heightChangeHandler,
    depth: depthChangeHandler,
    columns: columnChangeHandler,
    compartments: compartmentsChangeHandler,
    thickness: thicknessChangeHandler,
    plinthEnabled: plinthEnabledChangeHandler,
    plinthHeight: plinthHeightChangeHandler,
    material: materialChangeHandler,
    closedBack: closedBackHandler,
    wallMount: wallMountHandler,
    backMaterial: backMaterialHandler,
    doors: doorAddHandler,
    mountingHoles: mountingHolesHandler,
    skirtingBoard: skirtingBoardHandler,
    heightAdjusters: heightAdjustersHandler,
    skirtingBoardHeight: skirtingBoardHeightHandler,
    skirtingBoardDepth: skirtingBoardDepthhHandler,
    interiorOption: addInteriorOption,
    interior: addInteriorOption,
  };

  const overlayHandlers = {
    columnWidthChange: columnWidthChangeHandler,
    compartmentHeightChange: compartmentHeightChangeHandler,
    columnHeightChange: columnHeightChangeHandler,
    compartmentHeightSet: compartmentHeightSet,
    columnHeightSet: columnHeightSet,
    columnWidthSet: columnWidthSet,
    columnCompartmentsSet: columnCompartmentsSet,
    areaChangeHandler: setArea,
    volumeChangeHandler: setVolume,
    selectDoors: selectedDoorsHandler,
  };

  const menuOptionHandler = (key) => {
    setSelectedMenuOption(key);
  };
  const menuOptions = [
    {
      key: "front",
      title: "Front",
      image: "./icons/front.svg",
      handler: menuOptionHandler,
    },
    {
      key: "sizing",
      title: "Sizing",
      image: "./icons/dimensions.svg",
      handler: menuOptionHandler,
    },
    {
      key: "180",
      title: "180° view",
      image: "./icons/180-preview.svg",
      handler: menuOptionHandler,
    },
    {
      key: "doors",
      title: "Display doors",
      image: "./doors/out/door_both.svg",
      handler: () => {
        setDoorDisplayed(!doorDisplayed);
      },
    },
  ];

  return (
    <>
      <SaveSizing state={state} saveImage={setSizingImage} />
      <SaveGraphics state={state} saveImage={setModelImage} />
      <div className={styles.configurator}>
        <div
          style={{
            height: `100%`,
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {(selectedMenuOption === "front" || selectedMenuOption === "180") && (
            <Graphics
              style={{ height: `100%`, background: "black" }}
              state={state}
              indicatorsState={indicatorsState}
              stateHandlers={handlers}
              overlayHandlers={overlayHandlers}
              enableRotate={selectedMenuOption === "180"}
              overlayOption={overlayOption}
              clickedCompartment={clickedCompartment}
              doorDisplayed={doorDisplayed}
            />
          )}
          {selectedMenuOption === "sizing" && (
            <ImageSizing image={sizingImage} />
          )}

          <UndoButton
            undo={undo}
            isDisplayed={
              selectedMenuOption === "front" && stateHistory.length > 1
            }
          />
          <LeftMenu
            menuOptions={menuOptions}
            selectedOption={selectedMenuOption}
          />
          <RightMenu
            sizingImage={sizingImage}
            modelImage={modelImage}
            state={state}
          />
        </div>
        <ConfigForm
          state={initialState}
          formState={formState}
          stateHandlers={handlers}
          compartmentsChanged={compartmentsChanged}
          displayState={state}
          disabled={selectedMenuOption !== "front"}
          selectFront={() => {
            setSelectedMenuOption("front");
          }}
          config={config}
          formData={formData}
          area={area}
          volume={volume}
          price={
            state.price !== null ? `${state.price} ${state.currency}` : "NaN"
          }
          undos={undoCalls}
          updateInitialState={() => {
            setInitialState(state);
          }}
          setOverlayOption={setOverlayOption}
          selectedDoorDimension={selectedDoorDimension}
          globalDoorOptions={state.globalDoorOptions}
          setGlobalDoorOptions={setGlobalDoorOptions}
          showCheckout={true}
          sizingImage={sizingImage}
          modelImage={modelImage}
        />
      </div>
    </>
  );
};

export default Configurator;
