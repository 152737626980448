import { post } from "../../api/api";
import { generate } from "../utils/Math";

const OldSaveButton = ({ state }) => {
  const saveHandler = async () => {
    try {
      const response = await post("save.php", {
        configuration_data: state,
        structure: generate(state)[1],
      });
      window.open(response?.payload?.redirection_url);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <button
      onClick={saveHandler}
      style={{ background: "none", border: "none", cursor: "pointer" }}
    >
      CIX
    </button>
  );
};

export default OldSaveButton;
