import { degToRad } from "three/src/math/MathUtils";

export const RotateDimensions = (dimensions = {}, rotation) => {
  let alpha = degToRad(rotation.x),
    betha = degToRad(rotation.y),
    gamma = degToRad(rotation.z);
  let width, height, depth;

  const sinA = Math.round(Math.sin(alpha) * 100) / 100;
  const cosA = Math.round(Math.cos(alpha) * 100) / 100;

  const sinB = Math.round(Math.sin(betha) * 100) / 100;
  const cosB = Math.round(Math.cos(betha) * 100) / 100;

  const sinG = Math.round(Math.sin(gamma) * 100) / 100;
  const cosG = Math.round(Math.cos(gamma) * 100) / 100;

  const rotationMatrix = [
    [cosB * cosG, -cosB * sinG, sinB],
    [
      cosA * sinG + sinA * sinB * cosG,
      cosA * cosG - sinA * sinB * sinG,
      -sinA * cosB,
    ],
    [
      sinA * sinG - cosA * sinB * cosG,
      sinA * cosG + cosA * sinB * sinG,
      cosA * cosB,
    ],
  ];

  width =
    rotationMatrix[0][0] * dimensions.width +
    rotationMatrix[0][1] * dimensions.height +
    rotationMatrix[0][2] * dimensions.depth;
  height =
    rotationMatrix[1][0] * dimensions.width +
    rotationMatrix[1][1] * dimensions.height +
    rotationMatrix[1][2] * dimensions.depth;

  depth =
    rotationMatrix[2][0] * dimensions.width +
    rotationMatrix[2][1] * dimensions.height +
    rotationMatrix[2][2] * dimensions.depth;

  return { width, height, depth };
};
