import { useContext, useState } from "react";
import CheckoutModal from "../CheckoutModal/CheckoutModal";
import SaveModal from "../SaveModal/SaveModal";
import styles from "./CheckoutSection.module.scss";
import { generate } from "../../utils/Math";
import { CartContext } from "../../utils/CartContext";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const CheckoutSection = ({
  state,
  sizingImage,
  modelImage,
  price,
  ...props
}) => {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isSaveOpen, setIsSaveOpen] = useState(false);

  const navigate = useNavigate();

  const { addToCart, cartItems } = useContext(CartContext);

  const addToCartHandler = async (e) => {
    e.preventDefault();

    try {
      await addToCart(state, generate(state)[1], {
        technical_image: sizingImage,
        model_image: modelImage,
      });
    } catch (error) {
      console.warn(error);
    }
  };
  const saveHandler = () => {
    setIsSaveOpen(true);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.price}>
          <p>{price}</p>
        </div>
        <button
          className={`${styles["checkoutButton"]}`}
          onClick={addToCartHandler}
        >
          Add to cart <ShoppingCartIcon /> <span>{cartItems?.length ?? 0}</span>
        </button>
      </div>
      {/* <div className={styles.container}>
        <button
          className={`${styles["button"]} ${styles["saveButton"]}`}
          onClick={saveHandler}
        >
          Save
        </button>

        <button
          className={`${styles["button"]} ${styles["checkoutButton"]}`}
          onClick={() => {
            navigate("/checkout");
          }}
        >
          Checkout
        </button>
      </div> */}
      <CheckoutModal
        isOpen={isCheckoutOpen}
        setIsOpen={setIsCheckoutOpen}
        state={state}
      />
      <SaveModal
        isOpen={isSaveOpen}
        setIsOpen={setIsSaveOpen}
        state={state}
        sizingImage={sizingImage}
        modelImage={modelImage}
      />
    </>
  );
};

export default CheckoutSection;
