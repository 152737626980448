import React, { useEffect, useState } from "react";
import FormSection from "../FormSection/FormSection";
import ConfigurationInput from "../ConfigurationInput/ConfigurationInput";
import { logValue } from "../../../dev";
import CheckoutSection from "../CheckoutSection/CheckoutSection";
//Styles
import styles from "./ConfigForm.module.scss";
import OldSaveButton from "../../TEMPORARY/SaveButton";

const ConfigForm = ({ sizingImage, modelImage, ...props }) => {
  const [openedSection, setOpenedSection] = useState(0);

  const config = props.config;

  const formData = props.formData;

  function changeOpenedSection(number) {
    if (typeof props?.updateInitialState === "function")
      props?.updateInitialState();
    setOpenedSection(number);
  }

  useEffect(() => {
    if (typeof props?.setOverlayOption === "function")
      props?.setOverlayOption(formData.sections[openedSection].overlay);
  }, [openedSection]);

  const state = props.state;
  const formState = props.formState;

  //Generisanje elemenata forme prema podacima iz form.json
  const elems = [];
  for (let i = 0; i < formData?.sections.length; i++) {
    const children = [];
    if (
      formData.sections[i].key === "interiorOptions" &&
      props.selectedDoorDimension === null
    ) {
      children.push(<p key='pls'>Please select interior to use.</p>);
    }
    for (let j = 0; j < formData?.sections[i].elements.length; j++) {
      let valueType = formData?.sections[i].elements[j]["value-type"];
      if (valueType === "compartments" && props.compartmentsChanged) {
        continue;
      }

      if (
        (valueType === "skirtingBoardHeight" ||
          valueType === "skirtingBoardDepth") &&
        !formState.skirtingBoard.enabled
      ) {
        continue;
      }

      if (
        formData.sections[i].key === "interiorOptions" &&
        props.selectedDoorDimension === null
      ) {
        continue;
      }

      let handler =
        typeof props.stateHandlers[valueType] === "function"
          ? props.stateHandlers[valueType]
          : logValue;
      let newProps = generateConfigInputProps(
        valueType,
        state,
        formData?.sections[i].elements[j].props,
        formState,
        config
      );
      if (valueType === "doors") {
        newProps = {
          ...newProps,
          selectedDoorDimension: props.selectedDoorDimension,
          globalOptions: props.globalDoorOptions,
          setGlobalOptions: props.setGlobalDoorOptions,
        };
      }

      if (valueType === "interior") {
        newProps = {
          ...newProps,
          selectedDoorDimension: props.selectedDoorDimension,
        };
      }

      if (valueType === "interiorOption") {
        newProps = {
          ...newProps,
          defaultChecked:
            props.selectedDoorDimension.interior ===
            formData?.sections[i].elements[j].props.value,
        };
      }
      children.push(
        <ConfigurationInput
          key={j}
          type={formData?.sections[i].elements[j].type}
          {...formData?.sections[i].elements[j].props}
          {...newProps}
          onPassValue={handler}
          undos={props.undos}
        />
      );
    }

    elems.push(
      <FormSection
        key={i}
        number={i}
        title={formData?.sections[i].title}
        description={formData?.sections[i].description}
        info={formData?.sections[i].info}
        isCollapsed={!(i === openedSection)}
        onOpen={changeOpenedSection}
        backButton={() => {
          if (i > 0) {
            changeOpenedSection(i - 1);
          }
        }}
        isLast={i === formData?.sections.length - 1}
        isFirst={i === 0}
      >
        <div>
          {children.map((child) => {
            return child;
          })}
        </div>
      </FormSection>
    );
  }

  return (
    <div className={styles["form"]}>
      <div className={styles["form-sections"]}>
        {elems.map((elem) => {
          return elem;
        })}
      </div>

      {props.showCheckout && (
        <div className={styles["checkout-section"]}>
          {/* <div>
            <OldSaveButton state={props.displayState} />
          </div> */}

          <CheckoutSection
            state={props.displayState}
            sizingImage={sizingImage}
            modelImage={modelImage}
            price={props.price}
          />
        </div>
      )}
      {props.disabled && (
        <div className={styles.disabled}>
          <div className={styles.disabledBackground}></div>
          <p>You are currently not in a normal view.</p>
          <button onClick={props.selectFront}>Back to normal view</button>
        </div>
      )}
    </div>
  );
};

export default ConfigForm;

// Postavljanje propsa za inpute u zavisnosti od vrednosti koje prikazuju
function generateConfigInputProps(
  valueType,
  state,
  formData,
  formState,
  config
) {
  const props = {};
  switch (valueType) {
    case "width":
    case "height":
    case "depth":
      props.min =
        config.MIN[valueType] !== undefined ? config.MIN[valueType] : null;
      props.max =
        config.MAX[valueType] !== undefined ? config.MAX[valueType] : null;
      props.value = state[valueType];

      break;
    case "thickness":
      props.name = "thickness";
      props.value = {
        innerThickness: formData?.innerThickness,
        materialThickness: formData?.materialThickness,
      };
      props.title = formData?.title;
      props.defaultChecked =
        formData?.innerThickness === formState.innerThickness &&
        formData?.materialThickness === formState.materialThickness;
      break;
    case "columns":
      props.value = state.columns.length;
      props.max = formState.columns.max;
      props.min = formState.columns.min;
      break;
    case "compartments":
      props.value = state.columns[0].compartments.length;
      props.min = formState.compartments.min;
      props.max = formState.compartments.max;

      break;
    case "plinthEnabled":
      props.name = "plinth";
      props.value = formData?.enabled;
      props.defaultChecked = formData?.enabled === formState.plinth.enabled;

      break;
    case "plinthHeight":
      props.disabled = !formState.plinth.enabled;
      props.value = state.plinth.height;
      props.max = config.MAX.plinth.height;
      props.min = config.MIN.plinth.height;
      break;
    case "closedBack":
      props.selected = formState.closedBack.enabled;
      break;
    case "wallMount":
      props.selected = formState.wallMount.enabled;
      break;
    case "backMaterial":
      props.displayed = formState.closedBack.enabled;
      props.selectedOption = formState.closedBack.option;
      props.materialId = formState.material.id;
      props.selectedBackMaterial = formState?.backMaterial ?? null;
      props.selectedOptions = formState?.material?.selectedOptions;
      break;
    case "material":
      props.selectedOptions = formState.material.selectedOptions;
      break;
    case "skirtingBoard":
      props.disabled = !formState.plinth.enabled;
      break;
    case "skirtingBoardHeight":
      props.max = formState.plinth.height;
      props.value = formState.skirtingBoard.dimensions.height;

      break;
    case "skirtingBoardDepth":
      props.value = formState.skirtingBoard.dimensions.depth;
      break;
    case "doors":
      props.state = formState;

      break;
    default:
      break;
  }

  return props;
}
